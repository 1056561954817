import React from 'react';
import { Row, Col, Form, Button, Modal as BSModal } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import ToggleButton from 'framework/ToggleButton';
import RemoteSelect from 'framework/RemoteSelect';
import RafalaApi from 'api/Rafala';
import CoreApi from 'api/Core';

import { Formik } from 'formik';

export default class ShopItem extends Module {
    info = ShopItem.Info;

    state = {
        ...this.state,
        supplyQuantity: 1,
        isSubmittingSupply: false
    }

    showPagination = true;
    showPrice = false;
    showAlcoholPercent = false;

    get shopId () { return 1; }

    get pathname () {
        return "/shop/items";
    }
    
    static Info = {
        nameResource: "shop-items",
        icon: "fas fa-shopping-cart",
        path: "/shop/items",
        show: true,
        cookieName: "shop-items"
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItem match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["shop-items"];
    }
    
    get apiPath() {
        return '/shop/items';
    }
    
    get recordsApiPath() {
        return '/shop/1/items';
    }
    
    get createApiPath() {
        return '/shop/1/items';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required(),
            package_quantity: yup.number(),
            description: yup.string()
        });
    }

    get tableHead () {
        const t = this;

        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
        },{
            "Title": AppContext.r["product-code"],
            "Field": "product_code"
          },{
            "Title": AppContext.r["name"],
            "Field": "name"
          },{
            "Title": AppContext.r["availability"],
            "Field": "availability_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.availabilityOptions[o.availability_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["category"],
            "Field": "category_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.categoryOptions[o.category_id];
                return (field) ? field.name : "";
            }
          }
          ,{
            "Title": AppContext.r["shop-item-sub-category"],
            "Field": "sub_category_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.allCategoryOptions[o.sub_category_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["brand"],
            "Field": "brand_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.brandOptions[o.brand_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["quantity"],
            "Field": "package_quantity",
            "ClassName": "d-none d-sm-table-cell"
          },{
            "Title": AppContext.r["size"],
            "Field": "package_size",
            "ClassName": "d-none d-sm-table-cell"
          },{
            "Title": AppContext.r['public'],
            "Field": "published",
            "ClassName": "item-icons",
            "Adapter": function(o) {
                const icons = [];

                icons.push( (o.highlighted === 1) ? <i className="fas fa-star" key="star"></i> : "");
                if(o.image_legacy || o.image) icons.push(<i className="far fa-image" key="image"></i>);
                icons.push(<ToggleButton key={o.published} onToggleAsync={async () => await t.onTogglePublished(o)}
                    on={AppContext.r["yes"]} off={AppContext.r["no"]} toggled={o.published} />);

                return icons;
            }
          }];
    }

    onTogglePublished = async (o) => {
        console.log(o);

        const item = {
            id: o.id,
            published: !o.published
        };

        await this.update(item);
    }

    get initialValues() {
        return {
            product_code: "",
            name: "",
            description: "",
            category_id: null,
            sub_category_id: null,
            availability_id: null,
            brand_id: null,
            wine_category_id: null,
            published: false,
            package_size: "",
            percent_alcohol: 0,
            package_quantity: 1,
            highlight_note: "",
            highlighted: false,
            percent_discount: 0,
            shop_id: this.shopId,
            price: 0
        };
    }
    
    async beforeComponentDidMount() {
        this.availabilityOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitemavailabilities", "id");
        // this.brandOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitems/brands?shopId="+this.shopId, "id");
        this.categoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitems/categories?shopId="+this.shopId, "id");
        this.allCategoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitems/categories?all=1&shopId="+this.shopId, "id");
        this.wineCategoriesOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitemwinecategories");

        this.warehouseOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/warehouses", "id");

        const brandsResponse = await RafalaApi.Brands(this.shopId);
        console.log(brandsResponse);
        if(brandsResponse && brandsResponse.data) {
            this.brands = brandsResponse.data.data;
            this.brandOptions = CoreApi.mapData(brandsResponse.data.data, "id");
        }

        console.log(this.brandOptions);
    }

    handleChange = async (e) => {
        // console.log(this.filters);

        // const filters = {...this.filters};

        // this.jsonTableRef.current.setState({
        //     filterObj: filters
        // })

        // this.setState({
        //     filters: filters
        // })

        console.log(this.filters, Object.keys(this.filters).length);

        this.setState({
            filters: this.filters,
            currentPage: 1
        });

        await this.fetchRecords();
    }

    onResetFilters = () => {
        this.filters = {
            availability_id: null,
            category_id: null,
            brand_id: null
        }

        this.handleChange(null);
    }

    filters = {
        //availability_id: null,
        category_id: null,
        //brand_id: null
    }

    filtersForm = () => {
        const { availability_id, category_id, brand_id } = this.state.filters;

        return (<Form onChange={this.handleChange} className="filters-form">
            <Form.Row>
                <Col xs="6" md="4" lg="3">
                    <RemoteSelect name="categoryId" className="form-control-sm" options={this.categoryOptions} nullOption={true} key={this.state.filters.category_id} value={this.state.filters.category_id}
                            onChange={(e, value) => { if(value === "") value = null; this.filters["category_id"] = value ? parseInt(value, 10) : null; }} />
                </Col>
                {/* <Col xs="6" md="4" lg="3">
                    <RemoteSelect name="categoryId" className="form-control-sm" options={this.categoryOptions} nullOption={true} key={this.state.filters.category_id} value={this.state.filters.category_id}
                            onChange={(e, value) => { if(value === "") value = null; this.filters["category_id"] = value ? parseInt(value, 10) : null; }} />
                </Col> */}
                <Col xs="6" md="4" lg="3">
                    {/* <RemoteSelect name="brandId" className="form-control-sm" options={this.brandOptions} nullOption={true} key={this.state.filters.brand_id} value={this.state.filters.brand_id}
                            onChange={(e, value) => { if(value === "") value = null; this.filters["brand_id"] = value ? parseInt(value, 10) : null; }} />
                             */}
                    <Form.Control as="select" name={"brandId"} value={this.state.filters.brand_id} 
                        className={"form-control-sm"} 
                        onChange={(e) => { 
                                const {value} = e.target;
                                console.log(e, value)
                                if(value === "") value = null; this.filters["brand_id"] = value ? parseInt(value, 10) : null; 
                            }}>
                        <option value={null}></option>
                        {this.brands.map(brand => 
                            <option value={brand.id}>{brand.name}</option>)}
                    </Form.Control>
                </Col>
                <Col xs="8" md="5" lg="4">
                    <ToggleButton on={AppContext.r["clear-filter-not-available"]} off={AppContext.r["filter-not-available"]} key={this.state.filters.availability_id === 2} toggled={this.state.filters.availability_id === 2}
                        className="square"
                        onToggle={() => { this.filters.availability_id = this.filters.availability_id === 2 ? null : 2; this.handleChange(null); }} />
        
                    <Button variant="link" className="reset-filters-button" onClick={this.onResetFilters}
                            style={(availability_id !== null || category_id !== null || brand_id !== null) ? { display: "inline-block" } : { display: "none" }}><i className="fas fa-times"></i> Reset</Button>
                </Col>
            </Form.Row>
        </Form>);
    }

    loadSubCategories = async (category_id) => {
        const url = AppContext.s["api-url"] + "/shopitems/categories?shopId="+this.shopId+"&parentId="+category_id;
        const subCategoryOptions = category_id ? await CoreApi.fetchAsync(url, "id") : [];
        this.setState({ subCategoryOptions: subCategoryOptions });
    }
    
    onChangeCategory = async (e, value, values) => {
        var item = {...values}
        item.category_id = value;
        this.setState({ item: item });
        this.loadSubCategories(value);
    }

    onFetchedItem = async (item) => {
        if(item && item.category_id) {
            this.loadSubCategories(item.category_id);
        }
    }

    onBeforeChange = (values) => {
        var item = { ...values }

        // Force uppercase
        item.product_code = item.product_code.toUpperCase();
        // Not allow symbols
        //item.product_code = item.product_code.replace(/[^a-zA-Z0-9]+/g, "");
        
        // console.log(item.product_code);
        
        // Find Category (if any has been associated)
        let selectedCategory = null;

        for(const c of this.categoryOptions) {
            if(c)
                for(const prefix of c.product_code_prefixes_array) {
                    if(prefix.length > 0 && item.product_code.startsWith(prefix)) {
                        selectedCategory = c;
                        break;
                    }
                }
        }

        if(selectedCategory) {
            item.category_id = selectedCategory.id;
        }

        this.setState({item: item});
    }

    updateAdapter(values) {
        if(values["percent_discount"] === "")
            values["percent_discount"] = 0;

        return values;
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["product-code"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["product-code"]} name="product_code" maxLength="10"
                                                value={values.product_code} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="7">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>

                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                                onToggle={
                                                    (value) => { 
                                                        // this.state.item["published"] = value;
                                                        // this.onChange(this.state.item);
                                                        var item = {...values}
                                                        item.published = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["availability"]}</Form.Label>

                                <RemoteSelect name="availability_id" key={values.availability_id} value={values.availability_id} 
                                                onChange={handleChange} nullOption={true}
                                                options={this.availabilityOptions} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["shop-item-brand"]}</Form.Label>

                                <RemoteSelect name="brand_id" key={values.brand_id} value={values.brand_id}
                                                onChange={handleChange} nullOption={true}
                                                options={this.brandOptions} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["shop-item-category"]}</Form.Label>

                                <RemoteSelect name="category_id" key={values.category_id} value={values.category_id} nullOption={true}
                                                onChange={handleChange} onChange2={(e, value) => { values.sub_category_id = null; this.onChangeCategory(e, value, values)}}
                                                options={this.categoryOptions} />
                            </Form.Group>
                            
                            {(this.state.item && this.state.item.category_id && this.state.subCategoryOptions && this.state.subCategoryOptions.length > 0) && (
                                <Form.Group as={Col} md="6"
                                    style={(this.state.item && this.state.item.category_id && this.state.subCategoryOptions && this.state.subCategoryOptions.length > 0) ? {display:"block"} : {display:"none"}}>
                                <Form.Label>{AppContext.r["shop-item-sub-category"]}</Form.Label>

                                <RemoteSelect name="sub_category_id" key={values.category_id+values.sub_category_id} value={values.sub_category_id}
                                                onChange={handleChange} nullOption={true} fieldToMap="name"
                                                options={this.state.subCategoryOptions} />
                                </Form.Group>
                            )}
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange}
                                                isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs="6" md="3">
                                <Form.Label>{AppContext.r["package-quantity"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["package-quantity"]} name="package_quantity"
                                                value={values.package_quantity} onChange={handleChange}
                                                isValid={touched.package_quantity && !errors.package_quantity} isInvalid={!!errors.package_quantity} />
                            </Form.Group>

                            <Form.Group as={Col} xs="6" md="3">
                                <Form.Label>{AppContext.r["package-size"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["package-size"]} name="package_size"
                                                value={values.package_size} onChange={handleChange} />
                            </Form.Group>

                            {(values.category_id === 119 || values.category_id === 120 || this.showAlcoholPercent) &&
                                <Form.Group as={Col} xs="6" sm="3">
                                    <Form.Label>{AppContext.r["percent-volume"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["percent-volume"]} name="percent_alcohol"
                                                    value={values.percent_alcohol} onChange={handleChange}
                                                    isValid={touched.percent_alcohol && !errors.percent_alcohol} isInvalid={!!errors.percent_alcohol} />
                                </Form.Group>}

                            {/* Only shown when wine categories are selected */}
                            {(values.category_id === 119 || values.category_id === 120) &&
                                <Form.Group as={Col} xs="6" md="3">
                                    <Form.Label>Wine Type</Form.Label>

                                    <RemoteSelect name="wine_category_id" key={"wine"+values.category_id} value={values.wine_category_id}
                                                    onChange={handleChange} nullOption={true} fieldToMap="name"
                                                    options={this.wineCategoriesOptions} />
                                </Form.Group> }
                        </Row>

                        <Row>
                            {/* {this.showPrice &&  */}
                                <Form.Group as={Col} xs="4" sm="3">
                                    <Form.Label>{AppContext.r["price"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["price"]} name="price"
                                                    value={values.price} 
                                                    onChange={e => {
                                                        e.preventDefault();
                                                        const { value } = e.target;
                                                        const regex = /^(\s*|0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                        if (regex.test(value.toString())) {
                                                            setFieldValue(`price`, value);
                                                            values.price = value;
                                                        }
                                                    }}
                                                    isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
                                </Form.Group>
                            
                            <Form.Group as={Col} xs="4" sm="2">
                                <Form.Label>{AppContext.r["highlighted"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.highlighted} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.highlighted}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.highlighted = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} xs="4" sm="3">
                                <Form.Label>{AppContext.r["percent-discount"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["percent-discount"]} name="percent_discount"
                                                value={values.percent_discount} onChange={handleChange}
                                                isValid={touched.percent_discount && !errors.percent_discount} isInvalid={!!errors.percent_discount} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["note"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["note"]} name="highlight_note"
                                                value={values.highlight_note} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                            
                        {/* Shown only on editing */}
                        <Form.Row style={this.displayOnEditStyle}>
                            <Form.Group as={Col}>
                            <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                            <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                            value={values.id} onChange={handleChange}
                                            isValid={touched.id && !errors.id} isInvalid={!!errors.id} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>

                </Form.Row>

                <Form.Row>
                    <Button variant="info" className="supply-stock-button"
                        onClick={this.onSupplyStock}>
                        {AppContext.r["supply-stock"]}
                    </Button>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }

    handleSubmitSupplyStock = async (values) => {
        this.setState({
            modalBusy: true
        });

        console.log(values);
        // console.log(1, this.state.item.id, values.quantity);
        await RafalaApi.SupplyWarehouse(parseInt(values.warehouse_id, 10), this.state.item.id, values.quantity);

        this.setState({
            showModal: false,
            modalBusy: false
        });
    }

    onSupplyStock = () => {
        const title = AppContext.r["supply-stock"];
        const message = AppContext.r["supply-stock-confirm"].replace("{item}", this.state.item.name);
        const view = (
            <>
                <BSModal.Header>
                    <BSModal.Title>{title}</BSModal.Title>
                </BSModal.Header>

                <BSModal.Body>
                    {message}

                    {!this.state.isSubmittingSupply ? 
                        <Formik
                            onSubmit={this.handleSubmitSupplyStock}
                            initialValues={{
                                quantity: 1,
                                warehouse_id: 2
                            }}>
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                            }) => (
                                <Form className="supply-stock-form"
                                    noValidate validated={false} onSubmit={handleSubmit}>
                                    <Form.Group as={Col} xs="4" sm="3">
                                        <Form.Label><b>{AppContext.r["quantity"]}</b></Form.Label>
                                        <Form.Control type="number" 
                                            min="1" step="1" pattern="[0-9]"
                                            name="quantity"
                                            value={values.quantity}
                                            onChange={handleChange} />
                                    </Form.Group>
                                                        
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>{AppContext.r["warehouse"]}</Form.Label>

                                        <RemoteSelect name="warehouse_id" key={values.warehouse_id} value={values.warehouse_id} 
                                                        onChange={handleChange} nullOption={false}
                                                        options={this.warehouseOptions} />
                                    </Form.Group>
{/*                                     
                                    <RemoteSelect name="warehouseId" className="form-control-sm" options={this.state.warehouses} nullOption={false} 
                                        key={this.state.warehouse_id} value={this.state.warehouse_id}
                                            onChange={(e, value) => { 
                                                const warehouseId = parseInt(value, 10);
                                                console.log(warehouseId);

                                                this.setState({
                                                    warehouse_id: warehouseId
                                                });

                                                this.onChangeWarehouse(warehouseId);
                                            }} /> */}

                                    <br />
                                    <Button type="submit">Submit</Button>
                                </Form>
                            )}
                        </Formik>
                        : AppContext.r["preloader"]
                    }

                </BSModal.Body>
            </>
        );

        this.setState({
            showModal: true,
            modalView: view
        });
    }
}