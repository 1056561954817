import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Alert, AlertTitle } from '@material-ui/lab';
// import { Switch } from '@material-ui/core';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as yup from 'yup';

import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
// import MenuProApi from "api/MenuPro";
import StripeApi from "api/Stripe";
import FormImage from 'framework/FormImage';
import { asWidget } from 'framework/asWidget.js';
import ActiveWeek from 'framework/ActiveWeek';
import ActionButton from 'framework/ActionButton';
// import { ContactSupportOutlined } from '@material-ui/icons';

// var QRCode = require('qrcode.react');
var HtmlToReactParser = require('html-to-react').Parser;

class StoreWidget extends React.Component {
    formImageRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            store: this.props.restaurant,
            enableEdit: false,
            openingTimes: null, // Storeable Value
            enableTakeaway: false // Storeable Value
        };
    }

    async componentDidMount() {
        const {store} = this.state;

        // Retrieve Stripe Connected Account
        const r = await StripeApi.RetrieveAccount(store.id);
        console.log(r);

        this.setState({
            stripeAccount: (r && r.data && r.status === 200) ? r.data : null
        });

        if(document.getElementById("Description")) {
            const htmlToReactParser = new HtmlToReactParser();
            const reactElement = htmlToReactParser.parse(store.description);

            ReactDOM.render(
                reactElement,
                document.getElementById('Description')
            );
        }
        
        if(store.opening_times)
            this.setState({
                openingTimes: JSON.parse(store.opening_times)
            });

        console.log(store);
    }

    restaurantPage(includeHttp = false) {
        return ((includeHttp) ? "https://" : "") + this.state.store.subdomain + "." + AppContext.s["access-domain"];
    }

    onImageFileChanged = async (file, attributeName = 'image') => {
        console.log(AppContext.s["api-url"] + "/users/media")
        const response = await CoreApi.uploadImageFile(file, AppContext.s["api-url"] + "/users/media", attributeName);
        // await Core.EditUser(user.id, user);
        console.log(response);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                image_name: file.name
            };

            item[attributeName] = response.data.name;
            console.log(item);

            await CoreApi.updateItem(AppContext.s["api-url"] + '/shops/'+ this.state.store.id, item);
        // await t.update(item, attributeName === 'image', attributeName === 'cover');
        }
    }

    onDeleteImage = async () => {
        // await CoreApi.EditUser(this.props.auth.user.id, {image: "-1"});
        console.log(await CoreApi.updateItem(AppContext.s["api-url"] + '/shops/'+ this.state.store.id, {image: "-1"}));
        
        this.formImageRef.current.resetImage();
    }

    onUpdate = async (values) => {
        this.props.setOverlayMessage(AppContext.r["preloader"]);

        if(this.openingTimes)
            values["opening_times"] = JSON.stringify(this.openingTimes);

        const response = await CoreApi.updateItem(AppContext.s["api-url"] + '/shops/'+ this.state.store.id, values);

        console.log(response, values);
        if(response && response.status === 202) {
            const store = {...this.state.store};
            
            store["name"] = values.name;
            // ...

            this.setState({
                store: store
            });
        }

        this.props.setOverlayMessage(null);
    }

    // onUpdate = async () => {
    //     const {store} = this.state;

    //     this.props.setOverlayMessage(AppContext.r["preloader"]);

    //     const values = {
    //         opening_times: JSON.stringify(this.openingTimes)
    //     };

    //     await CoreApi.updateItem(AppContext.s["api-url"] + '/shops/'+ store.id, values);

    //     this.props.setOverlayMessage(null);
    // }

    stripeOnboard = async () => {
        const {store} = this.state;

        const r = await StripeApi.OnBoard(store.id);
        console.log(r);

        if(r && r.status === 201) {
            // AppContext.history.replace(r.data.account_links.url);
            window.location = r.data.account_links.url;
        }
    }

    stripeViewAccount = async () => {
        const {store} = this.state;

        const r = await StripeApi.LoginLink(store.id);
        console.log(r);

        if(r && r.status === 200 && r.data.login_link) {
            // AppContext.history.replace(r.data.account_links.url);
            window.location = r.data.login_link.url;
        }
    }

    render() {
        const { store, enableEdit, stripeAccount } = this.state;

        if(!store)
            return AppContext.r["preloader"];

        // Stripe OnBoard/OnBoarded View
        let stripeView = "";

        if(stripeAccount === undefined) {
            stripeView = AppContext.r["preloader"];
        } else {
            if(stripeAccount === null || !stripeAccount.charges_enabled)
                stripeView = 
                    <>
                        {AppContext.r["stripe-partners"]}

                        <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                            action={this.stripeOnboard}>{AppContext.r["connect-stripe"]}</ActionButton>
                        
                        <br />
                        
                        <Alert className="stripe-error-alert" severity="error">
                            <AlertTitle>{(stripeAccount && !stripeAccount.charges_enabled) && AppContext.r["stripe-charges-not-enabled"]}</AlertTitle>
                            
                            {AppContext.r["stripe-required"]}</Alert>
                    </>;
            else
                stripeView =
                    <>
                        <p>{AppContext.r["stripe-successfully-connected"]}</p>

                        { !stripeAccount.payouts_enabled && stripeAccount.type !== "standard" &&
                            <>
                                <Alert className="stripe-error-alert" severity="error">
                                    {AppContext.r["stripe-payouts-not-enabled"]}
                                </Alert>
                                <br />
                            </> }

                            {store.stripe_account_type === "express" ?
                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeViewAccount}>{AppContext.r["view-stripe-account"]}</ActionButton> :
                                    AppContext.r["view-stripe-dashboard"] }
                    </>;
        }

        return (
            <>
                <h1 className="your-restaurant-title">{AppContext.r["your-shop"]}</h1>

                <h2>{store.name}</h2>

                <div className="actions">
                    <ActionButton selected={enableEdit} 
                        tooltip={!enableEdit ? AppContext.r["enable-edit"] : AppContext.r["disable-edit"]}
                        action={() => this.setState({enableEdit: !enableEdit})}>
                        <IconButton aria-label="settings">
                            <SettingsIcon />
                        </IconButton>
                    </ActionButton>
                </div>
                
                <Row>
                    <Col>
                        <FormImage type="avatar" viewOnly={!enableEdit} rounded height="128px" maxWidth="128px" 
                            ref={this.formImageRef}
                            imageUrl={AppContext.s["api-url"] + '/shops/'+ store.id + '/image'}
                            onDelete={this.onDeleteImage}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Col>
                </Row>
{/* 
                <FormImage type="avatar" viewOnly rounded height="128px" maxWidth="128px" 
                    imageUrl={AppContext.s["api-url"] + '/shops/'+ store.id + '/image'} /> */}
{/* 
                { enableEdit && 
                    <>
                        {/* <FormControlLabel
                            control={<Switch checked={store.enable_takeaway} onChanged={this.} name="checkedEnableTakeaway" />}
                            label="Primary" /> */}
                        {/* <h3>{AppContext.r["collection-hours"]}</h3>

                        {<ActiveWeek values={this.state.openingTimes} onChanged={(s) => this.openingTimes = s } />}

                        <br />
                        <Button className="update-store-button" onClick={this.onUpdate}>{AppContext.r["save"]}</Button>
                        <br /><br />
                    </>
                } */}

                { enableEdit && 
                    <Row>
                        <Col>
                            <Formik
                                initialValues={{ name: store.name, description: store.description }}
                                validationSchema={yup.object({ name: yup.string().required().min(6) })}
                                onSubmit={(values) => this.onUpdate(values)}>

                                {({ isSubmitting }) => (
                                    <Form className="form store-widget-edit">
                                        <Row className="justify-content-center">
                                            <Col md={8}>
                                                <Field className="field" component={TextField} name="name" type="text"
                                                    label={AppContext.r["name"]} />
                                            </Col>

                                            <Col md={10}>
                                                {/* <Form.Label>{AppContext.r["description"]}</Form.Label> */}
                                                <Field name="description">
                                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                                </Field>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <br />
                                                <h3>{AppContext.r["collection-hours"]}</h3>
                                                <ActiveWeek values={this.state.openingTimes} onChanged={(s) => this.openingTimes = s } />
                                                <br />
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center">
                                            <Button type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["save"]}</Button>
                                        </Row>

                                        {isSubmitting && <LinearProgress />}
                                        <br />
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                }

                <div className="connect-with-stripe">
                    <h3>{AppContext.r["payments"]}</h3>

                    {stripeView}
                </div>
            </>);
    }
}

export default asWidget(StoreWidget);