import React from 'react';

import Settings from '../../settings';

export default function loadClientResources (resources) {
    resources["shop"] = "Negozio";
    resources["shops"] = "Negozi";
    resources["shop-item-categories"] = "Categorie";
    resources["shop-item-category"] = "Categoria";
    resources["shop-item-sub-category"] = "Sottocategoria";
    resources["shop-item-sub-categories"] = "Sottocategorie";
    resources["highlighted"] = "In Evidenza";
    resources["percent-discount"] = "Sconto %";
    resources["placed-at"] = "Piazzato Il";
    resources["requested-date"] = "Data Richiesta";
    resources["order"] = "Ordine";
    resources["orders"] = "Ordini";
    resources["order-details"] = "Dettagli Ordine";
    resources["brand"] = "Brand";
    resources["shop-item-brand"] = "Marca";
    resources["shop-item-brands"] = "Marche";
    resources["customer"] = "Cliente";
    resources["customers"] = "Clienti";
    resources["pending-orders"] = "Nuovi Ordini";
    resources["no-pending-orders"] = "Non hai nuovi ordini.";
    resources["payment"] = "Pagamento";
    resources["order-to-table"] = "Ordine al Tavolo";
    resources["delivery"] = "Delivery";
    resources["takeaway"] = "Take Away";
    resources["opening-hours"] = "Orari di Apertura";
    resources["collection-hours"] = "Orari di Raccolta";
    resources["discount"] = "Sconto";
    resources["order-id-is"] = "ID ordine";
    resources["customer-notes"] = "Note Cliente";
    
    resources["payments"] = "Pagamenti";
    resources["stripe-partners"] = <>
        <p>Uderly è partner di Stripe per la gestione dei pagamenti.</p>
        <p>Connettiti con Stripe per ricevere i pagamenti sul tuo account.</p>
        <p><small>Questa azione ti reindirizzerà al form Stripe di affilazione con Uderly.</small></p>
    </>;
    resources["connect-stripe"] = "Connetti Stripe";
    resources["stripe-charges-not-enabled"] = <>Non hai completato il processo di affiliazione per ricevere pagamenti.</>
    resources["stripe-required"] = <>E' necessario impostare i tuoi dati per poter vendere e ricevere pagamenti su {Settings["project-name"]}.</>
        {/* <br/>Se non ti senti ancora pronto, puoi provare la partecipazione ad un evento di gioco IQ Space tramite i biglietti gratuiti emessi, visualizzabili nella scheda dell'evento.</>; */}
    resources["stripe-payouts-not-enabled"] = 
        <>Hai fornito tutti i dati per poter vendere su {Settings["project-name"]} ma c'è ancora qualcosa che dovresti controllare per ricevere fondi sul tuo conto corrente.
        <br/>Per favore verifica il tuo account Stripe. Se pensi di aver fornito tutti i dati richiesti, non ti preoccupare e considera cortesemente che, per verificare la tua identità, Stripe potrebbe richiedere un paio di giorni lavorativi.
        <br/>Puoi già iniziare ad usare {Settings["project-name"]}!</>
    resources["stripe-successfully-connected"] = "Hai connesso con successo i tuoi dati di pagamento con Stripe.";
    resources["view-stripe-account"] = "Account Stripe";
    resources["view-stripe-dashboard"] = <p>Puoi gestire i tuoi pagamenti direttamente dalla tua <a href="https://dashboard.stripe.com/" target="_blank">dashboard Stripe</a>.<br />Se desideri cambiare o rimuovere il tuo account Stripe, contattaci.</p>;
    resources["currency"] = "Valuta";
}