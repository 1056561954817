export default function loadClientResources (resources) {
    resources["career"] = "Carriera";
    resources["careers"] = "Carriere";
    resources["company"] = "Azienda";
    resources["message"] = "Messaggio";
    resources["careers"] = {
        1: "Designer",
        2: "Developer",
        3: "Animator",
        4: "3D Artist",
        5: "Video-Maker"
    };
}