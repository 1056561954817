import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';

export default class Uderly {
    static async MyStores() {
        return await Axios.get(AppContext.s["api-url"] + "/mystores")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }
    
    static async MediaStockCategories(storeSubdomain) {
        return await Axios.get(AppContext.s["net-api-url"] + "/mediastock/categories/?storeSubdomain=" + storeSubdomain)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
    
    static async MediaStockRatings(storeSubdomain) {
        return await Axios.get(AppContext.s["net-api-url"] + "/mediastock/ratings/?storeSubdomain=" + storeSubdomain)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
    
    static async MediaStockChannels(storeSubdomain) {
        return await Axios.get(AppContext.s["net-api-url"] + "/mediastock/channels/?storeSubdomain=" + storeSubdomain)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
}