import React from 'react'
import { Col, Form } from 'react-bootstrap'

import {ContentRoute} from "_metronic/layout";

import AppContext from '../../AppContext';
import Module from "app/modules/Module";

let yup = require('yup');

export default class EventType extends Module {
    enableDelete = false;
    
    info = EventType.Info;
    
    static Info = {
        nameResource: "event-types",
        icon: "fas fa-cubes admin-menus-color",
        path: "/eventtypes",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <EventType match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["event-type"];
    }

    get apiPath() {
      return '/events/types';
    }
    
    get schema () {
        return yup.object({
            type: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id"
            },
            {
                "Title": AppContext.r["title"],
                "Field": "type"
            }
        ];
    }

    get initialValues() {
        return {
            title: "",
        };
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} xs="12">
                        <Form.Label>{AppContext.r["type"]}*</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["type"]} name="type"
                                        value={values.type} onChange={handleChange}
                                        isValid={touched.type && !errors.type} isInvalid={!!errors.type} />
                    </Form.Group>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}