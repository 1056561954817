import ProjectIdsEnum from './settings/ProjectIdsEnum';

import settingsUderly from "./settings/settings-uderly";
import settingsUderlyPro from "./settings/settings-uderlypro";
import settingsQuartarone from "./settings/settings-quartarone";
import settingsQrtService from "./settings/settings-qrtservice";
import settingsIqSpace from "./settings/settings-iqspace";
import settingsWoolSafe from "./settings/settings-woolsafe";
import settingsStay from "./settings/settings-stay";
import settingsVaffa from "./settings/settings-vaffa";
import settingsRafala from "./settings/settings-rafala";

const selectedProject = ProjectIdsEnum.Rafala; // Change this value to select the client's configuration

const settings = [];

settings['provider'] = {
    name: 'Uderly',
    website: 'www.uderly.com',
    websiteUrl: 'https://www.uderly.com',
    contactFormUrl: 'https://www.uderly.com/#contact',
};
settings["project-id"] = selectedProject;
settings["project-name"] = "Uderly";
settings["language"] = "en";
settings["logo-dark"] = "/assets/images/logo.png";
settings["logo-light"] = "/assets/images/logo.png";
settings["mini-logo-dark"] = "/assets/images/mini-logo.png";
settings["mini-logo-light"] = "/assets/images/mini-logo.png";
settings["login-aside-background-path"] = "/assets/images/login-aside.jpg";
settings["fb-app-id"] = '1889454704637203';
settings["password-min-length"] = 8;

settings["client-id"] = "2";
settings["client-secret"] = "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu";
settings["api-url"] = "https://api.uderly.com/api/v1";
settings["net-api-url"] = "https://api.net.uderly.com";
// settings["net-api-url"] = "https://localhost:44321";

if (selectedProject === ProjectIdsEnum.Uderly)
    settingsUderly(settings);
if (selectedProject === ProjectIdsEnum.UderlyPro)
    settingsUderlyPro(settings);
if (selectedProject === ProjectIdsEnum.Quartarone)
    settingsQuartarone(settings);
if (selectedProject === ProjectIdsEnum.QrtService)
    settingsQrtService(settings);
if (selectedProject === ProjectIdsEnum.IqSpace)
    settingsIqSpace(settings);
if (selectedProject === ProjectIdsEnum.WoolSafe)
    settingsWoolSafe(settings);
if (selectedProject === ProjectIdsEnum.Stay)
    settingsStay(settings);
if (selectedProject === ProjectIdsEnum.Vaffa)
    settingsVaffa(settings);
if (selectedProject === ProjectIdsEnum.Rafala)
    settingsRafala(settings);

settings["host-url"] = "https://" + settings["website"];

export default settings;