import React from 'react';
import { Formik } from 'formik';
import { Row, Col, Form, Button, Modal as BSModal } from 'react-bootstrap';
import { format } from 'date-fns'
import * as yup from 'yup';

import { ContentRoute } from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import { ModuleViewModeEnum } from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import JsonToTable from "framework/JsonToTable";
import RafalaApi from 'api/Rafala';
import CoreApi from 'api/Core';

export default class Stock extends Module {
    info = Stock.Info;

    mode = ModuleViewModeEnum.SingleItem;
    
    state = {
        ...this.state,
        warehouses: null,
        warehouse: null,
        item: { }
    }

    get pathname () {
        return "/shop/stock";
    }
    
    static Info = {
        nameResource: "stock",
        icon: "fas fa-layer-group",
        path: "/shop/stock",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Stock match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["stock"];
    }

    get tableHead () {
        const t = this;

        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
        },{
            "Title": AppContext.r["product-code"],
            "Field": "product_code"
          },{
            "Title": AppContext.r["name"],
            "Field": "name"
          },{
            "Title": AppContext.r["availability"],
            "Field": "availability_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.availabilityOptions[o.availability_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["category"],
            "Field": "category_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.categoryOptions[o.category_id];
                return (field) ? field.name : "";
            }
          }
          ,{
            "Title": AppContext.r["shop-item-sub-category"],
            "Field": "sub_category_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.allCategoryOptions[o.sub_category_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["brand"],
            "Field": "brand_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.brandOptions[o.brand_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["quantity"],
            "Adapter": function(o) {
                return o.pivot.quantity;
            }
          },{
            "ClassName": "flex-justify-center",
            "Adapter": function(o) {
                return (
                    <>
                        <Button size="sm" variant="info" onClick={() => t.showHistory(o)}>History</Button>
                        <Button size="sm" onClick={() => t.confirmTransferItem(o)}>Transfer</Button>
                    </>);
            }
          }
        ];
    }

    get schema () {
        return yup.object({ });
    }

    handleSubmitTransferItem = async (values) => {
        this.setState({
            modalBusy: true
        });
        
        console.log(await RafalaApi.TransferItem(this.state.warehouse_id, parseInt(values.warehouse_id, 10), this.state.item.id, values.quantity));

        console.log("TRANSFER", this.state.warehouse_id, parseInt(values.warehouse_id, 10), this.state.item.id, values.quantity);

        this.onChangeWarehouse();

        this.setState({
            showModal: false,
            modalBusy: false,
        });
    }

    showHistory = async (item) => {
        const historyTableHead = [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none",
            },{
                "Title": AppContext.r["name"],
                "Adapter": function(o) {
                    return o.user.name + " " + (o.user.surname ? o.user.surname : "");
                }
            },{
                "Title": AppContext.r["quantity"],
                "Adapter": function(o) {
                    return o.quantity;
                }
            },{
                "Title": "From",
                "Adapter": function(o) {
                    return o.from_warehouse.name;
                }
            },{
                "Title": AppContext.r["previous-stock-level"],
                "Adapter": function(o) {
                    return o.from_warehouse_previous_stock_level;
                }
            },{
                "Title": AppContext.r["new-stock-level"],
                "Adapter": function(o) {
                    return o.from_warehouse_previous_stock_level - o.quantity;
                }
            },{
                "Title": "To",
                "Adapter": function(o) {
                    return o.to_warehouse.name;
                    // return t.state.warehouse.name;
                }
            },{
                "Title": AppContext.r["previous-stock-level"],
                "Adapter": function(o) {
                    return o.to_warehouse_previous_stock_level;
                }
            },{
                "Title": AppContext.r["new-stock-level"],
                "Adapter": function(o) {
                    return o.to_warehouse_previous_stock_level + o.quantity;
                }
            },{
                "Title": AppContext.r["done-at"],
                "Adapter": (o) => format(new Date(o.created_at), 'd/MM/yyyy h:mm')
            }
        ];
        
        const response = await RafalaApi.History(this.state.warehouse_id, item.id);

        if(response && response.data) {
            const view = 
                <div className="history-modal">
                    <h3>{item.name + " (" + item.product_code + ")"}</h3>
                    <JsonToTable head={historyTableHead} body={response.data} />
                </div>;

            this.setState({
                showModal: true,
                modalBusy: false,
                modalView: view
            });
        }
    }

    confirmTransferItem = (item) => {
        console.log(item);

        const schema = yup.object({
            quantity: yup.number().min(1).max(item.pivot.quantity),
        });

        const otherWarehouseOptions = this.warehouseOptions.filter(x => x.id !== this.state.warehouse_id);

        const title = AppContext.r["transfer-stock"];
        const message = AppContext.r["transfer-stock-confirm"].replace("{item}", item.name);
        const view = (
            <>
                <BSModal.Header>
                    <BSModal.Title>{title}</BSModal.Title>
                </BSModal.Header>

                <BSModal.Body>
                    {message}

                    {otherWarehouseOptions && otherWarehouseOptions.length > 0 ? 
                        <Formik
                            validationSchema={schema}
                            onSubmit={this.handleSubmitTransferItem}
                            initialValues={{
                                quantity: 1,
                                warehouse_id: otherWarehouseOptions[0].id
                            }}>
                            {({
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                values,
                                touched,
                                errors,
                            }) => (
                                <Form className="supply-stock-form"
                                    noValidate validated={false} onSubmit={handleSubmit}>
                                    <br />
                                    <Form.Group as={Col} xs="4" sm="3">
                                        <Form.Label><b>{AppContext.r["quantity"]}</b></Form.Label>
                                        {/* <Form.Control type="number" 
                                            min="1" max={item.pivot.quantity}
                                            step="1" pattern="[0-9]"
                                            name="quantity"
                                            value={values.quantity}
                                            onChange={handleChange} /> */}
                                            
                                        <Form.Control type="number" name="quantity"
                                            value={values.quantity} min="1"
                                            onChange={e => {
                                                e.preventDefault();
                                                const { value } = e.target;
                                                // const regex = /^(\s*|0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                const regex = /^\d+$/;

                                                if (regex.test(value.toString())) {
                                                    if(value === 0) {
                                                        setFieldValue(`quantity`, 1);
                                                        values.quantity = 1;
                                                    } else if(value <= item.pivot.quantity) {
                                                        setFieldValue(`quantity`, value);
                                                        values.quantity = value;
                                                    } else {
                                                        setFieldValue(`quantity`, item.pivot.quantity);
                                                        values.quantity = item.pivot.quantity;
                                                    }
                                                }
                                            }}
                                            isValid={touched.quantity && !errors.quantity} isInvalid={!!errors.quantity} />
                                    </Form.Group>

                                    <br />
                                    <Form.Group as={Col} md="6">
                                        <Form.Label><b>{AppContext.r["warehouse"]}</b></Form.Label>

                                        <RemoteSelect name="warehouse_id" key={values.warehouse_id} value={values.warehouse_id} 
                                                        onChange={handleChange} nullOption={false}
                                                        options={otherWarehouseOptions} />
                                    </Form.Group>

                                    <br />
                                    <Button type="submit">Transfer</Button>
                                </Form>
                            )}
                        </Formik>
                        : AppContext.r["preloader"]
                    }

                </BSModal.Body>
            </>
        );

        this.setState({
            showModal: true,
            modalView: view,
            item: item
        });
    }

    onChangeWarehouse = async (warehouseId = null) => {
        const warehouse_id = warehouseId ? warehouseId : this.state.warehouse_id
        // const url = AppContext.s["api-url"] + "/warehouses/" + warehouse_id;
        // const res = await axios.get(url, { headers: { Authorization: AppContext.Auth.bearer } });
        const res = await RafalaApi.Warehouse(warehouse_id);
        
        if(res && res.data) {
            this.setState({
                warehouse: res.data.data
            });
        }
    }

    async beforeComponentDidMount() {
        this.availabilityOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitemavailabilities", "id");
        this.brandOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitems/brands", "id");
        this.categoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitems/categories", "id");
        this.allCategoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitems/categories?all=1", "id");

        this.warehouseOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/warehouses", "id");
        let warehouse_id = null;
        
        if(this.warehouseOptions)
            for(let w of this.warehouseOptions)
                if(w && w.id) {
                    warehouse_id = w.id;
                    break;
                }

        this.otherWarehouseOptions = this.warehouseOptions.filter(x => x.id !== this.state.warehouse_id);

        this.setState({
            warehouses: this.warehouseOptions,
            warehouse_id: warehouse_id
        });

        await this.onChangeWarehouse(warehouse_id);
    }

    get mask () {
        if(!this.state.warehouses)
            return AppContext.r["preloader"];

        const { warehouse } = this.state;
        const items = warehouse ? warehouse.items : [];

        return (
            <div>
                <h2>{AppContext.r["stock"]}</h2>

                <Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["warehouse"]}</Form.Label>
                    
                        <RemoteSelect name="warehouseId" className="form-control-sm" options={this.state.warehouses} nullOption={false} 
                            key={this.state.warehouse_id} value={this.state.warehouse_id}
                                onChange={(e, value) => { 
                                    const warehouseId = parseInt(value, 10);
                                    console.log(warehouseId);

                                    this.setState({
                                        warehouse_id: warehouseId
                                    });

                                    this.onChangeWarehouse(warehouseId);
                                }} />
                    </Form.Group>
                </Row>

                <Row>
                    <Col>
                        <JsonToTable className="stock" head={this.tableHead} body={items} />
                    </Col>
                </Row>

                {/* { this.modal("history") } */}
            </div>
        );
    }
}