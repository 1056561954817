import React from 'react';
import { Col, Form } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import { add, format } from 'date-fns';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RangePicker } from 'react-trip-date';

import { ContentRoute } from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import StayApi from "api/Stay";
import CopyLink from "../../../framework/CopyLink";
import _TripContacts from "./_TripContacts";

let yup = require('yup');

export default class Trip extends Module {
    showPagination = true;

    info = Trip.Info;
    
    static Info = {
        nameResource: "trips",
        icon: "fas fa-suitcase-rolling",
        path: "/trips",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Trip match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["trip"];
    }

    get apiPath() {
        return '/stores/null/trips';
    }

    get itemApiPath() {
        return '/stores/trips';
    }

    get updateApiPath() {
        return '/stores/trips';
    }

    get deleteApiPath() {
        return '/stores/trips';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required(),
            guests_count: yup.number().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell"
            },
            {
                "Title": AppContext.r["title"],
                "Field": "title"
            },
            {
                "Title": AppContext.r["guests"],
                "Field": "guests_count"
            },
            {
                "Title": "Check-in",
                "Adapter": (o) => format(new Date(o.arriving_at), 'd MM yyyy')
            },
            {
                "Title": "Check-out",
                "Adapter": (o) => format(new Date(o.departing_at), 'd MM yyyy')
            }
        ];
    }

    get initialValues() {
        const {store} = this.state;
        console.log(store);
        return {
            title: "",
            guests_count: 1,
            arriving_at: format(new Date(), 'yyyy-MM-dd HH:mm'),
            departing_at: format(add(new Date(), { days: 1 }), 'yyyy-MM-dd ' + store.checkout_at),
            checkin_at: format(new Date(), 'yyyy-MM-dd ' + store.checkin_at),
            checkout_at: format(new Date(), 'yyyy-MM-dd ' + store.checkout_at)
        };
    }
    
    async beforeComponentDidMount() {
        const myShopsResponse = await StayApi.MyShops();

        if(myShopsResponse && myShopsResponse.status === 200 && myShopsResponse.data.data[0]) {
            const store = myShopsResponse.data.data[0];

            this.setState({
                store: store
            });
        }
    }

    onChangeRange = (v, setFieldValue) => {
        setFieldValue("arriving_at", v.from);
        setFieldValue("departing_at", v.to);
    }

    get mask () {
        const { item } = this.state;

        const options = [];

        options.push(<option value={null}></option>);
        
        for(let i=1; i<=10; i++)
            options.push(<option value={i}> {i} </option>)

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form className="trip-mask" onSubmit={handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} sm="9">
                        <Form.Label>{AppContext.r["title"]}*</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                        value={values.title} onChange={handleChange}
                                        isValid={touched.title && !errors.title} isInvalid={!!errors.title} autoFocus />
                    </Form.Group>

                    <Form.Group as={Col} sm="3">
                        <Form.Label>{AppContext.r["guests-count"]}*</Form.Label>
                        <Form.Control as="select" name="guests_count" value={values.guests_count} 
                            onChange={handleChange} 
                            isValid={touched.guests_count && !errors.guests_count} isInvalid={!!errors.guests_count}>
                            {options}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} xs="12">
                        <Form.Label>{AppContext.r["notes-for-guests"]} ({AppContext.r["max-255-chars"]})</Form.Label>
                        <Form.Control type="text" as="textarea" rows="3" placeholder={AppContext.r["notes"]} name="notes_host"
                                        value={values.notes_host} onChange={handleChange} maxLength={255} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} sm="12">
                        {/* https://www.npmjs.com/package/react-trip-date */}
                        {/* https://reactjsexample.com/a-date-range-picker-for-reactjs/ */}
                        <RangePicker 
                            disabledAfterDate={add(new Date(), { years: 1 })}
                            selectedDays={{ from: values.arriving_at, to: values.departing_at }}
                            autoResponsive
                            onChange={(v) => this.onChangeRange(v, setFieldValue)} />
                    </Form.Group>
                </Form.Row>
                
                {item && item.id &&
                    <>
                        <Form.Row>
                            <h2>Info About Your Guests</h2>
                        </Form.Row>
        
                        <Form.Row>
                            <Form.Group as={Col} sm="6">
                                <Form.Label>{"Check-in"}</Form.Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker className="checkin-picker" ampm={false}
                                        value={values.checkin_at}
                                        format="HH:mm"
                                        onChange={(v) => {
                                            setFieldValue("checkin_at", format(v, 'yyyy-MM-dd HH:mm'));
                                        }} />
                                </MuiPickersUtilsProvider>
                            </Form.Group>
        
                            <Form.Group as={Col} sm="6">
                                <Form.Label>{"Check-out"}</Form.Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker className="checkout-picker" ampm={false}
                                        value={values.checkout_at}
                                        format="HH:mm"
                                        onChange={(v) => {
                                            setFieldValue("checkout_at", format(v, 'yyyy-MM-dd HH:mm'));
                                        }} />
                                </MuiPickersUtilsProvider>
                            </Form.Group>
        
                            {(values.notes && values.notes.length > 0) &&
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>{AppContext.r["guest-notes"]}</Form.Label>
                                    <p className="guest-notes">{values.notes}</p>
                                </Form.Group> }
                        </Form.Row>
                        
                        <h3>Link</h3>
                        <p>{AppContext.r["provide-link"]}</p>
                        <Form.Row>
                            <CopyLink url={!values.store ? "" : AppContext.s["project-url"] + "/" + values.store.subdomain + "/" + values.token} />
                        </Form.Row>
                        
                        <hr />
                        <h3>{AppContext.r["contacts"]}</h3>
                        <_TripContacts tripId={item.id} />
                    </> }

                {this.formFooter}
            </Form>);
    }
}