import React from 'react';
import Axios from 'axios';
import { format } from 'date-fns';

import AppContext from 'app/AppContext';
import JsonToTable from "framework/JsonToTable";
import ShopOrder from '../../pages/Rafala/ShopOrder.js';
import EnotecaOrder from '../../pages/Rafala/EnotecaOrder.js';
import { asWidget } from 'framework/asWidget.js';

class ShopOrderWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: null
        };

        if(this.props.showRequestedDate)
            this.tableHead.push({
                "Title": AppContext.r["requested-date"],
                "Field": "requested_date",
                "Adapter": function(o) {
                    return (o.requested_date === null) ? "" : o.requested_date.substring(0,10);
                }
            });

        this.interval = null;
    }

    tableHead = [
        {
            "Title": AppContext.r["id"],
            "ClassName": "d-none d-sm-table-cell",
            "Field": "id"
        },{
            "Title": AppContext.r["type"],
            "Adapter": function(o) {
                return (o && o.shop_order_type_id) ? AppContext.r["order-type"][o.shop_order_type_id] : "";
            }
        },{
            "Title": AppContext.r["name"],
            "Field": "name",
            "Adapter": function(o) {
                return (o.user === null) ? "" : o.user.name + " " + ((o.user.surname !== null) ? o.user.surname : "");
            }
        },{
            "Title": AppContext.r["placed-at"],
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": (o) => format(new Date(o.created_at), "d/MM/yyyy H:mm")
        },{
            "Title": AppContext.r["note"],
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": (o) => (o.note && o.note.length > 50) ? (o.note.substring(0, 50) + "...") : ""
        },{
            "Title": AppContext.r["status"],
            "Field": "status",
            "Adapter": function(o) {
                return (o.status === null) ? "" : o.status.title;
            }
        }
    ];
    
    fetchOrders = () => {
        // Only show orders not completed (received)
        Axios.get(AppContext.s["host-url"] + "/api/v1/shop/"+this.props.shopId+"/orders?filters={\"status\":[1,2]}") // Rafalà [1,2,3]
            .then(response => {
                this.setState({
                    orders: response.data.data
                });
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static updateInterval = 3000; // ms

    componentDidMount() {
        const t = this;

        this.fetchOrders();

        if(!this.interval)
            this.interval = setInterval(() => {
                if(window.location.pathname === "/") {
                    // console.log(window.location.pathname)
                    t.fetchOrders();
                }
            }, ShopOrderWidget.updateInterval);
    }
    
    componentWillUnmount() {
        if(this.interval)
            clearInterval(this.interval);
        this.interval = null;
    }

    onRowClick = (order) => {
        if(order.shop_id === 2)
            this.props.setRedirectTo(EnotecaOrder.Info.path + "/" + order.id);
        else
            this.props.setRedirectTo(ShopOrder.Info.path + "/" + order.id);
    }

    get widget() {
        const view = (this.state.orders == null) ? AppContext.r["preloader"] : 
            this.state.orders.length > 0 ? 
                <JsonToTable onRowClick={this.onRowClick} head={this.tableHead} body={this.state.orders} /> : <div><i className="far fa-folder-open"></i><p>{AppContext.r["no-pending-orders"]}</p></div>

        return (
            <div className="shop-order-widget">
                {view}
            </div>
        );
    }

    render() {
        return (
            <div>
                <h1>{AppContext.r["pending-orders"]}</h1>

                {this.widget}
            </div>);
    }
}

export default asWidget(ShopOrderWidget);