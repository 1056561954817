export default function loadClientResources (resources) {
    resources["store"] = "Property";
    resources["stores"] = "Properties";
    resources["enabled"] = "Enabled";
    resources["video-streams"] = "Video Streams";
    resources["team-members"] = "Team Members";
    resources["category"] = "Category";
    resources["categories"] = "Categories";
    resources["trip"] = "Trip";
    resources["trips"] = "Trips";
    resources["notes-for-guests"] = "Note for the guests";
    resources["nationality"] = "Nationality";
    resources["issued-by"] = "Issued by";
    resources["passport-id"] = "Passport ID";
    resources["upcoming-trips"] = "Upcoming Trips";
    resources["insert-trip"] = "Insert Trip";
    resources["checkin-at"] = "Check-in At";
    resources["checkout-at"] = "Check-out At";
    resources["guest"] = "Guest";
    resources["guests"] = "Guests"
    resources["guests-count"] = "Guests Count";
    resources["provided-ids"] = "Provided IDs";
    resources["guest-notes"] = "Guest Notes";
    resources["arriving"] = "Arriving";
    resources["departing"] = "Departing";
    resources["provide-link"] = "Send this link to your guests, in order to allow them to provide their info.";
    resources["checkin-from"] = "Check-in From";
    resources["checkout-to"] = "Check-out Up To";
    resources["arrival-info"] = "Arrival Info";
    resources["guests-info"] = "Guests Info";
    resources["contact-info"] = "Contact Info";
    resources["property-location"] = "Property Location";
    resources["check-x-info"] = "Check-in & Check-out Info";
    resources["parking-spaces"] = "Parking Spaces";
    resources["suitable-for-work"] = "Suitable for work";
    resources["suitable-for-work-desc"] = "Why is your space suitable for work? (e.g. desk, monitor, fast internet connection, etc...)";
    resources["message"] = "Message";
    resources["messages"] = "Messages";
}