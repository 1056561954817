import settings from "./settings";
import resources from "./resources/resources";

export default class AppContext {
    static get s() { return settings; }
    static get r() { return resources; }

    static currentModulePath = "/";
    static layout = null;
    static history = null;

    static yupAddPhoneTest = (yup) => {
        yup.addMethod(yup.string, "phone", function(messageError = AppContext.r['phone-not-valid']) {
            //const regExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9-()]{2,3}\\)[ \\-]*)|([0-9-()]{2,4})[ \\-]*)*?[0-9-()]{3,4}?[ \\-]*[0-9-()]{3,4}?$/
            const regExp = /^[+0-9-()\s]{6,20}?$/;
            
            return this.test('phone', messageError, value => {
                if (value && value.length > 0) {
                    value = value.replace(/\s/g,'');
                    console.log(regExp.test(value))
                    return regExp.test(value);
                }
        
                return true;
            })
        })
    }

    static yupAddUrlTest = (yup) => {
        yup.addMethod(yup.string, "url", function(messageError = AppContext.r['url-not-valid']) {
            const regExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-zA-Z0-9\.-_]{2,}){1,3}(#?\/?[a-zA-Z0-9#\-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%&=_]+&?)?$/;

            return this.test('url', messageError, value => {
                if (value && value.length > 0)
                    return regExp.test(value);
        
                return true;
            })
        })
    }

    static get isLocalhost () {
        return window.location.hostname === "localhost";
    }
}