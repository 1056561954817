import React from 'react';

export default function loadClientResources (resources) {
    resources["shop-not-active"] = "Your shop has not been activated yet.";
    resources["shop-disabled"] = "Your shop has been disabled.";
    resources["no-role"] = "There are no services associated to this account.";
    resources["active"] = "Active";
    resources["shop"] = "Shop";
    resources["shops"] = "Shops";
    resources["shop-item"] = "Menu Item";
    resources["shop-items"] = "Menu Items";
    resources["menu-categories"] = "Menu Categories";
    resources["ingredients"] = "Ingredients";
    resources["note"] = "Note";
    resources["status"] = "Status";
    resources["order-type"] = [];
    resources["order-type"][1] = "Order";
    resources["order-type"][2] = "Delivery";
    resources["order-type"][3] = "Take Away";
    resources["your-restaurant"] = "Your Restaurant";
    resources["your-shop"] = "Your Shop";
    resources["access-url-desc"] = "Your web page is live on";
    resources["qr-desc"] = <p>For a quick access to your page, you could also distribute the following QR code.</p>;
    resources["support-big-screen"] = <div>Your licence allows to view the game on <b>big screens</b> by the following URL:</div>;
    resources["domain"] = "Domain";
    resources["color"] = "Color";
    resources["allowed-extras"] = "Allowed Extras";
    resources["no-extras"] = "There are no extras yet for this item";
    resources["insert-extra"] = "Add Extra";
    resources["vegan"] = "Vegan";
    resources["vegetarian"] = "Vegetarian";
    resources["gluten-free"] = "Gluten Free";
    resources["first-access-datetime"] = "Date & Time First Access";
    resources["first-access-country"] = "Country First Access";
    resources["tables-count"] = "Tables Count";
    resources["big-screen"] = "Big Screen";
    resources["suggest-checkin"] = <span>Pop-up Check-in <i className="fas fa-shield-virus"></i></span>;
    resources["users-check-ins"] = "Users Check-Ins";
    resources["users-check-ins-last-month"] = "Users Check-Ins Last 30 Days";
    resources["users-check-ins-count"] = "# of check-ins";
    resources["generate"] = "Generate";
    resources["days"] = "days";
    resources["report-30-days"] = "Report 30 days";
    resources["report-90-days"] = "Report 90 days";
    resources["ready-for-download"] = "Ready for download";
    resources["order_types"] = [];
    resources["order_types"]["1"] = "Placed";
    resources["order_types"]["2"] = "Preparation";
    resources["order_types"]["3"] = "Ready for collection";
    resources["order_types"]["4"] = "Cancelled by Customer";
    resources["order_types"]["5"] = "Cancelled by Store";
    //     1: "Placed",
    //     2: "Preparation",
    //     3: "Out for Delivery",
    //     4: "Cancelled by Customer",
    //     5: "Cancelled by Store",
    // }
    resources["enable-takeaway"] = "Enable Takeaway";
    resources["service"] = "Service";
    resources["services"] = "Services";
    resources["stripe-product"] = "Stripe Product";
    resources["stripe-products"] = "Stripe Products";
    resources["stripe-product-id"] = "Product Id";
    resources["view-in-stripe"] = "View in Stripe";
}