import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {useSelector} from "react-redux";

import AppContext from "app/AppContext";
import RafalaApi from "api/Rafala";
import ShopWidget from './ShopWidget';
import ShopOrderWidget from './ShopOrderWidget';

const Dashboard = () => {
    const { user } = useSelector(state => state.auth);
    const [ store, setStore ] = useState(null);

    useEffect(() => {
        async function retrieveShop () {
            const response = await RafalaApi.MyShops();
            
            // Manager accounts actually have both shops
            if(response) {
                if(response.data.data && response.data.data.length > 0) {
                    setStore(response.data.data[0]);
                } else {
                    setStore(-1);
                }
            }
        }

        retrieveShop();
    });

    const isShopVisible = user.roles[0].id <= 4;
    const isEnotecaVisible = (user.roles[0].id === 1 || user.roles[0].id === 2 || user.roles[0].id === 4 || user.roles[0].id === 5);

    const shopBox = (
        <Col md={isEnotecaVisible ? 6 : 12}>
            <Link to={"/shop/items"} exact className="box shop-items">
                <div>
                    <i className="fas fa-shopping-cart"></i>
                    <h1>{AppContext.r["shop"]}</h1>
                </div>
            </Link>

            <ShopOrderWidget shopId={1} showRequestedDate={false} />
        </Col>);

    const enotecaBox = (
        <Col md={isShopVisible ? 6 : 12}>
            <Link to={"/enoteca/items"} exact className="box enoteca">
                <div>
                    <i className="fas fa-wine-glass-alt"></i>
                    <h1>Enoteca</h1>
                </div>
            </Link>

            <ShopOrderWidget shopId={2} showRequestedDate={false} />
        </Col>);

    return (
        <div className="home rafala iq-dashboard">
            <Row>
                {!store ? AppContext.r["preloader"] : <ShopWidget className="store" store={store} />}
            </Row>
            <Row>
                {(isShopVisible) && shopBox }
                {(isEnotecaVisible) && enotecaBox }
            </Row>
        </div>);
}

export default Dashboard;