// ActionButton v1.0

import React from 'react';
import {Tooltip} from '@material-ui/core';
// import cn from 'classnames';

export default class ActionButton extends React.Component {
    state = {
        running: false,
        selected: this.props.selected ? true : false,
        disabled: this.props.disabled ? true : false,
        tooltip: this.props.tooltip ? this.props.tooltip : ""
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disabled !== this.props.disabled) {
            this.setState({
                disabled: nextProps.disabled
            });
        }
        
        if (nextProps.selected !== this.props.selected) {
            this.setState({
                selected: nextProps.selected
            });
        }
        
        if (nextProps.tooltip !== this.props.tooltip) {
            this.setState({
                tooltip: nextProps.tooltip
            });
        }
    }

    runAction = async (e) => {
        e.stopPropagation();

        if(this.props.action && !this.state.running && !this.state.disabled) {
            this.setState({
                running: true
            });

            await this.props.action();

            this.setState({
                running: false
            });
        }
    }

    render() {
        const className = this.props.className ? this.props.className : "";
        const c = "action-button " 
            + className
            + (this.props.small ? " small" : "")
            + (this.props.big ? " big" : "")
            + (this.state.selected ? " selected" : "")
            + (this.state.disabled ? " disabled" : "")
            + (this.state.running ? " running" : "");
//open={open} onClose={handleClose} onOpen={handleOpen}

        // const type = this.props.type ? this.props.type : <button />;

        const b = (
            <div className={c} onClick={this.runAction}>
                {this.props.icon && <i className={this.props.icon}></i>}
                {this.props.children}
                <div className="overlay"></div>
            </div>
        );

        return this.state.tooltip ? (
            <Tooltip title={<span className="action-button-tooltip">{this.state.tooltip}</span>}>
                {b}
            </Tooltip>) : b;
    }
}