import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import SettingsIcon from '@material-ui/icons/Settings';

import AppContext from 'app/AppContext';
import StripeApi from "api/Stripe";
import FormImage from 'framework/FormImage';
import ActionButton from 'framework/ActionButton';
import { asWidget } from 'framework/asWidget.js';
import QrLink from 'framework/QrLink';

import 'react-quill/dist/quill.snow.css';

var HtmlToReactParser = require('html-to-react').Parser;

class StoreWidget extends React.Component {
    formImageRef = React.createRef();
    coverFormImageRef = React.createRef();

    state = {
        store: this.props.store,
        stripeAccount: undefined,
        enableEdit: false,
        tabPage: 0
    };

    async componentDidMount() {
        const {store} = this.state;

        if(store.licence_id === 1) {
            // Retrieve Stripe Connected Account
            const r = await StripeApi.RetrieveAccount(store.id);
            console.log(r);
    
            this.setState({
                stripeAccount: (r && r.data && r.status === 200) ? r.data : null
            });
        }

        if(document.getElementById("Description")) {
            const htmlToReactParser = new HtmlToReactParser();
            const reactElement = htmlToReactParser.parse(this.state.store.description);

            ReactDOM.render(
                reactElement,
                document.getElementById('Description')
            );
        }
    }

    stripeOnboard = async () => {
        const r = await StripeApi.OnBoard(this.state.store.id);
        // console.log(r);

        if(r && r.status === 201) {
            window.location = r.data.account_links.url;
        }
    }

    stripeViewAccount = async () => {
        const r = await StripeApi.LoginLink(this.state.store.id);
        // console.log(r);

        if(r && r.status === 200 && r.data.login_link) {
            window.location = r.data.login_link.url;
        }
    }

    storePageUrl(includeHttp = false) {
        const { store } = this.state;
        const subdomain = store.subdomain ? store.subdomain : store.name;
        return AppContext.s["project-url"]+"/" + subdomain;
        //return ((includeHttp) ? "https://www." : "www.") + store.language.domain + "/" + store.subdomain;
    }

    render() {
        const { store, enableEdit, stripeAccount } = this.state;

        if(!store)
            return AppContext.r["preloader"];

        // Stripe OnBoard/OnBoarded View
        let stripeView = "";

        if(stripeAccount === undefined) {
            stripeView = AppContext.r["preloader"];
        } else {
            if(stripeAccount === null || !stripeAccount.charges_enabled)
                stripeView = 
                    <>
                        {enableEdit &&
                            <>
                                {AppContext.r["stripe-partners"]}
        
                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeOnboard}>{AppContext.r["connect-stripe"]}</ActionButton>
                                
                                <br />
                            </> }
                        
                        <Alert className="stripe-error-alert" severity="error">
                            <AlertTitle>{(stripeAccount && !stripeAccount.charges_enabled) && AppContext.r["stripe-charges-not-enabled"]}</AlertTitle>
                            {AppContext.r["stripe-required"]}
                            {/* <Button onClick={() => this.setState({enableEdit: true})}>{AppContext.r["setup"]}</Button> */}
                        </Alert>
                    </>;
            else
                stripeView =
                    <>
                        <p>{AppContext.r["stripe-successfully-connected"]}</p>

                        { !stripeAccount.payouts_enabled && stripeAccount.type !== "standard" && 
                            <>
                                <Alert className="stripe-error-alert" severity="error">
                                    {AppContext.r["stripe-payouts-not-enabled"]}
                                </Alert>
                                <br />
                            </> }

                            {store.stripe_account_type == "express" ?
                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeViewAccount}>{AppContext.r["view-stripe-account"]}</ActionButton> :
                                    AppContext.r["view-stripe-dashboard"] }
                    </>;
        }

        return (
            <>
                <h1>{store.name}</h1>
                
                <Row>
                    <Col>
                        <p>Your stand's cover</p>
                        <FormImage viewOnly={!enableEdit}
                            className="cover"
                            ref={this.coverFormImageRef} ratio={3}
                            imageUrl={AppContext.s["api-url"] + '/stores/'+ store.id + '/cover?updated_at=' + store.updated_at} 
                            onDelete={() => this.onDeleteImage("cover")}
                            onImageFileChanged={(file) => this.onImageFileChanged(file, 'cover')} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>Your company's logo</p>
                        <FormImage type="avatar" viewOnly={!enableEdit} 
                            rounded height="128px" maxWidth="128px" className="avatar"
                            ref={this.formImageRef}
                            imageUrl={AppContext.s["api-url"] + '/stores/'+ store.id + '/image?updated_at=' + store.updated_at}
                            onDelete={this.onDeleteImage}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p style={{marginBottom: 5}}>{AppContext.r["page-at"]}</p>
                        <QrLink value={this.storePageUrl(true)} logoImage={AppContext.s["mini-logo-qr"]} />
                        <p><small>{AppContext.r["wish-change-subdomain"]}</small></p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Link to="/stand" className="edit-stand">
                            {AppContext.r["edit-store-information"]} <SettingsIcon />
                        </Link>
                    </Col>
                </Row>
            </>
        );
    }
}

export default asWidget(StoreWidget);