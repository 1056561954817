import React from 'react';
import { Form, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, FieldArray } from 'formik';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { connect } from 'react-redux';
import * as yup from 'yup';

import CoreApi from '../../../api/Core';
import IqSpaceApi from '../../../api/TriviaPro';
import AppContext from 'app/AppContext';
import {withModal} from 'framework/withModal';
import RemoteSelect from '../../../framework/RemoteSelect';
import QuickModule from 'framework/QuickModule';
import QuickImage from 'framework/QuickImage';
import ProSlider from 'framework/ProSlider';
import { setAlert } from '../../../_metronic/layout/components/layoutSlice';

export function indexToLetter(index) {
    switch(index) {
        case 0: return 'A';
        case 1: return 'B';
        case 2: return 'C';
        case 3: return 'D';
        case 4: return 'E';
        default: return '';
    }
}

export function basicQuestionHead () {
    return [
        {
            "Title": AppContext.r["type"],
            "Field": "type",
            "Adapter": function(o) {
                if(o.image_legacy || o.image) return <i className="far fa-image"></i>;
                if(o.video_url && o.video_url.length > 0) return <i className="fas fa-video"></i>;
                return <i className="fas fa-font"></i>;
            }
        },
        {
            "Title": AppContext.r["text"],
            "Field": "text"
        },
        {
            "Title": AppContext.r["category"],
            "Field": "category_id",
            "ClassName": "d-none d-sm-table-cell category-col",
            "Adapter": function(o) {
                const style={
                    backgroundColor: (o && o.category) ? o.category.color : "transparent"
                };
                const category = (o && o.category) ? o.category.category : "";
                return (<div><div className="category-dot" style={style}>&nbsp;</div>{category}</div>);
            }
        },
        {
            "Title": AppContext.r["exact"],
            "Adapter": (o) => {
                let exact_index = "";

                if(o && o.answers)
                    o.answers.forEach(function (a, i) {
                        if(a && a.is_correct) {
                            exact_index = i;
                        }
                        // console.log('%d: %s', i, value);
                    });
                
                const letter = indexToLetter(exact_index);
                    
                return (
                    <div className="answers">
                        <div className={"letter " + letter}>
                            {letter}
                        </div>
                    </div>);
            }
        }
    ];
}

class _PackageQuestions extends React.Component {
    state = { 
        loading: true,
        query: null
    };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "ClassName": "d-none d-sm-table-cell",
                "Field": "id"
            },
            ...basicQuestionHead(),
            {
                "Title": AppContext.r["actions"],
                "Adapter": (o) => {
                    const playlistsMap = !this.playlistOptions || this.playlistOptions.length === 0 ? 
                        <Link className="add-to-new-playlist" to="/playlists/insert">{AppContext.r["new-playlist"]}</Link>
                        : this.playlistOptions.map(p => <Dropdown.Item onClick={() => this.addToPlaylist(p, o)}>{p.name}</Dropdown.Item>);
    
                    return (
                        <DropdownButton variant="success" id="dropdown-basic-button" size="sm" 
                            title={<><PlaylistAddIcon /><span className="mobile-hidden"> {AppContext.r["add-to"]}</span></>} onClick={(e) => e.stopPropagation()}>
                            {/* <Dropdown.Item href="#/action-1">{AppContext.r["new-playlist"]}</Dropdown.Item> */}
                            {/* <Dropdown.Divider /> */}
                            {playlistsMap}
                        </DropdownButton>
                    );
                }
            }
        ];
    }

    async addToPlaylist(playlist, question) {
        await IqSpaceApi.AddToPlaylist(playlist.id, question.id);
    }

    async addAllToPlaylist(playlist, pack) {
        const response = await IqSpaceApi.AddAllToPlaylist(playlist.id, pack.id);

        if(response.status === 200) {
            this.props.setAlert({ open: true, message: AppContext.r["all-questions-added"] + " \"" + playlist.name + "\"", severity: "success" });
        } else {
            // Eror Message
            this.props.setAlert({ open: true, message: "Error", severity: "error" });
        }
    }

    get schema () {
        return yup.object({
            text: yup.string().required(),
            category_id: yup.number().required()
        });
    }

    async componentDidMount() {
        console.log(this.props.package)
        this.can_edit = this.props.package.can_edit;

        this.playlistOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/playlists");
        const packagesResponse = await IqSpaceApi.Packages(false);
        const packages = packagesResponse.data.data;
        this.packageOptions = CoreApi.mapData(packages, "id");

        const categoriesResponse = await IqSpaceApi.StoreCategories(this.props.package.language_id);
        const categories = categoriesResponse.data.data;
        console.log(categories)
        this.categoryOptions = CoreApi.mapData(categoriesResponse.data.data, "id");

        this.initialValues = {
            text: "",
            duration_ms: 20000,
            image_duration_ms: 7000,
            fixed_score: 0,
            category_id: categories ? categories[0].id : null,
            package_id: this.props.packageId,
            can_edit: true,
            answers: [{text: "", is_correct: 1}, {text: "", is_correct: 0}, {text: "", is_correct: 0}, {text: "", is_correct: 0}]
        };

        this.setState({
            loading: false
        });
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.query !== this.props.query) {
            console.log(nextProps.query)
            this.setState({
                query: nextProps.query
            })
        }
    }

    can_edit = true;

    view = ({ handleChange, values, touched, errors, setFieldValue }) => (
        <>
            <Form.Row className="question-form">
                <Col md="8">
                    {/* <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["package"]}</Form.Label>

                            <RemoteSelect name="package_id" key={values.package_id} value={values.package_id}
                                            onChange={handleChange} onChange2={(e, value) => { this.onChangePackage(e, value, values)}}
                                            disabled={!this.can_edit}
                                            nullOption={false} options={this.packageOptions} />
                        </Form.Group>
                    </Form.Row> */}

                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>{AppContext.r["text"]}*</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["text"]} name="text" maxLength="150" autoFocus
                                        disabled={!this.can_edit}
                                        value={values.text} onChange={handleChange}
                                        isValid={touched.text && !errors.text} isInvalid={!!errors.text} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <h2>{AppContext.r["answers"]}</h2>

                        <FieldArray name="answers"
                            render={arrayHelpers => (
                                <div className="answers">
                                    {values.answers && values.answers.length > 0 && (
                                        values.answers.map((v, index) => (
                                        <div key={index}>
                                            <div className={"letter " + indexToLetter(index)}>{indexToLetter(index)}</div>
                                            <Field type="text"
                                                    name={`answers[${index}].text`}
                                                    disabled={!this.can_edit}
                                                    maxLength="100" />

                                            <label className="checkmark-container">
                                                <input type="checkbox"
                                                    name={`answers[${index}].is_correct`}
                                                    checked={values.answers[index].is_correct === 1}
                                                    disabled={!this.can_edit}
                                                    onChange={e => { //e.target.checked
                                                        console.log(values);

                                                        if(values.answers[index].text.length > 0) {
                                                            for(var x in values.answers)
                                                                values.answers[x].is_correct = 0;
                                                            values.answers[index].is_correct = 1;
                                                            this.setState({ item: values });
                                                        } else {
                                                            alert("Questa risposta è vuota.")
                                                        }

                                                        setFieldValue(`answers[${index}].is_correct`, 1);
                                                    }} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div> ))
                                    )}
                                    <div>
                                    </div>

                                    { typeof errors.answers === 'string' ? <div>{errors.answers}</div> : null }
                                </div>
                            )} />
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["category"]}</Form.Label>
                            
                            <RemoteSelect name="category_id" key={values.category_id} value={values.category_id} onChange={handleChange}
                                            disabled={!this.can_edit} nullOption={false}
                                            fieldToMap="category" options={this.categoryOptions} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["duration"]}</Form.Label>
                            <ProSlider value={values.duration_ms} adapterDividend={1000} minValue="5" maxValue="100" symbol="s"
                                        disabled={!this.can_edit}
                                        onChange={(value) => setFieldValue("duration_ms", value)} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} className="fixed-score-group">
                            <Form.Label>{AppContext.r["fixed-score"]}</Form.Label><br />
                            <Form.Label as={Col}>{AppContext.r["fixed-score-desc"]}</Form.Label><br />
                            <ProSlider value={values.fixed_score} step={250} 
                                        minValue={0} maxValue={10000} symbol=""
                                        disabled={!this.can_edit}
                                        onChange={(value) => setFieldValue("fixed_score", value)} />
                            
                                {!values.fixed_score && 
                                    <p className="default">{AppContext.r["fixed-score-default"]}</p>}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>{AppContext.r["note"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["note"]} name="note"
                                            disabled={!this.can_edit}
                                            value={values.note} onChange={handleChange} />
                        </Form.Group>
                    </Form.Row>
                </Col>

                <Col md="4">
                    <QuickImage item={values} 
                        apiUrl={AppContext.s["api-url"] + '/questions/'}
                        onChanged={() => this.setState({ footerBusy: true })}
                        onUploaded={async (item, attributeName) => {
                            setFieldValue(attributeName, item.image);
                            setFieldValue("image_name", item.image_name);
                            this.setState({ footerBusy: false });
                        }}
                        onDelete={(item, attributeName) => {
                            console.log("DELETE " + attributeName, item)
                            setFieldValue(attributeName, item.image); // Set to -1
                            setFieldValue("image_name", item.image_name); // Set to empty
                        }} />

                    {values.image && 
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["image-duration"]}</Form.Label>
                                <ProSlider value={values.image_duration_ms} adapterDividend={1000} minValue="3" maxValue="30" symbol="s"
                                            disabled={!this.can_edit}
                                            onChange={(value) => setFieldValue("image_duration_ms", value)} />
                            </Form.Group>
                        </Form.Row>
                    }

                    <Form.Row>
                        <Form.Label as={Col}>{AppContext.r["video-url"]}<br /><small>{AppContext.r["video-support"]}</small></Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["video-url"]} name="video_url"
                                        disabled={!this.can_edit || values.image}
                                        value={values.video_url} onChange={handleChange} />

                        {values.image && AppContext.r["video-url-disabled-desc"]}
                    </Form.Row>
                </Col>
            </Form.Row>
        </>);

    render() {
        if(this.state.loading)
            return AppContext.r["preloader"];

        return <QuickModule initialValues={this.initialValues}
            className={this.props.className} enablePagination
            footerBusy={this.state.footerBusy}
            apiPath={'/packages/'+ this.props.packageId + '/questions'}
            createApiPath={'/questions'}
            updateApiPath={'/questions'}
            deleteApiPath={'/questions'}
            query={this.state.query}
            tableHead={this.tableHead} schema={this.schema} view={this.view}
            footerDisabled={this.state.footerDisabled} 
            disableInsert={!this.can_edit} disableEdit={!this.can_edit} disableDelete={!this.can_edit} />;
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(_PackageQuestions));
// export default withModal(_PackageQuestions);