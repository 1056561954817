import React, { Component } from 'react';
import { Form, Col, Pagination, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { Formik } from 'formik';
import Axios from 'axios';
import Cookie from 'react-cookies';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { GlobalHotKeys } from "react-hotkeys";
import LinearProgress from '@material-ui/core/LinearProgress';
import { TextField } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import { SubheaderContext } from "../../_metronic/layout/_core/MetronicSubheader";

import AppContext from '../AppContext';
import CoreApi from "../../api/Core";
import FormImage from '../../framework/FormImage';
import JsonToTable from '../../framework/JsonToTable';
import { setAlert } from '../../_metronic/layout/components/layoutSlice';

import path from 'path';

export class ModuleViewModeEnum {
    static get List() { return 0; }
    static get SingleItem() { return 1; }
    static get Custom() { return 2; }
}

export class ModuleStatusEnum {
    static get rest() { return 0; }
    static get loading() { return 1; }
    static get saving() { return 2; }
    static get overlay() { return 3; }
    static get uploading() { return 4; }
}

const keyMap = {
    NEW_ITEM: "ctrl+shift+n",
    TEST: "n"
};

// Small component as a work-around to get Package to use redux alerts
function Alert (props) {
    const dispatch = useDispatch();

    dispatch(setAlert(props.a));

    return "";
}

export default class Module extends Component {
    static contextType = SubheaderContext;

    constructor(props) {
        super(props);
        
        this.state = {
            status: ModuleStatusEnum.rest,
            records: null,
            item: null,
            filters: { },
            showModal: false,
            modalBusy: false,
            modalMessage: null,
            currentPage: 1,
            lastPage: 0,
            alert: null
        };

        this.mode = ModuleViewModeEnum.List;
        this.jsonTableRef = React.createRef();
        this.formImageRef = React.createRef();
    }

    handlers = {
        NEW_ITEM: event => {
            event.preventDefault();
            console.log(event);

            this.onInsert();
        },
        TEST: event => {
            event.preventDefault();
            console.log(event);

            if(!this.state.item)
                this.onInsert();
        }
        
        // this.setState((state) => {
        //     return {
        //         ...state,
        //         preview: !state.preview
        //     }
        // })
    };

    _updateDelayMs = 1000;
    _updateTimeout = null;

    enableView = true;
    enableInsert = true;
    enableEdit = true;
    enableDelete = true;
    enableDownloadCsv = false;

    _cancelToken = Axios.CancelToken;
    _cancelTokenSource = null;

    _apiUrl = AppContext.s["api-url"];
    get apiUrl() {
        return this._apiUrl;
    }

    _apiPath = null;
    get apiPath() {
        return this._apiPath;
    }
    
    _recordsApiPath = null;
    get recordsApiPath() {
        if(this._recordsApiPath !== null)
            return this._recordsApiPath;

        if(this.apiPath !== null)
            return this.apiPath;

        return null;
    }
    set recordsApiPath(v) {
        this._recordsApiPath = v;
    }
    
    get itemApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get createApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }
    
    get updateApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }
    
    get deleteApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get mediaTransferApiPath() {
        if(this.apiPath !== null)
            return path.join(this.apiPath, "media");
        throw new Error(AppContext.r["method-not-implemented"]);
    }

    get initialValues() {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get schema() {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get tableHead () {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get title() {
        return "[ Title ]";
    }

    _info = {}
    get info() {
        return this._info;
    }
    set info(o) {
        this._info = o;
    }

    get mask() {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get createButton() {
        return (
            <Button type="submit" variant="contained" color="primary" className="form-create">
                {AppContext.r["create"]} <AddIcon />
            </Button>);
    }

    get deleteButton() {
        return (
            <Button variant="contained" className="form-delete danger-button" onClick={this.onDelete}>
                {AppContext.r["delete"]} <DeleteIcon />
            </Button>);
    }

    get insertButton() {
        return <Button variant="contained" className="form-insert success-button" onClick={this.onInsert}>
            {AppContext.r["insert"]} <AddIcon />
        </Button>
    }

    get closeButton() {
        return <Button variant="contained" className="form-close danger-button" onClick={this.onClose}>
            {AppContext.r["close"]} <CloseIcon />
        </Button>;
    }

    setSubHeaderContext = (item = null) => {
        if(this.context) {
            if(item) {
                // Edit Item Actions
                const name = item.name ? item.name : item.title ? item.title : "";
                const displayName = name ? " \"" + name + "\"" : "";
    
                this.context.setTitle(AppContext.r["edit"] + " " + this.title + displayName);
    
                this.context.setActions(
                    <>
                        <span className="item-id">
                            {AppContext.r["item-id"] + " " + item.id}
                        </span>
    
                        {this.closeButton}
                    </>);
            } else {
                item = this.state.item;

                // Insert Item Actions
                if(this.enableInsert) {
                    if(!item && !this.isInsertMode)
                        this.context.setActions(this.insertButton);
                    else
                        this.context.setActions(this.closeButton);
                } else {
                    this.context.setActions("");
                }
            }
        }
    }

    get imageDeliveryApiPath() {
      if(this.apiPath !== null && this.state.item !== undefined && this.state.item.id !== undefined)
        return this.apiPath + "/" + this.state.item.id.toString(10) + "/image";
      return "";
    }
  
    get imageUrl() {
      return (this.imageDeliveryApiPath !== "") ? this.apiUrl + this.imageDeliveryApiPath : "";
    }

    get isEdit() {
        return (this.state.item && this.state.item.id);
    }

    get displayOnEditStyle() {
      return (this.state.item === undefined || this.state.item.id === undefined) ? { display: "none" } : { display: "block"};
    }
  
    get displayOnInsertStyle() {
      return (this.state.item !== undefined && this.state.item.id !== undefined) ? { display: "none" } : { display: "block"};
    }
  
    _formImageRatio = 1;
    get formImageRatio() {
        return this._formImageRatio;
    }
    set formImageRatio(value) {
        this._formImageRatio = value;
    }
  
    get formImage() {
        return (
            <FormImage ref={this.formImageRef} ratio={this.formImageRatio}
                disabled={this.state.item === undefined || this.state.item.id === undefined}
                disabledMessage={AppContext.r["create-item-first"]}
                viewOnly={!this.enableEdit}
                imageUrl={this.imageUrl}
                onDelete={this.onDeleteImage}
                onImageFileChanged={this.onImageFileChanged} />);
    }
  
    get formFooter () {
      return (
        <Form.Row className="form-footer">
            {/* <div style={{display: (this.state.status === ModuleStates.saving) ? "block" : "none"}}>{this.saving}</div> */}
            {/* {this.saving} */}
            {/* Shown only on editing */}
            <Col style={this.displayOnEditStyle}>
              <div style={this.enableDelete ? {display: "block"} : {display: "none"}}>
                {this.deleteButton}
              </div>
            </Col>
            {/* Shown only on insert */}
            <Col style={this.displayOnInsertStyle}>
                {this.createButton}
            </Col>
        </Form.Row>
      );
    }

    get saving() {
        return (this.state.status === ModuleStatusEnum.saving) ?
            <div className="item-progress-bar">
                <label>{AppContext.r["saving"]} <i className="fas fa-check"></i></label>
                <LinearProgress />
            </div> : "";
    }

    get isInsertMode () {
        return this.props.match.url.startsWith(this.info.path + "/insert");
    }

    async componentDidMount() {
        if(this.beforeComponentDidMount)
            await this.beforeComponentDidMount();
            
        console.log("FETCHING at start", this.props.match)

        const cookie = (this.info.cookieName) ? Cookie.load(this.info.cookieName) : null;
        let page = cookie ? cookie.currentPage : -1;

        if(this.mode === ModuleViewModeEnum.List || this.mode === ModuleViewModeEnum.SingleItem) {
            if (this.props.match) {
                if (this.isInsertMode)
                    this.resetForm();
                else if(this.props.match.params.id) {
                    this.fetchItem(this.props.match.params.id);
                }
                else if(this.mode === ModuleViewModeEnum.List)
                    await this.fetchRecords(page);
            } else {
                await this.fetchRecords(page);
            }
        }

        window.addEventListener("resize", this.onResize);

        this.setSubHeaderContext();
    }

    resetForm () {
        console.log("Reset Form", this.initialValues)
        
        this.setState({
            item: this.initialValues
        });
    }

    fetchItem = (id = null, query = "") => {
        if(!this.itemApiPath)
            return;

        console.log("Fetching item " + this.apiUrl + this.itemApiPath + "/" + id);

        if(this.onFetchingItem)
            this.onFetchingItem();

        let url = this.apiUrl + this.itemApiPath;
        if(id) url += "/" + id + query;

        this._cancelTokenSource = this._cancelToken.source();

        CoreApi.fetchItem(url, (item) => {
            console.log(item);
            
            if(this.onFetchedItem)
                this.onFetchedItem(item);
                    
            this.setSubHeaderContext(item);

            this.setState({
                item: item
            });
        }, this._cancelTokenSource.token)
    }

    filtersQuery = () => {
        let query = "";

        for (var key in this.filters) {
            const value = this.filters[key];

            if(value) {
                if(query.length > 1)
                    query += "&";
                query += key + "=" + value;
            }
        }

        return query;
    }

    async fetchRecords(page = -1) {
        if(this.onBeforeFetchRecords)
            await this.onBeforeFetchRecords();

        if(!this.recordsApiPath)
            return;

        this.setState({
            records: null
        });

        let query = "?page="+ ((page >= 0) ? page : this.state.currentPage);

        if(this.filters) {
            const filtersQuery = this.filtersQuery();
            
            if(filtersQuery.length > 0)
                query += "&" + filtersQuery;
        }

        console.log("fetchRecords " + this.apiUrl + this.recordsApiPath + query);

        Axios.get(this.apiUrl + this.recordsApiPath + query)
            .then(response => {
                // If request is good...
                console.log(response);
                this.setState({
                    records: response.data.data ? response.data.data : response.data,
                    page: response.data.current_page, // TODO: Maybe it's not used
                    currentPage: response.data.current_page,
                    lastPage: response.data.last_page
                });
            })
            .catch((error) => {
                CoreApi.errorLog(error);
                console.log('error ' + error);
            });
    }
        
    create = async (values) => {
        //const values = this.state.item;
        console.log(values);

        this.setState({
            status: ModuleStatusEnum.saving
        });

        var config = { headers: { 'Content-Type': 'application/json' }, crossdomain: true };
        const url = this.apiUrl + this.createApiPath;
        
        delete values["created_at"];
        delete values["updated_at"];
        
        if(this.insertDataAdapter)
            values = this.insertDataAdapter(values);

        console.log("CREATE", values);
        
        const response = await Axios.post(url, values, config)
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response);
                    return error.response;
                }
            });

        this.fetchRecords();

        if(response && response.status === 201 && response.data.data.id) {
            this.setState({
                item: response.data.data
            });
        }

        this.setState({
            status: ModuleStatusEnum.rest
        });

        return response;
    }
    
    async update(values, updateImage = false, updateCover = false) {
        if(values && /*this.state.item !== null &&*/ this.enableEdit) {
            this.setState({
                status: ModuleStatusEnum.saving
            });
            
            delete values["created_at"];
            delete values["updated_at"];

            if(!updateImage)
                delete values["image"];
            if(!updateCover)
                delete values["cover"];

            const id = values.id ? values.id : this.state.item.id;
            const url = this.apiUrl + this.updateApiPath + "/" +  id;
            
            if(this.updateAdapter && !updateImage && !updateCover)
                values = this.updateAdapter(values);

            const response = await CoreApi.updateItem(url, values);
            console.log("UPDATE", values, response);
        
            if(this.state.item && response && response.data && response.status === 202) {
                let context = (response.data.data) ? response.data.data : response.data;
                this.setSubHeaderContext(context);
            }

            if(response && response.status === 202 
                && this.onUpdated)
                this.onUpdated(response.data.data);

            this.setState({
                status: ModuleStatusEnum.rest
            });

            return response.data.data;
        }

        return null;
    }

    delete = async () => {
        if(this.state.item !== null) {
            this.setState({
                status: ModuleStatusEnum.saving
            });

            const url = this.apiUrl + this.deleteApiPath + "/" + this.state.item.id;
            const response = await CoreApi.deleteItem(url);

            if(response.status === 423) {
               // Resource Locked
               this.setState({
                    alert: {
                        open: true,
                        severity: "error",
                        autoHideDuration: 5000,
                        message:"This resource is locked and can't be deleted"// AppContext.r["resource-locked"] 
                    },
                    showModal: false,
                    status: ModuleStatusEnum.rest
                });

                // Clear Alert after 5 secs
                setTimeout(() => this.setState({ alert: null }), 5000);
            } else {
                this.context.setActions(this.insertButton);
                
                if(this.onDeleted)
                    this.onDeleted(response);
                else
                    this.fetchRecords();
    
                const s = {
                    showModal: false,
                    status: ModuleStatusEnum.rest
                };
    
                // Do not close the view if there was an event
                // TODO: It may show a default error
                if(response.status < 300)
                    s["item"] = null;
        
                this.setState(s);
            }
        }
    }

    onChange = (values) => {
        if(this.onBeforeChange)
            this.onBeforeChange(values);

        const { item } = this.state;

        if(!item || !item.id)
            return;

        // Use a data adapter if it has been specified
        if(this.updateDataAdapter)
            values = this.updateDataAdapter(values);

        const t = this;

        // Validation
        if(t._updateTimeout !== null) {
            clearTimeout(t._updateTimeout);
            t._updateTimeout = null;
        }

        t._updateTimeout = setTimeout(function () {
            t.schema.isValid(values).then(function(valid) {

            if(valid) {
                    t.update(values);
                }
            });
        }, t._updateDelayMs);

        return {}; // This is required by Formik
    }

    onClose = () => {
        AppContext.history.push(this.info.path);
    //   console.log("close " + this.info.path, this.CancelTokenSource, this.state.filters);
      if(this.CancelTokenSource)
          this.CancelTokenSource.cancel('Operation canceled by the user.');
    }

    maskTemplate(m, initialValues) {
        // Not accept null values
        for(let key in initialValues) {
            if(initialValues[key] === null)
                initialValues[key] = "";
        }

        if(initialValues === undefined)
            initialValues = this.initialValues;

        return (
            <Formik validate={this.onChange} validationSchema={this.schema}
                        enableReinitialize={true} initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            console.log(values['id'])
                            if(!values['id']) {
                                console.log("SUBMIT", values);
                                this.onCreate(values);
                            }
                    }}>
                {m}
            </Formik>
        );
    }

    overlay() {
        const message = "";

        return (
            <div style={this.state.status === ModuleStatusEnum.uploading || this.state.status === ModuleStatusEnum.overlay ? {display: "block"} : {display: "none"}}>
                <div className="fullscreen-overlay">
                    <div className="centered">
                        <p>{message}</p>

                        {AppContext.r["preloader"]}
                    </div>
                </div>
            </div>
        );
    }

    setModalFree = () => {
        this.setState({
            modalBusy: false,
            modalMessage: null
        });
    }

    onCloseModal = () => {
        this.setState({
            showModal: false,
        });
    }

    setModalTitle = (title) => {
        this.setState({
            modalTitle: title
        });
    }

    setModalFooter = (footer) => {
        this.setState({
            modalFooter: footer
        });
    }

    setModal = (title, view, footer = null) => {
        this.setState({
            modalTitle: title,
            modalView: view,
            modalFooter: footer
        });
    }

    confirm = (title, text, callback) => {
        const confirmModalFooter = (
            <>
                <Button variant="secondary" onClick={this.onCloseModal}>
                    {AppContext.r["no"]}
                </Button>
                <Button variant="success" onClick={() => callback()}>
                    {AppContext.r["yes"]}
                </Button>
            </>
        );

        this.setModal(title, text, confirmModalFooter);

        this.setState({
            showModal: true
        })
    }

    input = (title, text, callback) => {
        const confirmModalFooter = (
            <>
                <Button variant="secondary" onClick={this.onCloseModal}>
                    {AppContext.r["no"]}
                </Button>

                <Button variant="success" onClick={() => callback(this.state.inputField)}>
                    {AppContext.r["yes"]}
                </Button>
            </>
        );

        const form = (<>
            {text}

            <TextField value={this.state.inputField} onChange={(e) =>
                this.setState({ inputField: e.target.value })} />
        </>);

        this.setModal(title, form, confirmModalFooter);

        this.setState({
            showModal: true
        })
    }

    onDelete = () => {
        const deleteModalFooter = (
            <>
                <Button onClick={this.onCloseModal}>
                    {AppContext.r["cancel"]}
                </Button>
                
                <Button variant="contained" className="danger-button" onClick={this.delete}>
                    {AppContext.r["delete"]} <DeleteIcon />
                </Button>
            </>
        );

        this.setModal(AppContext.r["confirm-delete-heading"], AppContext.r["confirm-delete"], deleteModalFooter);

        this.setState({
            showModal: true
        })
    }
        
    onCreate = async (values) => {
        const response = await this.create(values);
        console.log(response);
        
        if(response.status === 201 && response.data.data && response.data.data.id)
            AppContext.history.push(this.info.path + "/" + response.data.data.id, response.data.data);
    }

    onInsert = () => {
        this.enableEdit = true;

        AppContext.history.push(this.info.path + "/insert");
    }

    onChangePage = async (page) => {
        this.setState({
            currentPage: page
        });

        if(this.info.cookieName)
            Cookie.save(this.info.cookieName, { currentPage: page }, { maxAge: 30 * 60 });

        await this.fetchRecords(page);
    }

    onRowClick = (o) => {
        console.log(o);
        if(this.enableView) {
            this.setState({
                item: o
            });

            AppContext.history.push(this.info.path+'/'+o.id, o);
        }

        if(this.onRowClicked)
            this.onRowClicked(o);
    }

    async uploadImageFile(file, attributeName = 'image') {
        this.setState({
            status: ModuleStatusEnum.uploading
        });
  
        const url = this.apiUrl + this.mediaTransferApiPath;
        // console.log(url);
    
        const formData = new FormData();
        formData.append('file', file);
  
        const response = await CoreApi.uploadFile(url, formData);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                //cover: response.data.name,
                image_name: file.name
            };

            item[attributeName] = response.data.name;

            console.log(item);

            await this.update(item, attributeName === 'image', attributeName === 'cover');
        }

        this.setState({
            status: ModuleStatusEnum.rest // TODO: Maybe define an error state
        });
    }

    onImageFileChanged = (file, attributeName = 'image') => {
        console.log(file)
        this.uploadImageFile(file, attributeName);

        const item = { ...this.state.item };
        item[attributeName] = file.name;

        this.setState({
            item: item
        });
    }

    onDeleteImage = (attributeName = 'image') => {
        const values = { };
        values[attributeName] = "-1";

        this.update(values, attributeName === 'image', attributeName === 'cover');

        const item = { ...this.state.item };
        item[attributeName] = null;

        this.setState({
            item: item
        });
        
        this.formImageRef.current.resetImage();
    }

    render() {
        if (this.state.redirectTo) {
            return (
                <Redirect push to={{
                    pathname: this.state.redirectTo,
                    state: this.state.redirectState ? this.state.redirectState : { }
                }} />
            );
        }

        const { item } = this.state;

        if(this.mode !== ModuleViewModeEnum.SingleItem && (item === null && this.state.records === null)
           || this.state.status === ModuleStatusEnum.loading)
            return (
                <div className="module">
                    {AppContext.r["preloader"]}
                </div>);

        const modal = (
            <Modal show={this.state.showModal} onHide={this.onCloseModal}>
                {this.state.modalTitle && 
                    <Modal.Header>
                        <Modal.Title>{this.state.modalTitle}</Modal.Title>
                    </Modal.Header> }

                <Modal.Body>
                    {this.state.modalView}
                </Modal.Body>

                {(this.state.modalBusy || this.state.modalMessage != null) && (
                    <div className="modal-overlay">
                        {this.state.modalBusy && AppContext.r["preloader"]}
                        <div className="centered">
                            {this.state.modalMessage}
                        </div>
                    </div>
                )}

                {this.state.modalFooter && 
                    <Modal.Footer>
                        {this.state.modalFooter}
                    </Modal.Footer> }
            </Modal>
        );

        if(item) {
            return (
                <div className={"module item-container" + (this.fullWidth ? " full-width" : "")} ref={this.itemContainerRef}>
                    {/* this.mode === ModuleViewModeEnum.List && item &&
                        <div className="module-header">
                            {<h1>{this.PageInfo.itemTitle}</h1>}
                            {this.closeButton}
                        </div> */
                    }

                    { this.maskTemplate(this.mask, item) }
                    { this.saving }
        
                    { modal }
                    <GlobalHotKeys keyMap={keyMap} handlers={this.handlers}/>

                    {this.state.alert && 
                        <Alert a={this.state.alert}/> }
                </div>
                );
        }
console.log(this.state.records, this.state.lastPage)
        if(this.state.records) {
            // Pagination
            const items = [];

            if(this.showPagination && this.state.lastPage > 1) {
                for (let number = 1; number <= this.state.lastPage; number++) {
                    items.push(
                        <Pagination.Item key={number} active={number === this.state.currentPage}
                            onClick={() => this.onChangePage(number)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }
            
            return (
                <div className="module items-container">
                    <div className="module-header">
                        {/* {this.enableInsert && this.insertButton} */}
                        { this.filtersForm && this.filtersForm() }
                    </div>

                    {this.enableDownloadCsv && this.downloadCsv()}

                    <JsonToTable ref={this.jsonTableRef} onRowClick={this.onRowClick}
                        onRowClassName={this.onRowClassName ? this.onRowClassName : null}
                        head={this.tableHead} body={this.state.records}
                        sortable={this.sortable} onReorder={this.onReorder} />
                         {/* filter={this.state.filters} */}
                    
                    { items.length > 0 ? <Pagination size="sm">{items}</Pagination> : "" }

                    { modal }

                    { this.overlay() }

                    <GlobalHotKeys keyMap={keyMap} handlers={this.handlers}/>

                    {this.state.alert && 
                        <Alert a={this.state.alert}/> }
                </div>);
        }
    }

    downloadCsv = () => {
        const { records } = this.state;
        const filename = this.title + "-report.csv";
        const data = [];

        if(!records)
            return;

        for(let r of records) {
            let v = {}
            
            for(const headField of this.tableHead) {
                if(headField["Adapter"]) {
                    const adaptedValue = headField["Adapter"](r, null);

                    if(typeof(adaptedValue) !== "object" && adaptedValue !== React.isValidElement(adaptedValue))
                        v[headField.Title] = adaptedValue;
                } else {
                    v[headField.Title] = r[headField.Field];
                }
            }

            data.push(v);
        }

        return (
            <CSVLink data={data} filename={filename}>
                <i className="fas fa-file-csv"></i> Download {filename}
            </CSVLink>);
    }
}