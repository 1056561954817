// CopyLink v1.0

import React from "react";
import { Button } from '@material-ui/core';
import { TextField } from "@material-ui/core";

import AppContext from "app/AppContext";

export default class CopyLink extends React.Component {
    state = {
        copied: null
    };

    url = this.props.url ? this.props.url : window.location.href;

    copyLink = () => {
        if(this.t)
            clearTimeout(this.t);

        const el = document.querySelector("input#urlToCopy");
        el.select();
        document.execCommand('copy');

        this.setState({
            copied: true
        });
        
        this.t = setTimeout(() => this.setState({ copied: false }), 10000);
    }

    render() {
        return (
            <div className="copy-link">
                <TextField id="urlToCopy"
                    label="URL"
                    defaultValue={this.url}
                    InputProps={{
                        readOnly: true,
                    }} />

                <Button onClick={this.copyLink}>
                    {!this.state.copied ? AppContext.r["copy-link"] : (AppContext.r["copied"] + "!")}
                </Button>
            </div>);
    }
}