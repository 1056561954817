import React from 'react';
import { Form, Col } from 'react-bootstrap';

import AppContext from 'app/AppContext';
import RemoteSelect from '../../../framework/RemoteSelect';
import {withModal} from 'framework/withModal';
import QuickModule from 'framework/QuickModule';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);

class _Contacts extends React.Component {
    state = { };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["contact-name"],
                "Field": "alias",
            },{
                "Title": AppContext.r["city"],
                "Adapter": (o) => {
                    return o.city;
                }
            }
        ];
    }

    get schema () {
        return yup.object({
            alias: yup.string().required(),
            city: yup.string().required(),
            email: yup.string().email().nullable(),
            phone: yup.string().phone().nullable(),
        });
    }

    view = ({ handleChange, values, touched, errors }) => (
        <>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["contact-name"]}</Form.Label>
                    <Form.Control type="text" placeholder={"Just a friendly name"} name="alias" autoFocus
                                    value={values.alias} onChange={handleChange}
                                    isValid={touched.alias && !errors.alias} isInvalid={!!errors.alias} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["address"]} 1</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["address"]} name="address1"
                                    value={values.address1} onChange={handleChange}
                                    isValid={touched.address1 && !errors.address1} isInvalid={!!errors.address1} />
                </Form.Group>

                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["address"]} 2</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["address"]} name="address2"
                                    value={values.address2} onChange={handleChange} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md="2">
                    <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["post-code"]} name="post_code"
                                    value={values.post_code} onChange={handleChange} />
                </Form.Group>

                <Form.Group as={Col} md="5">
                    <Form.Label>{AppContext.r["city"]} *</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["city"]} name="city"
                                    value={values.city} onChange={handleChange}
                                    isValid={touched.city && !errors.city} isInvalid={!!errors.city} />
                </Form.Group>

                <Form.Group as={Col} md="5">
                    <Form.Label>{AppContext.r["county"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["county"]} name="county"
                                    value={values.county} onChange={handleChange}/>
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["country"]}</Form.Label>

                    <RemoteSelect name="country_id" key={values.country_id} value={values.country_id} onChange={handleChange}
                                options={this.props.countryOptions} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["email"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                value={values.email} onChange={handleChange}
                                isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                </Form.Group>
                
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["phone"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                    value={values.phone} onChange={handleChange}
                                    isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                </Form.Group>
            </Form.Row>
        </>);

    render() {
        return <QuickModule initialValues={{"alias": "Contact", "phone": null, "country_id": 228}}
            className={this.props.className}
            apiPath={'/'+this.props.contactableType+'/'+ this.props.contactableId + '/contacts'}
            tableHead={this.tableHead} schema={this.schema} view={this.view} />;
    }
}

export default withModal(_Contacts);