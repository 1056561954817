import React from 'react';
import * as yup from "yup";

import { ContentRoute } from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";

export default class Expense extends Module {
    enableInsert = false;
    enableDelete = false;
    onRowClick = null;

    info = Expense.Info;
    
    static Info = {
        nameResource: "expenses",
        icon: "fa-solid fa-angles-up money-out-icon",
        path: "/expenses",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Expense match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["expense"];
    }

    get apiPath() {
        return '/expenses';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required(),
            description: yup.string(),
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Field": "title"
            }
        ];
    }
}