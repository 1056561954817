export default class ProjectIdsEnum {
    static get Uderly () { return 0; }
    static get UderlyPro () { return 0.1; } // MenuPro - Legacy
    static get Quartarone () { return 1; }
    static get QrtService () { return 2; }
    static get IqSpace () { return 3; }
    static get WoolSafe () { return 4; }
    static get Stay () { return 5; }
    static get Vaffa () { return 6; }
    static get Rafala () { return 7; }
}