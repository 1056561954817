import ProjectIdsEnum from '../settings/ProjectIdsEnum';

export default function loadClientSettings(settings) {
    settings["project-id"] = ProjectIdsEnum.QrtService;
    settings["language"] = "it";
    settings["website"] = "api.qrtservice.uderly.it";
    settings["project-name"] = "QRT Service";
    settings["logo-dark"] = "/assets/images/quartarone/logo-light.png";
    settings["logo-light"] = "/assets/images/quartarone/logo-light.png";
    settings["mini-logo-dark"] = "/assets/images/quartarone/logo-light.png";
    settings["mini-logo-light"] = "/assets/images/quartarone/logo-light.png";

    // settings["client-id"] = "2";
    // settings["client-secret"] = "znnQhFu5gMr3o9exTrX0KRhx5sBhanfALpNGCaZO";
    settings["api-url"] = "https://api.qrtservice.uderly.it/api/v1";
}
