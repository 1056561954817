import React from 'react';

export default function loadClientResources (resources) {
    resources["active"] = "Active";
    resources["pricing"] = "Pricing";
    resources["amount"] = "Amount";
    resources["you-earn"] = "You earn";
    resources["ticket-price"] = "Ticket Price";
    resources["net-income"] = "Net Income";
    resources["transaction-fee"] = "Transaction Fee";
    resources["tickets"] = "Tickets";
    resources["free-tickets-desc"] = <>
        <p>Wanna give a try? Just use these <b>welcome vouchers</b> to get free trial tickets.</p>
        <small><p>Hint: you could award your top players.</p></small></>;
    resources["ticket-min-price"] = "Minimum price {currency} 0.50";
    resources["attendances"] = "Attendances";
    resources["purchased-on"] = "Purchased on";
    resources["fullname"] = "Full Name";
    resources["username"] = "Username";
    resources["voucher"] = "Voucher";
    resources["attendance_status"] = "Status";
    resources["attendance_statuses"] = {
        1: "Interested",
        2: "Acquired",
        3: "Payment Intent",
        4: "Refunded",
    };
    resources["no-attendances"] = "There are not confirmed attendances for this event yet.";
    resources["cant-delete-event"] = <>
        <p>You can't delete this event, because there are other resources associated, such as a game or purchased tickets.</p>
        <p>You can unpublish this event if you don't want to keep it available for your audience.</p>
    </>;
    resources["refund"] = "Refund";
    resources["refund-ticket"] = "Refund Ticket";
    resources["refund-confirm"] = "Would you like to refund {user}?";

    resources["keep-up-to-date"] = "Keep your profile's public information up-to-date";
    resources["upcoming-events"] = "Your upcoming events at a glance";
    resources["no-upcoming-events"] = "No upcoming events";
        
    resources["event-page"] = "Event Page";
    resources["events"] = "Events";
    resources["event"] = "Event";
    resources["title"] = "Title";
    resources["youtube-url"] = "YouTube Url";
    resources["facebook-url"] = "Facebook Url";
    resources["instagram-url"] = "Instagram Url";
    resources["twitter-url"] = "Twitter Url";
    resources["event-link"] = "Event Link";
    resources["event-planned"] = "Event planned to start in";
    
    resources["actions"] = "Actions";
    resources["follower"] = "Follower";
    resources["followers"] = "Followers";
    resources["gamename"] = "Game Name";
    resources["packages"] = "Question Albums";
    resources["package"] = "Questions Album";
    resources["playlists"] = "Playlists";
    resources["playlist"] = "Playlist";
    resources["clear-playlist"] = "Clear Playlist";
    resources["add-to"] = "Add To";
    resources["add-to-new-playlist"] = "Add To New Playlist";
    resources["new-playlist"] = "New Playlist";
    resources["add-to-playlist"] = "Add To Playlist";
    resources["add-all-to-playlist"] = "Add All To Playlist";
    resources["remove-from-playlist"] = "Remove From Playlist";
    resources["remove-question"] = "Remove Question";
    resources["remove-question-confirm"] = "Are you sure you want to remove the selected question from the playlist?";
    resources["drag-and-drop"] = "Drag and drop the items to reorder the list";
    resources["quiz"] = "Quiz";

    resources["games"] = "Games";
    resources["game"] = "Game";
    resources["event-id"] = "Event Id";
    resources["zoom-meeting-id"] = "Meeting ID";
    resources["zoom-meeting-password"] = "Meeting Password";
    resources["zoom-meeting-desc"] = "Embed Zoom \"in-app\". It works only if you have a paid Zoom version.";
    resources["started"] = "Started";
    resources["stopped"] = "Stopped";
    resources["duration"] = "Duration";
    resources["max-players-count"] = "Max Players Number";
    resources["paused"] = "Paused";
    resources["status"] = "Status";

    resources["categories"] = "Categories";
    resources["category"] = "Category";
    resources["color"] = "Color";
    
    resources["questions-count"] = "Questions Count";
    resources["questions"] = "Questions";
    resources["question"] = "Question";
    resources["text"] = "Text";
    resources["note"] = "Note";
    resources["answers"] = "Answers";
    resources["answer"] = "Answer";
    resources["image-duration"] = "Image Duration";
    resources["collection-not-editable"] = "This collection is not editable.";
    
    resources["start-when"] = "Start when you are ready";
    resources["events-today"] = "Events today";
    resources["no-events-today"] = "No events today.";
    resources["start-new-game"] = "Start new game";
    resources["start-game"] = "Start game";
    resources["plan-an-event"] = "schedule an event";

    resources["this-is-a-free-event"] = "This is a free event.";
    resources["this-is-a-paid-event"] = "This is a paid event.";
    resources["licence"] = "Licence";
    resources["free-not-possible"] = <>
        <p>It is not possible anymore to set this event as free because you have already sold tickets or started the game.</p>
        <p>If you wish to host a free game you could refund your players and create a new event.</p>
    </>;

    resources["video-url"] = "Video URL";
    resources["video-support"] = "Support YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion.";
    resources["video-url-disabled-desc"] = <p><small>The video can't be shown if an image has been selected.</small><br/><small>Either choose to show an image or a video.</small></p>;
    resources["fixed-score"] = "Fixed Score";
    resources["fixed-score-default"] = <small>Use default algorithm</small>;
    resources["fixed-score-desc"] = <small>Choose whether using a fixed score or the default progressive algorithm.</small>;

    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connecting...";
    resources["rts-connected"] = "Connected. I'm subscribing to the server...";
    resources["rts-disconnected"] = "Disconnected. :/ Attempting reconnection...";
    resources["rts-couldnt-connect"] = "The game is not available right now due to a connection issue to the server. Please make sure you are connected to the internet and try refreshing the page in a bit.";
    resources["rts-subscription-failed"] = "Invocation of subscribeViewer failed. Error: ";
    
    resources["cancel-by"] = "Cancel by";
    resources["partial-results"] = <span>Partial<br/>Results</span>;
    resources["invite-players"] = <span>Invite<br/>Players</span>;
    resources["cancel-game"] = "Cancel Game";
    resources["cancel-game-confirm"] = (
        <div>
            <p>Would you like to cancel this game?</p>
            <p>All the information related to this game, including players scores, will be <strong>destroyed</strong> and it won't be recoverable.</p>
            <p>Your connection to the game will be lost hence you will need to refresh the game page(s).</p>
        </div>);
    resources["last-answer"] = "Last Answer";
    resources["last-time"] = "Last Time";
    resources["last-score"] = "Last Score";
    resources["score"] = "Score";
    resources["delete-player"] = "Delete Player";
    resources["delete-player-confirm"] = (
        <div>
            <p>Would you like to delete this player?</p>
            <p>Their score will be <strong>erased</strong> and won't be recoverable.</p>
            <p>The player will be kicked out of the game and will need to join it again to play.</p>
        </div>);
    resources["reset-scores"] = "Reset Scores";
    resources["reset-scores-confirm"] = (
        <div>
            <p>Would you like to <b>permanently</b> reset all players' scores?</p>
        </div>);
    resources["wave"] = "Wave";
    resources["players"] = "Players";
    resources["viewers"] = "Viewers";
 
    resources["pause-tooltip"] = "Intermission";
    resources["stop-tooltip"] = "Stop Game";
    resources["play-tooltip"] = "Start Game";
    resources["forward-tooltip"] = "Forward";
    resources["skip-tooltip"] = "Skip Forward";
    resources["show-invite-players-tooltip"] = "Show Invite Players";
    resources["hide-invite-players-tooltip"] = "Hide Invite Players";
    resources["show-teams-tooltip"] = "Show Players Board";
    resources["hide-teams-tooltip"] = "Hide Players Board";
    resources["show-leaderboard-tooltip"] = "Show Leaderboard";
    resources["hide-leaderboard-tooltip"] = "Hide Leaderboard";
    resources["show-final-leaderboard-tooltip"] = "Show Final Leaderboard";
    resources["hide-final-leaderboard-tooltip"] = "Hide Final Leaderboard";
    resources["enable-partial-leaderboard-tooltip"] = "Enable Partial Leaderboard";
    resources["disable-partial-leaderboard-tooltip"] = "Disable Partial Leaderboard";
    resources["partial-leaderboard-on-off"] = <span>Partial<br/>L.board On/Off</span>;
    resources["play-question-tooltip"] = "Play this Question";
    resources["ticket-id"] = "Ticket Id";
    resources["page-at"] = "Your page is online at";
    resources["wish-change-subdomain"] = "If you wish to change it, please get in touch with us.";
    resources["game-language"] = "Game Language";
    resources["notifications"] = "Notifications";
    resources["notifications-desc"] = "Send a notification to all the event's viewers.";
    resources["message"] = "Message";
    resources["only-premium-feature"] = "This feature is available only for premium accounts.";
    resources["info"] = "Info";
    resources["success"] = "Success";
    resources["warning"] = "Warning";
    resources["error"] = "Error";
    resources["your-store"] = "Your Organisation";
    resources["your-company-logo"] = "Your company's logo";
    resources["edit-store-information"] = "Edit your organisation";
    resources["game-link"] = "Game Link";
    resources["play"] = "Play";
    resources["play-desc"] = "Direct link to access the game.";
    resources["project"] = "Project";
    resources["project-desc"] = "Link to project to maxi-screen(s).";
    resources["all-questions-added"] = "Questions added to playlist";
}