// CopyButton v1.0

import React from "react";
import { Button } from '@material-ui/core';

import AppContext from "app/AppContext";

export default class CopyButton extends React.Component {
    state = {
        copied: null
    };

    copyValue = (e) => {
        e.stopPropagation();
        
        if(this.t)
            clearTimeout(this.t);

        if(this.props.id) {
            const el = document.querySelector("#" + this.props.id);

            if(el) {
                el.select();
                document.execCommand('copy');
        
                this.setState({
                    copied: true
                });
    
                this.t = setTimeout(() => this.setState({ copied: false }), 10000);
            }
        }
    }

    render() {
        return (
            <Button onClick={this.copyValue}>
                {!this.state.copied ? AppContext.r["copy"] : (AppContext.r["copied"] + "!")}
            </Button>);
    }
}