import React from 'react';

export default function loadClientResources (resources) {
    resources["shop-not-active"] = "Il tuo locale non è ancora stato attivato.";
    resources["shop-disabled"] = "Il tuo locale è stato disabilitato.";
    resources["no-role"] = "Non hai nessun servizio associato a questo account.";
    resources["active"] = "Attivo";
    resources["shop"] = "Locale";
    resources["shops"] = "Locali";
    resources["shop-item"] = "Prodotto Menu";
    resources["shop-items"] = "Menu";
    resources["menu-categories"] = "Categorie Menu";
    resources["ingredients"] = "Ingredienti";
    resources["note"] = "Note";
    resources["status"] = "Stato";
    resources["order-types-enum"] = [];
    resources["order-types-enum"][1] = "Comanda";
    resources["order-types-enum"][2] = "Delivery";
    resources["order-types-enum"][3] = "Take Away";
    resources["your-restaurant"] = "Il tuo ristorante";
    resources["your-shop"] = "Il tuo locale";
    resources["access-url-desc"] = "La tua pagina è live su";
    resources["qr-desc"] = <p>Per facilitare l'accesso alla tua pagina, puoi inoltre distribuire il seguente <i>codice QR</i>.</p>;
    resources["support-big-screen"] = <div>La tua licenza permette di visualizzare il gioco su <b>grandi schermi</b> tramite il seguente URL:</div>;
    resources["subdomain"] = "Sottodominio";
    resources["domain"] = "Dominio";
    resources["color"] = "Colore";
    resources["allowed-extras"] = "Supplementi Consentiti";
    resources["no-extras"] = "Non ci sono supplementi per questa categoria";
    resources["insert-extra"] = "Inserisci Supplemento";
    resources["vegan"] = "Vegano";
    resources["vegetarian"] = "Vegetariano";
    resources["gluten-free"] = "Gluten Free";
    resources["first-access-datetime"] = "Data & Ora Primo Accesso";
    resources["first-access-country"] = "Nazione Primo Accesso";
    resources["tables-count"] = "Numero Tavoli";
    resources["big-screen"] = <span>Grande Schermo <i className="fas fa-tv"></i></span>;
    resources["suggest-checkin"] = <span>Pop-up Check-in <i className="fas fa-shield-virus"></i></span>;
    resources["users-check-ins"] = "Accessi Utente";
    resources["users-check-ins-last-month"] = "Accessi Utente Ultimi 30 Giorni";
    resources["users-check-ins-count"] = "# di accessi";
    resources["generate"] = "Genera";
    resources["days"] = "giorni";
    resources["report-30-days"] = "Report 30 giorni";
    resources["report-90-days"] = "Report 90 giorni";
    resources["ready-for-download"] = "Pronto per il download";
    resources["order_types"] = {
        1: "Effettuato",
        2: "In Preparazione",
        3: "Pronto per la consegna",
        4: "Cancellato dal Cliente",
        5: "Cancellato dal Ristoratore",
    }
    resources["enable-takeaway"] = "Abilita Takeaway";
    resources["service"] = "Servizio";
    resources["services"] = "Servizi";
    resources["stripe-product"] = "Prodotto Stripe";
    resources["stripe-products"] = "Prodotti Stripe";
    resources["stripe-product-id"] = "Id Prodotto";
    resources["view-in-stripe"] = "Gestisci su Stripe";
}