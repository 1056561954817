import Axios from 'axios';

import AppContext from '../app/AppContext';

export default class Core {
    static errorLog(error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        return (error.response) ? error.response : null;
    }
    
    static async fetchAsync (url, fieldToMap = null, getResponse = false) {
        const res = await Axios.get(url)
            .catch(function (error) {
                console.log('error ' + error);
                Core.errorLog(error);
            });

        const data = (res.data && res.data.data && !getResponse) ? res.data.data : res.data;

        return this.mapData(data, fieldToMap);
    };

    static mapData(data, fieldToMap) {
        if(fieldToMap && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }
        return data;
    }

    // static mapData2(data, fieldToMap) {
    //     if(fieldToMap && fieldToMap.length > 0) {
    //         const mappedData = [];
    //         for (const x of data) {
    //             mappedData[x[fieldToMap]] = x;
    //         }
    //         return mappedData;
    //     }
    //     return data;
    // }
    
    static async fetchItem(url, callback, cancelToken = null) {
        const config = {
            // cancelToken: this.CancelTokenSource.token,
            //headers: { Authorization: AppContext.Auth.bearer }
        };

        if(cancelToken)
            config.cancelToken = cancelToken;

        Axios.get(url, config)
            .then(response => {
                if(callback)
                    callback(response.data.data);
            })
            .catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    // console.log('error ' + error);
                    this.errorLog(error);
                }
            });
    }

    static async createItem(url, values) {
        // console.log("POST", url, values);

        return await Axios.post(url, values)
            .catch(function (error) {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }

    static async updateItem(url, values) {
        console.log("UPDATE", url, values);

        return await Axios.put(url, values)
            .catch(function (error) {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }

    static async deleteItem(url) {
        console.log("DELETE", url);

        return await Axios.delete(url)
            .catch(function (error) {
                console.log('error ' + error);
                Core.errorLog(error);

                return error.response;
            });
    }

    static async uploadFile(url, formData) {
        const config = {
            headers: {
            // 'Accept': '*/*',
            //'Content-Length': file.size,
            'Content-Type': "multipart/form-data"
            }
        };
            
        return await Axios.post(url, formData, config)
            .catch((error) => {
                console.log(error);
                Core.errorLog(error);

                return error.response;
            });
    }

    static async uploadImageFile(file, mediaTransferApiPath, attributeName = 'image') {
        console.log(file);
        const config = {
            headers: {
                'Accept': '*/*',
                //'Content-Length': file.size,
                'Content-Type': "multipart/form-data"
            }
        }

        const formData = new FormData();
        formData.append('file', file);

        return await Axios.post(mediaTransferApiPath, formData, config)
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                // TODO: Show error
                return error.response;
            })
    }

    static async AddMedia(path, modelId, mediaList, collection = "asset") {
        const url = AppContext.s["net-api-url"] + path + "/" + modelId + "/media/add";

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const data = {
            media: mediaList, 
            collection: collection
        };

        return await Axios.post(url, data, config)
            .catch((error) => {
                console.log(error);
                Core.errorLog(error);
                return error.response;
            });
    }

    static async RemoveMedia(path, mediaId) {
        const url = AppContext.s["net-api-url"] + "/" + path + "/media/remove/" + mediaId;

        return await Axios.delete(url)
            .catch((error) => {
                console.log(error);
                Core.errorLog(error);
                return error.response;
            });
    }

    static async DownloadMedia(basePath, media) {
        console.log(media);
        const url = basePath + "/media/"+ media.id + "/download";
        console.log(url)
        return Axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', media.file_name);
            document.body.appendChild(link);
            link.click();
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });

        // fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
        //     method: 'GET',
        //     headers: {
        //      'Content-Type': 'application/pdf',
        //     },
        // })
        // .then((response) => response.blob())
        // .then((blob) => {
        //     // Create blob link to download
        //     const url = window.URL.createObjectURL(
        //     new Blob([blob]),
        //     );
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute(
        //     'download',
        //     `FileName.pdf`,
        //     );

        //     // Append to html link element page
        //     document.body.appendChild(link);

        //     // Start download
        //     link.click();

        //     // Clean up and remove the link
        //     link.parentNode.removeChild(link);
        // });
    }
    
    static async Permissions() {
        return await Axios.get(AppContext.s["api-url"] + "/permissions")
            .catch((error) => {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }
    
    static async SocialTypes() {
        return await Axios.get(AppContext.s["api-url"] + "/socials/types")
            .catch((error) => {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }

    static async StreamTypes() {
        return await Axios.get(AppContext.s["api-url"] + "/streams/types")
            .catch((error) => {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }
    
    static async AssetTypes() {
        return await Axios.get(AppContext.s["api-url"] + "/assettypes")
            .catch((error) => {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }
    
    static async Localization() {
        return await Axios.get(AppContext.s["api-url"] + "/localization")
            .catch((error) => {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }
    
    static async Touches() {
        return await Axios.get(AppContext.s["api-url"] + "/touches?count=10")
            .catch((error) => {
                console.log('error ' + error);
                Core.errorLog(error);
            });
    }
}