import React from 'react';
import { Form, Col } from 'react-bootstrap';
import {connect} from "react-redux";

import AppContext from 'app/AppContext';
import AuthApi from 'api/Auth';
import CoreApi from 'api/Core';
import RemoteSelect from '../../../framework/RemoteSelect';
import {withModal} from 'framework/withModal';
import QuickModule from 'framework/QuickModule';
import QuickImage from 'framework/QuickImage';
import { FilePond, File, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

let yup = require('yup');

class _Assets extends React.Component {
    state = { 
        assetTypes: null,
        files: [],
        showQuickModule: true
    };

    // quickModuleRef = React.createRef();

    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Field": "name",
            },{
                "Title": AppContext.r["asset-type"],
                "Field": "asset_type_id",
                "Adapter": (o) => {
                    const field = this.assetTypesOptions[o.asset_type_id];
                    return (field) ? field.type : "";
                }
            },{
                "Adapter": (o) => {
                    return (
                        <div className="row-thumb">
                            {o.image && <img src={AppContext.s["api-url"] + "/assets/" + o.id + "/image/thumb"} alt="Thumb" /> }
                        </div>
                    );
                }
            }
            // ,{
            // "Title": "",
            // "Field": "published",
            // "Adapter": function(o) {
            //     return (o.is_public === 1) ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>;
            // }
            // }
        ];
    }

    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    async componentDidMount() {
        // Fetch Asset Types
        const response = await CoreApi.AssetTypes();
        
        if(response && response.data) {
            this.setState({
                assetTypes: response.data.data
            });

            this.assetTypesOptions = CoreApi.mapData(response.data.data, "id");
        }
    }

    view = ({ handleChange, values, touched, errors, setFieldValue }) => (
        <>
            <Form.Row>
                <Col md="4">
                    <QuickImage item={values} 
                        apiUrl={AppContext.s["api-url"] + "/assets/"}
                        onChanged={() => this.setState({ footerBusy: true })}
                        onUploaded={async (item, attributeName) => {
                            setFieldValue(attributeName, item.image);
                            setFieldValue("image_name", item.image_name);
                            this.setState({ footerBusy: false });
                        }}
                        onDelete={async (item, attributeName) => {
                            // console.log("DELETE " + attributeName, item)
                            setFieldValue(attributeName, item.image); // Set to -1
                            setFieldValue("image_name", item.image_name); // Set to empty
                        }} />
                </Col>

                <Col md="8">
                    <Form.Group as={Col} md="8">
                        <Form.Label>{AppContext.r["name"]}*</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["name"]} name="name" autoFocus
                                        value={values.name} onChange={handleChange}
                                        isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                    </Form.Group>

                    <Form.Group as={Col} md="4" xs="6">
                        <Form.Label>{AppContext.r["asset-type"]}</Form.Label>

                        <RemoteSelect name="asset_type_id" key={values.asset_type_id} value={values.asset_type_id} onChange={handleChange}
                                    options={this.assetTypesOptions} fieldToMap="type" />
                    </Form.Group>
                </Col>
            </Form.Row>

            {/* Shown only on editing */}
            {(values.id) &&
                <>
                    <Form.Row>
                        <h3>File</h3>
                        
                        <FilePond
                            allowMultiple={false}
                            onupdatefiles={(fileItems) => { console.log(fileItems); }}
                            files={values.media.map(f => {
                                return {
                                    source: f.file_name,
                                    options: {
                                        type: "local"
                                    }
                                };
                            })}
                            maxFiles={1}
                            name="media" 
                            labelIdle='Associate a file to the asset. Drag & Drop a file or <span class="filepond--label-action">Browse</span>'
                            server={{
                                process: {
                                    url: AppContext.s["api-url"]+'/assets/'+values.id+'/media/add',
                                    headers: {
                                        Authorization: "Bearer " + this.props.authToken,
                                    },
                                    ondata: (formData) => {
                                        //formData.append('asset_type_id', 1);
                                        return formData;
                                    },
                                    //onload: this.onUploadCompleted,
                                    onerror: (error) => {
                                        console.log(error);
                                    },
                                }
                            }} />
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                            <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                            value={values.id} />
                        </Form.Group>
                    </Form.Row>
                </>}
        </>);

    onCreateFromFiles = (fileItems) => {
        console.log(fileItems);
    
        // Set currently active file objects to this.state
        this.setState({
            files: fileItems.map(fileItem => fileItem.file)
        });

        if(fileItems.length > 0)
            this.setState({
                showQuickModule: false
            });
    }

    onUploadCompleted = async () => {
        console.log("onUploadCompleted");

        this.setState({
            showQuickModule: true,
            files: []
        });
    }

    render() {
        const { assetTypes } = this.state;
        const { storeId } = this.props;

        if(!assetTypes)
            return AppContext.r["preloader"];
        
        return (
            <div className="quick-assets">
                {/*data-allow-reorder="true"
                    data-max-file-size="3MB" 
                    server={AppContext.s["api-url"]+'/assets?store_id='+storeId} */}

                <h3>Quick Creation</h3>

                <FilePond
                    files={this.state.files}
                    onupdatefiles={this.onCreateFromFiles}
                    allowMultiple={false}
                    maxFiles={1}
                    name="media" 
                    labelIdle='Create a new asset from file. Drag & Drop a file or <span class="filepond--label-action">Browse</span>'
                    server={{
                        process: {
                            url: AppContext.s["api-url"]+'/assets?store_id='+storeId,
                            headers: {
                                Authorization: "Bearer " + this.props.authToken,
                            },
                            ondata: (formData) => {
                                formData.append('asset_type_id', 1);
                                return formData;
                            },
                            onload: this.onUploadCompleted,
                            onerror: (error) => {
                                console.log(error);
                            },
                        }
                    }} />
                        
                {this.state.showQuickModule && 
                    <>
                        <h3>Store Assets</h3>
                        
                        <QuickModule 
                            disableInsert
                            ref={this.quickModuleRef}
                            initialValues={{"name": ""}}
                            className={this.props.className}
                            apiPath={'/assets?store_id='+storeId} 
                            createApiPath={'/assets?store_id='+storeId}
                            deleteApiPath={'/assets'}
                            updateApiPath={'/assets'}
                            tableHead={this.tableHead} schema={this.schema} view={this.view} />
                    </> }
            </div>
        );
    }
}

export default connect(
  ({ auth }) => ({ authToken: auth.authToken }),
  null
)(withModal(_Assets));
