import React from 'react';

export default function loadClientResources (resources) {
    resources["copy"] = "Copia";
    resources["copy-link"] = "Copia Link";
    resources["copied"] = "Copiato";
    resources["click-to-view-qr"] = "Visualizza il codice QR";
    resources["image-instructions"] = <>
        <p>Trascina qui un'immagine</p>
        <p><small>Formati supportati .gif, .jpeg, .png</small></p>
    </>;
}