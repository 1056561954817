import React from 'react';
import { format } from 'date-fns';
import { Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import { ContentRoute } from "../../../_metronic/layout";

import Module from '../../modules/Module';
import AppContext from '../../AppContext';
import ProjectIdsEnum from '../../settings/ProjectIdsEnum';

export default class Touch extends Module {
    enableInsert = false;
    showPagination = true;
    enableDownloadCsv = false; // TODO: It'd be nice having it
    
    info = Touch.Info;

    static Info = {
        "nameResource": "contacts",
        "icon": "fa fa-tasks",
        "path": "/contacts",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Touch match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["contact"];
    }

    get apiPath() {
        return '/touches';
    }

    static get head () { 
        const h0 = [ 
            {
                "Title": AppContext.r["name"],
                "Adapter": function(o) {
                    const jsx = (
                        <>
                            {o.title && (o.title + " ")}
                            <b>{o.name && (o.name + " ")}</b>
                            <b>{o.surname && o.surname}</b>
                        </>
                    );

                    return jsx;
                }
            }
        ];

        // Uderly's Fields
        const h1 = [
            {
                "Title": AppContext.r["company"],
                "Field":  "company_name"
            },
            {
                "Title": AppContext.r["phone"],
                "Adapter":  (o) => o.phone ? 
                    <a onClick={(e) => e.stopPropagation() } href={"tel:" + o.phone}>{o.phone}</a> : ""
            }
        ];
        
        // Vaffa's Fields
        const h2 = [
            {
                "Title": AppContext.r["career"],
                "Adapter": (o) => Touch.careerById(o.career_id)
            }
        ];

        const h3 = [
            {
                "Title": AppContext.r["email"],
                "Adapter": (o) => o.email ? 
                    <a onClick={(e) => e.stopPropagation() } href={"mailto:" + o.email}>{o.email}</a> : ""
            },
            {
                "Title": AppContext.r["date-time"],
                "Adapter": (o) => {
                    if(!o.created_at) return "";

                    const date = new Date(o.created_at.substr(0, 19));
                    return format(date, "d/MM/yyyy H:mm");
                }
            },
            {
                Adapter: (o) => {
                    const icons = [];

                    if(o.note && o.note.length > 0)
                        icons.push(<><i className="far fa-clipboard"></i>&nbsp;&nbsp;</>);

                    if(o.media && o.media.length > 0)
                        icons.push(<i className="fas fa-file-signature"></i>);

                    if(o.allow_newsletter > 0)
                        icons.push(<i className="fas fa-heart"></i>);

                    return icons;
                }
            }
        ];

        if(AppContext.s["project-id"] === ProjectIdsEnum.Vaffa)
            return h0.concat(h2, h3);
        else
            return h0.concat(h1, h3);
    };
    
    get tableHead () {
        return Touch.head;
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get initialValues() {
        return {
            name: ""
        };
    }

    static careerById = (id) => {
        if(id > 0) return AppContext.r["careers"][id];

        return "";
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                {(AppContext.s["project-id"] === ProjectIdsEnum.Vaffa) &&
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["name"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["name"]} name="name" autoFocus disabled
                                value={values.name} onChange={handleChange}
                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["surname"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["surname"]} name="surname" disabled
                                value={values.surname} onChange={handleChange}
                                isValid={touched.surname && !errors.surname} isInvalid={!!errors.surname} />
                        </Form.Group>
                    </Form.Row> }

                {(AppContext.s["project-id"] === ProjectIdsEnum.Uderly) &&
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["name"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["name"]} name="full_name" autoFocus disabled
                                value={values.full_name} onChange={handleChange}
                                isValid={touched.full_name && !errors.full_name} isInvalid={!!errors.full_name} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["company"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["company"]} disabled
                                value={values.title + (values.company_name ? (" @ " + values.company_name) : "")} onChange={handleChange}/>
                        </Form.Group>
                    </Form.Row> }

                <Form.Row>
                    <Form.Group as={Col} md="6">
                        {(AppContext.s["project-id"] === ProjectIdsEnum.Uderly) &&
                            <>
                                <Form.Label>{AppContext.r["phone"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone" disabled
                                        value={values.phone} onChange={handleChange}
                                        isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                            </>}

                        {(AppContext.s["project-id"] === ProjectIdsEnum.Vaffa) &&
                            <>
                                <Form.Label>{AppContext.r["career"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["career"]} name="career_id" disabled
                                                value={Touch.careerById(values.career_id)} />
                            </>}
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["email"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["email"]} name="email" disabled
                                    value={values.email} onChange={handleChange}
                                    isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["date-time"]}</Form.Label>
                        <p>{format(new Date(values.created_at.substr(0, 19)), "d/MM/yyyy H:mm")}</p>
                    </Form.Group>

                    {(values.docs && values.docs.length > 0) &&
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["document"]}</Form.Label><br />
                            <a href={AppContext.r["api-url"] + "/touches/" + values.id + "/download"}>{values.docs[0].file_name}</a>
                        </Form.Group>
                    }

                    <Form.Group as={Col} md="12">
                        <Form.Label>{AppContext.r["message"]}</Form.Label>
                        <Form.Control as="textarea" rows="5" maxLength="500" placeholder={AppContext.r["message"]} name="message" disabled
                                        value={values.message} onChange={handleChange} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label>{AppContext.r["internal-notes"]}</Form.Label>
                        <Form.Control as="textarea" rows="3" maxLength="500" placeholder={AppContext.r["internal-notes"]} name="note" 
                                        value={values.note} onChange={handleChange} />
                    </Form.Group>
                </Form.Row>

                {(values.allow_newsletter) ? 
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            <p><i className="fas fa-heart"></i> {AppContext.r["newsletter-allowed"]}</p>
                        </Form.Group>
                    </Form.Row> : "" }

                {this.formFooter}
            </Form>);
    }
}
