export default function loadClientSettings(settings) {
    settings["language"] = "en";
    settings["website"] = "woolsafe-api.uderly.com";
    settings["project-name"] = "GrandExpo";
    settings["project-url"] = "https://grandexpos.com";

    settings["api-url"] = "https://woolsafe-api.uderly.com/api/v1";

    settings["logo-dark"] = "/assets/images/woolsafe/logo.png";
    settings["logo-light"] = "/assets/images/woolsafe/logo.png";
    settings["mini-logo-dark"] = "/assets/images/woolsafe/logo.png";
    settings["mini-logo-light"] = "/assets/images/woolsafe/logo.png";

    settings['facebook-app-id'] = '954928778580117';//'2110358959097352';
    settings['facebook-page-id'] = '104751018345289';
    settings["enable-facebook-access"] = false;
}
