import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

import AppContext from 'app/AppContext';
import MenuParty from 'api/MenuPro';
// import ShopOrderWidget from './ShopOrderWidget';
import QuizWidget from '../IqSpace/QuizWidget.js';
import StoreWidget from './StoreWidget';
import MenuPartyUserAccessesWidget from './MenuPartyUserAccessesWidget';
import TouchesWidget from './TouchesWidget';

const mapStateToProps = state => ({
    auth: state.auth
});

class Dashboard extends React.Component {
    state = {
        shop: null,
    };

    async componentDidMount() {
        console.log(this.props);
        const response = await MenuParty.MyShops();

        if(response && response.data.data && response.data.data.length > 0) {
            // console.log(response);
            AppContext.s['shop-id'] = response.data.data.id;

            this.setState({
                shop: response.data.data[0]
            });
        }
    }

    onWidgetStatusChanged(status) {
        //console.log(status);
    }

    render() {
        const { shop } = this.state;
        const { user } = this.props.auth;

        if(!shop || !user)
            return AppContext.r["preloader"];

        let map = "";

        if(shop.active === 0) {
            map = <h3 className="text-center">{AppContext.r["shop-not-active"]}</h3>
        } else if(!user.roles[0]) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else {
            const widgets = [];

            if(user.roles[0].id === 1)
                widgets.push({
                    "name": "Contacts",
                    "status": { "collapsed": false },
                    "xl": 12
                });

            // const widgets = user.roles[0].id === 8 ? [] : [
            //     {
            //         "name": "YourShop",
            //         "status": { "collapsed": false },
            //         "xl": 6
            //     },
            //     {
            //         "name": "UserAccesses",
            //         "status": { "collapsed": false },
            //         "xl": 6
            //     },
            // ];

            // if(user.roles[0].id !== 1) {
            //     if(user.roles[0].id === 8)
            //         widgets.push({
            //             "name": "Quiz",
            //             "status": { "collapsed": false },
            //             "xl": 12
            //         });
            
            //     if(user.roles[0].id !== 2 && user.roles[0].id <= 4)
            //         widgets.push({
            //             "name": "ShopOrders",
            //             "status": { "collapsed": false },
            //             "xl": 6
            //         });
            // }
    
            let i = 0;
            map = widgets.map(w => (
                <Col xl={w.xl} sm={12} key={i++}>
                    {w.name === "YourShop" && 
                        <StoreWidget className="your-restaurant" restaurant={shop} collapsed={w.status.collapsed}
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                    {w.name === "UserAccesses" && 
                        <MenuPartyUserAccessesWidget className="graph" restaurant={shop} collapsed={w.status.collapsed}
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                    {w.name === "Quiz" && 
                        <QuizWidget className="quiz" collapsed={w.status.collapsed}
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                    {w.name === "Contacts" && 
                        <TouchesWidget store={shop} />}
                </Col>)
            );
        }
        
        return (
            <div className="home">
                <Row>
                    {map}
                </Row>
            </div>);
    }
}

export default connect(mapStateToProps, null)(Dashboard);