import React from 'react'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import { format } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { Field } from 'formik';
import ReactQuill from 'react-quill';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RemoteSelect from 'framework/RemoteSelect';

import { ContentRoute } from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import WoolSafeApi from 'api/WoolSafe';
import IqSpaceApi from 'api/TriviaPro';
import Module from "../../modules/Module";
import ToggleButton from 'framework/ToggleButton';
import ActionButton from '../../../framework/ActionButton';
import JsonToTable from 'framework/JsonToTable';

import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import TvIcon from '@material-ui/icons/Tv';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ShareIcon from '@material-ui/icons/Share';

import * as yup from 'yup';

AppContext.yupAddUrlTest(yup);

export class ShareEvent extends React.Component {
    state = {
        mode: 0
    };

    handleChange = (e, value) => {
        this.setState({mode: value});
    }

    render() {
        const {event} = this.props;
        const {mode} = this.state;

        // const localizedDomain = AppContext.s["language"] === "it" ? "iqspace.it" : "iqspace.co.uk";
        const localizedDomain = "grandexpos.com";
        let eventLink = localizedDomain+"/event/" + event.id;
        if(mode === 1) eventLink += "/maxi";

        return (
            <div className="shareable-event-links">
                {!this.props.disableIcons && 
                    <BottomNavigation className="modes" value={mode} onChange={this.handleChange}>
                        <BottomNavigationAction value={0} label="Play" icon={<PhoneIphoneIcon />} />
                        <BottomNavigationAction value={1} label="Project" icon={<TvIcon />} />
                    </BottomNavigation> }

                <a href={"https://www."+eventLink} target="_blank" rel="noopener noreferrer">
                    {"www."+eventLink}
                </a>
                
                <ShareIcon className="share-icon" />
            </div>);
    }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Event extends Module {
    info = Event.Info;
    
    static Info = {
        nameResource: "events",
        icon: "fas fa-calendar",
        path: "/events",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Event match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["event"];
    }

    get apiPath() {
      return '/events';
    }

    state = {
        ...this.state,
        isFree: true
    }

    minPrice = 0.5;
    defaultPrice = 5;
    
    get schema () {
        const v = {
            title: yup.string().required(AppContext.r["required-field"]),
            video_url: yup.string().url().nullable()
        };

        if(!this.state.isFree)
            v["price"] = yup.number()
                .required(AppContext.r["required-field"])
                .min(this.minPrice, AppContext.r["ticket-min-price"].replace("{currency}", this.state.store.locale.currency_symbol));
        
        return yup.object(v);
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <div className="row-thumb">
                                {o.image && <img src={AppContext.s["api-url"] + "/events/" + o.id + "/image/thumb"} alt="Thumb" /> }
                            </div>

                            {o.title}
                        </div>);
                }
            },
            {
                "Title": AppContext.r["price"],
                "Adapter": (o) => {
                    let p = this.state.store.locale.currency_symbol + " " + o.price;

                    if(o.price === 0)
                        p = AppContext.r["free"];

                    return (
                        <div className="td-v-center">
                            {p}
                        </div>);
                }
            },
            {
                "Title": AppContext.r["date-time"],
                "Adapter": (o) => {
                    const date = new Date(o.date_time.substr(0, 19));
                    // const readableDate = date.getDate() + " " + AppContext.r['months'][date.getMonth()] + " " + date.getFullYear() + " " + AppContext.r["at-time"] + " " + date.getHours() + ":" + date.getMinutes()
                
                    return <>
                        <div className="td-v-center" style={{minWidth: "100px"}}>
                            {formatInTimeZone(new Date(o.date_time), "Europe/London", "dd/MM/yyyy H:mm")}
                        </div>
                    </>;
                }
            },{
                "Title": AppContext.r["type"],
                "ClassName": "d-none d-sm-table-cell",
                "Adapter": (o) => {
                    const field = this.typeOptions[o.type_id];
                    return (field) ? field.type : "";
                }
              },
            {
                "Title": "",
                "Field": "published",
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <ActionButton selected={o.published}
                                action={async () => {
                                    const responseObj = await this.onTogglePublished(o);

                                    if(responseObj) {
                                        const records = [...this.state.records];

                                        for(let item of records) {
                                            if(item && item.id === responseObj.id) {
                                                item.published = responseObj.published;
                                            }
                                        }

                                        this.setState({
                                            records: records
                                        });
                                    }
                                }
                            }>{o.published ? AppContext.r["yes"] : AppContext.r["no"]}</ActionButton>
                        </div>);
                }
            }
        ];
    }
    
    onTogglePublished = async (o) => {
        const item = {
            id: o.id,
            published: !o.published ? 1 : 0 
        };

        return await this.update(item);
    }

    get tableAttendancesHead () {
        return [
            {
                "Title": AppContext.r["ticket-id"],
                "Field": "id"
            },{
                "Title": AppContext.r["fullname"],
                "Adapter": (o) => {
                    return o.user.name + " " + o.user.surname;
                }
            },
            {
                "Title": AppContext.r["purchased-on"],
                // Adapter: (o) => format(new Date(o.created_at.substr(0, 19)), "dd/MM/yyyy H:mm")
                Adapter: (o) => formatInTimeZone(new Date(o.created_at), "Europe/London", "dd/MM/yyyy H:mm")
            },{
                "Title": AppContext.r["voucher"],
                "Field": "discount_code"
            },{
                "Title": AppContext.r["attendance_status"],
                "Adapter": (o) => {
                    const status = AppContext.r["attendance_statuses"][o.status_id];

                    if(o.status_id === 2 
                        // && o.payment_intent 
                        && o.transfer_id && o.amount > 0)
                        return <span>{status}<Button variant="link" onClick={() => this.confirmRefund(o)}>{AppContext.r["refund"]}</Button></span>;

                    return status;
                }
            }
        ];
    }

    get initialValues() {
        return {
            title: "",
            published: true,
            description: "",
            date_time: format(new Date(), "yyyy-MM-dd 20:30:00"),
            timezone: "",
            video_url: "",
            location_id: null,
            price: 123456789,
            netIncome: 0,
            transactionFee: 0,
            disable_meeting_in_app: 0
        };
    }
    
    async beforeComponentDidMount() {
        // Fetch locations
        //this.locationOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locations", "id");
        const myShopsResponse = await WoolSafeApi.MyShops();
        console.log(myShopsResponse);

        if(myShopsResponse && myShopsResponse.status === 200 && myShopsResponse.data.data[0]) {
            const store = myShopsResponse.data.data[0];

            this.setState({
                store: store
            });

            this.calculateFees(null, store);
        }

        // Fetch Event Types
        const response = await WoolSafeApi.EventTypes();
        console.log(response)
        if(response && response.data) {
            this.typeOptions = CoreApi.mapData(response.data.data, "id");
        }
    }

    onFetchedItem = (item) => {
        if(item.price > 0)
            this.setState({isFree: false});
        this.calculateFees(item.price);
    }

    confirmRefund = (attendance) => {
        const text = AppContext.r["refund-confirm"].replace("{user}", attendance.user.name);

        this.confirm(AppContext.r["refund-ticket"], text, 
            async () => await this.refund(attendance));
    }

    refund = async (attendance) => {
        this.setState({
            modalBusy: true
        });

        const response = await IqSpaceApi.RefundTicket(attendance);
        console.log(attendance, response);

        this.fetchItem(this.state.item.id);

        this.setState({
            modalBusy: false,
            showModal: false
        });
    }

    calculateFees = (price = null, store = null) => {
        if(!store)
            store = this.state.store;
        if(!price)
            price = this.state.price;
        if(!price)
            price = this.initialValues.price;

        const transactionFee = ((parseFloat(price) / 100 * parseFloat(store.stripe_fee)) + parseFloat(store.stripe_fixed_fee));

        this.setState({
            netIncome: parseFloat(price) - transactionFee,
            transactionFee: transactionFee
        });
    }

    get freeTicketsMap() {
        const {free_tickets} = this.state.item;

        if(!free_tickets)
            return "";

        const tickets = JSON.parse(free_tickets);

        return (
            <Form.Row>
                <p>{AppContext.r["free-tickets-desc"]}</p>

                <div className="free-tickets">
                    {tickets.map(t => 
                        <Chip variant="outlined" label={t.code} icon={<Icon className="fas fa-ticket-alt" />} />)}
                </div>
            </Form.Row>);
    }

    onDeleted = (response) => {
        console.log(response, this.props);

        if(response.status === 423)
            this.setState({
                error: AppContext.r["cant-delete-event"]
            });

        this.fetchRecords();
    }

    insertDataAdapter = (values) => {
        if(this.state.isFree)
            values["price"] = 0;
    //     // TODO: make it more generic
    //     values["timezone"] = "+0"+this.state.store.timezone+":00";
        return values;
    }

    updateAdapter = (values) => {
        if(values["date_time"] && values["date_time"].indexOf(".000000Z") > 0) {
            delete values["date_time"];
        }

        //values["date_time"] = formatInTimeZone(new Date("2022-04-26 23:00:00"), "UTC", "yyyy-MM-dd HH:mm:ss");
        //values["date_time"] = "2022-04-26 21:00:00";

        if(this.state.isFree)
            values["price"] = 0;

        return values;
    }

    get mask () {
        this.formImageRatio = 4/3;

        const {store, error, item} = this.state;

        // Workaround
        if(item.tz === undefined)
            item.tz = "Europe/London";

        if(!store)
            return AppContext.r["preloader"];

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form className="event-mask" onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title" autoFocus
                                    value={values.title} onChange={handleChange}
                                    isValid={touched.title && !errors.title} isInvalid={!!errors.title} />
                                <div className="error-message">{errors.title}</div>
                            </Form.Group>

                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.published = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                             <Form.Group as={Col} md="5">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker ampm={false} format="d MM yyyy - H:mm"
                                        value={item.tz ?
                                            formatInTimeZone(values.date_time, item.tz, "yyyy-MM-dd H:mm:ss") : 
                                            values.date_time.substr(0, 19)}
                                        onChange={(v) => {
                                            item.tz = null;
                                            setFieldValue("date_time", format(v, "yyyy-MM-dd H:mm:ss"));
                                        }}
                                        label={AppContext.r["date-time"]} />
                                </MuiPickersUtilsProvider>
                            </Form.Group>

                            {this.isEdit && 
                                <Form.Group as={Col} md="2">
                                    <Form.Label>{AppContext.r["timezone"]}</Form.Label>
                                    <p>{values.timezone}</p>
                                    {/* <Form.Control type="text" placeholder={AppContext.r["title"]} name="timezone" readOnly small
                                                    value={values.timezone} onChange={handleChange} /> */}
                                </Form.Group> }

                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["type"]}</Form.Label>

                                <RemoteSelect name="type_id" key={values.type_id} value={values.type_id} nullOption={true} fieldToMap="type"
                                                onChange={handleChange} options={this.typeOptions} />
                            </Form.Group>

                            <Form.Group as={Col} xs="12">
                                <Form.Label>Video</Form.Label>
                                <Form.Control type="text" placeholder="Video" name="video_url"
                                    value={values.video_url} onChange={handleChange}
                                    isValid={touched.video_url && !errors.video_url} isInvalid={!!errors.video_url} />
                                <div className="error-message">{errors.video_url}</div>
                            </Form.Group>
                        </Form.Row>

                        {/* Zoom */}

                        <Form.Row>
                            <Col xs={12}>
                                <h3>Zoom</h3>
                            </Col>
                            
                            <Col xs={12}>
                                {AppContext.r["zoom-meeting-desc"]}
                            </Col>

                            <Col xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={values.disable_meeting_in_app} 
                                        onChange={(e) => {
                                            setFieldValue("disable_meeting_in_app", !e.target.checked ? 0 : 1);
                                        }} name="disable_meeting_in_app" />}
                                    label={"Disable \"Join Meeting In App\""} />
                            </Col>
                        </Form.Row>

                        <br />

                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["zoom-meeting-id"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["zoom-meeting-id"]} name="zoom_meeting_id"
                                                value={values.zoom_meeting_id} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["zoom-meeting-password"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["zoom-meeting-password"]} name="zoom_meeting_password"
                                                value={values.zoom_meeting_password} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                        
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Field name="description">
                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                </Field>
                            </Form.Group>
                        </Form.Row>
                    </Col>

                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>

                {/* {store.licence_id <= 2 && */}
                <Form.Row>
                    <Col xs={12}>
                        <h3>{AppContext.r["tickets"]}</h3>
                    </Col>

                    <Form.Group as={Col} xs={12}>
                        <FormControlLabel
                            control={<Switch checked={this.state.isFree} 
                                onChange={(e) => {
                                    // console.log(item.attendances)
                                    setFieldValue("price", !e.target.checked ? this.defaultPrice : 123456789);
                                    this.setState({isFree: e.target.checked });

                                    if(!e.target.checked)
                                        this.calculateFees(this.defaultPrice);
                                }} name="isFree" />}
                            label={AppContext.r["this-is-a-free-event"]} />
                    </Form.Group>

                    { this.state.isFree && store.licence &&
                        <p>{AppContext.r["licence"]}: <b>{store.licence.title}</b> {store.licence.max_attendants_count + " | " + AppContext.r["players"]}</p>}

                    { !this.state.isFree && 
                    <>
                        <Form.Group as={Col} xs="4" md={3}>
                            <Form.Label>{AppContext.r["price"]}*</Form.Label>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ticketPrice">{store.locale.currency_symbol}</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                                <Form.Control type="text" aria-describedby="ticketPrice"
                                    placeholder={AppContext.r["price"]} name="price" value={values.price} 
                                    onChange={(event) => { handleChange(event); this.calculateFees(event.target['value']); }}
                                    isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
                                <div className="error-message">{errors.price}</div>
                            </InputGroup>
                        </Form.Group>
                    
                        <Col xs={4} md={3}>
                            <Form.Label>{AppContext.r["transaction-fee"]}</Form.Label>
                            <p className="transaction-fee">{store.locale.currency_symbol + " " + this.state.transactionFee.toFixed(2)}</p>
                        </Col>
                        
                        <Col xs={4} md={3}>
                        <Form.Label>{AppContext.r["you-earn"]}</Form.Label>
                        <p className="you-earn">{store.locale.currency_symbol + " " + this.state.netIncome.toFixed(2)}</p>
                    </Col>
                    </>
                    }
                </Form.Row> 
                {/* } */}

                {(/*store.licence_id <= 2 && */!this.state.isFree) && this.freeTicketsMap}
                
                {(/*store.licence_id <= 2 && */!this.state.isFree && this.state.item && this.state.item.id) &&
                    <Form.Row style={this.displayOnEditStyle}>
                        <Col xs={12}>
                            <h3>{AppContext.r["attendances"]}</h3>
                            {item.attendances && 
                                <p><b>{item.attendances.filter(x => (x.status_id === 2 && x.amount > 0)).length}</b> Sold,&nbsp;
                                    <b>{item.attendances.filter(x => (x.status_id === 2 && x.amount === 0)).length}</b> Obtained by voucher</p>
                            }
                        </Col>

                        <Col xs={12}>
                            <JsonToTable head={this.tableAttendancesHead} body={this.state.item.attendances}
                                noItemsText={AppContext.r["no-attendances"]} />
                        </Col>
                    </Form.Row> }

                {values.id && 
                    <Form.Row>
                        <Col>
                            <p>Your event is online at:</p>
                            <ShareEvent event={values} disableIcons />
                        </Col>
                    </Form.Row> }

                {this.formFooter}

                {error && 
                    <Form.Row className="error-row">
                        <Snackbar open={error != null} autoHideDuration={20000000} 
                            onClose={() => this.setState({error: null})}>
                            <Alert severity="warning" onClose={() => this.setState({error: null})}>{error}</Alert>
                        </Snackbar>
                    </Form.Row> }
            </Form>);
    }
}