import React from 'react';
import { Col, Form } from 'react-bootstrap';
import * as yup from "yup";

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import UderlyApi from 'api/Uderly';
import Module from "app/modules/Module";
import MediaStockImage from '../../../framework/MediaStockImage';

export default class MediaStockRating extends Module {
    info = MediaStockRating.Info;
    
    static Info = {
        nameResource: "ratings",
        icon: "fas fa-child",
        path: "/ratings",
        show: true
    };

    static get route() {
        return <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <MediaStockRating match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["rating"];
    }

    get apiUrl() {
        return AppContext.s["net-api-url"];
    }

    get apiPath() {
        return "/mediastock/ratings";
    }

    get imageUrl() {
        return this.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required()
        });
    }

    get tableHead () {
        return [
        // {
        //     Title: AppContext.r["id"],
        //     Field: "id",
        //     ClassName: "d-none d-sm-table-cell",
        // },
        {
            Title: AppContext.r["title"],
            Adapter: function(o) {
                const style = {
                    backgroundColor: o.color
                };
                
                return (<div><div className="category-dot" style={style}>&nbsp;</div>{o.title}</div>);
            }
          },
          {
              "Adapter": (o) => {
                  return (
                      <div className="td-v-center">
                          <div className="row-thumb">
                              {(o.media && o.media.length > 0) && <img src={this.apiUrl + this.apiPath + "/" + o.id + "/image"} alt="Thumb" /> }
                          </div>
  
                          {o.name}
                      </div>);
              }
          }
        ];
    }

    get initialValues() {
        return {
            title: "",
        };
    }
    
    async beforeComponentDidMount() {
        const myStoresResponse = await UderlyApi.MyStores();

        if(myStoresResponse && myStoresResponse.status === 200 && myStoresResponse.data.data[0]) {
            const store = myStoresResponse.data.data[0];

            console.log(store);

            this.filters = {
                storeSubdomain: store.subdomain
            };

            this.setState({
                store: store
            });
        }
    }

    insertDataAdapter = (values) => {
        const { media, store } = this.state;

        values["media"] = media;
        if(store)
            values["store_id"] = store.id;

        return values;
    }
    
    updateDataAdapter = (values) => {
        if(this.state.media)
            values["media"] = this.state.media;

        return values;
    }

    // TODO: Move

    onImageFileChanged = (imageFile) => {
        const { item } = this.state;

        const m = [ 
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });

        if(item.id)
            this.update({ ...item, media: m });
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="category-form" onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                                value={values.title} onChange={handleChange}
                                                isValid={touched.title && !errors.title} isInvalid={!!errors.title} />
                            </Form.Group>
                        </Form.Row>        
                        
                        <Form.Row>
                            <Form.Group as={Col} xs="9">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange} />
                            </Form.Group>

                            {/* <Form.Group as={Col} xs="3">
                                <Form.Label>{AppContext.r["color"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["color"]} name="color" maxLength="7"
                                                value={values.color} onChange={handleChange} />
                            </Form.Group> */}
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        <MediaStockImage sx={{ width: 56, height: 56 }} variant="squircle"
                            imageUrl={values.id ? (this.imageUrl) : null}
                            item={this.state.item}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Col>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}