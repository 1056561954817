import React from 'react';

import {ContentRoute} from "_metronic/layout";

import ShopOrder from "./ShopOrder";

export default class EnotecaOrder extends ShopOrder {
    info = EnotecaOrder.Info;
    
    showPagination = true;
    
    get pathname () {
        return "/enoteca/orders";
    }

    static Info = {
        nameResource: "enoteca-orders",
        "icon": "fas fa-sort-amount-down",
        "path": "/enoteca/orders",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <EnotecaOrder match={props.match} location={props.location} history={props.history} />} />;
    }

    constructor(props) {
        super(props);

        this.shopId = 2;
    }
}