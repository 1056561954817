export default function loadClientSettings(settings) {
    settings["language"] = "en";
    settings["website"] = "api.staysicily.it";
    settings["project-name"] = "Stay Sicily";
    settings["project-url"] = "https://guest.staysicily.it";

    settings["api-url"] = "https://api.staysicily.it/api/v1";

    settings["logo-dark"] = "/assets/images/stay/logo.png";
    settings["logo-light"] = "/assets/images/stay/logo.png";
    settings["mini-logo-dark"] = "/assets/images/stay/logo.png";
    settings["mini-logo-light"] = "/assets/images/stay/logo.png";

    settings['facebook-app-id'] = '954928778580117';//'2110358959097352';
    settings['facebook-page-id'] = '104751018345289';
    settings["enable-facebook-access"] = false;
}
