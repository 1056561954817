import React from 'react';

import AppContext from 'app/AppContext';
import QrtServiceApi from 'api/QrtService';
import Order from '../../pages/QrtService/Order.js';
import JsonToTable from 'framework/JsonToTable';
import { asWidget } from 'framework/asWidget.js';

class OrdersWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: null
        };

        if(this.props.showRequestedDate)
            this.tableHead.push({
                "Title": AppContext.r["requested-date"],
                "Field": "requested_date",
                "Adapter": function(o) {
                    return (o.requested_date === null) ? "" : o.requested_date.substring(0,10);
                }
            });

        this.interval = null;
    }

    tableHead = [
        {
            "Title": AppContext.r["id"],
            "Field": "id"
        },
        // {
        //     "Title": AppContext.r["type"],
        //     "Adapter": function(o) {
        //         return (o && o.shop_order_type_id) ? AppContext.r["order-type"][o.shop_order_type_id] : "";
        //     }
        // },
        {
            "Title": AppContext.r["name"],
            "Field": "name",
            "Adapter": function(o) {
                return (o.user === null) ? "" : o.user.name + " " + ((o.user.surname !== null) ? o.user.surname : "");
            }
        },{
            "Title": AppContext.r["placed-at"],
            "Field": "created_at",
            "ClassName": "d-none d-sm-table-cell",
        },
        // {
        //     "Title": AppContext.r["note"],
        //     "ClassName": "d-none d-sm-table-cell",
        //     "Field": "note"
        // },
        {
            "Title": AppContext.r["status"],
            "Field": "status",
            "Adapter": function(o) {
                return (o.status === null) ? "" : o.status.title;
            }
        }
    ];
    
    fetchOrders = async () => {
        // Only show orders not completed (received)
        const response = await QrtServiceApi.Orders(1, "?filters={\"status\":[1,2]}");

        if(response && response.data)
            this.setState({
                orders: response.data.data
            });
        else {
            console.log('error ', response);
        }
    }

    static updateInterval = 3000; // ms

    componentDidMount() {
        const t = this;

        this.fetchOrders();

        if(!this.interval)
            this.interval = setInterval(() => {
                if(window.location.pathname === "/" || window.location.pathname === "/dashboard") {
                    // console.log(window.location.pathname)
                    t.fetchOrders();
                }
            }, OrdersWidget.updateInterval);
    }
    
    componentWillUnmount() {
        if(this.interval)
            clearInterval(this.interval);
        this.interval = null;
    }

    onRowClick = (order) => {
        this.props.setRedirectTo(Order.Info.path + "/" + order.id);
    }

    get widget() {
        const view = (this.state.orders == null) ? AppContext.r["preloader"] : 
            this.state.orders.length > 0 ? 
                <JsonToTable onRowClick={this.onRowClick} head={this.tableHead} body={this.state.orders} /> : <div><i className="far fa-folder-open"></i><p>{AppContext.r["no-pending-orders"]}</p></div>

        return (
            <div className="shop-order-widget">
                {view}
            </div>
        );
    }

    render() {
        return (
            <div>
                <h1>{AppContext.r["pending-orders"]}</h1>

                {this.widget}
            </div>);
    }
}

export default asWidget(OrdersWidget);