import React from 'react';
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import AppContext from 'app/AppContext';
import WoolSafeApi from 'api/WoolSafe';
import Event from '../../pages/WoolSafe/Event';
import { asWidget } from 'framework/asWidget.js';

class EventsWidget extends React.Component {
    state = {
        events: null
    };

    async componentDidMount() {
        const response = await WoolSafeApi.Events(1);

        if(response && response.status === 200) {
            this.setState({
                events: response.data.data
            });
        }
    }

    render() {
        const events = (!this.state.events) ? AppContext.r["preloader"] : 
            (this.state.events.length === 0) ? (
                <>
                    <p className="text-center">
                        {AppContext.r["no-upcoming-events"]}
                    </p>
                    <p>
                        <Link to={Event.Info.path + "/insert"}>{AppContext.r["create"]} a new event.</Link>
                    </p>
                </>
            ) :
            this.state.events.map((event) => {
                const imageUrl = AppContext.s["host-url"] + "/api/v1/events/" + event.id + "/image/thumb";

                return (
                    <Paper className="event" key={event.id}>
                        <div className="image">
                            { event.image && 
                                <img src={imageUrl} alt={event.title} /> }
                        </div>

                        <div className="date-time">
                            <p>{formatInTimeZone(new Date(event.date_time), "Europe/London", "d-MM-yyyy H:mm")}</p>
                            
                        </div>

                        <div className="title">
                            <h4>{event.title}</h4>
                        </div>
                        
                        <div className="action">
                            <Link to={Event.Info.path + "/" + event.id}>{AppContext.r["edit"]}</Link>
                        </div>
                    </Paper>);
            });

        return (
            <>
                <h1>{AppContext.r["upcoming-events"]}</h1>
  
                <div className="events">
                    {events}
                </div>
            </>
        );
    }
}

export default asWidget(EventsWidget);