export default function loadClientSettings(settings) {
    settings["language"] = "en";
    settings["website"] = "api.iqspace.it";
    settings["project-name"] = "IQ Space";

    settings["play-api-url"] = "https://play.iqspace.it/api/squizzer/";
    // settings["play-api-url"] = "http://localhost:56186/api/squizzer/";
    settings["api-url"] = "https://api.iqspace.it/api/v1";

    settings["logo-dark"] = "/assets/images/iqspace/logo-light.png";
    settings["logo-light"] = "/assets/images/iqspace/logo-light.png";
    settings["mini-logo-dark"] = "/assets/images/iqspace/logo-light.png";
    settings["mini-logo-light"] = "/assets/images/iqspace/mini-logo.png";
    settings["mini-logo-qr"] = "/assets/images/iqspace/mini-logo-qr.png";

    settings['facebook-app-id'] = '954928778580117';//'2110358959097352';
    settings['facebook-page-id'] = '104751018345289';
    settings["enable-facebook-access"] = true;
    settings["enable-facebook-messenger"] = false;
}
