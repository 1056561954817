import React from 'react';
import { Form, Col } from 'react-bootstrap';

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import RemoteSelect from '../../../framework/RemoteSelect';
import {withModal} from 'framework/withModal';
import QuickModule from 'framework/QuickModule';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);
AppContext.yupAddUrlTest(yup);

class _Socials extends React.Component {
    state = { };

    get tableHead () {
        return [
            {
                "Title": "URL",
                "Field": "url",
            },{
                "Title": AppContext.r["url"],
                "Adapter": (o) => {
                    return o.type.name;
                }
            }
        ];
    }

    get schema () {
        return yup.object({
            url: yup.string().url().nullable()
        });
    }

    async componentDidMount() {
        // Fetch Social Types
        const response = await CoreApi.SocialTypes();
        
        if(response && response.data) {
            this.setState({
                socialTypes: response.data.data
            });

            this.socialTypeOptions = CoreApi.mapData(response.data.data, "id");
        }
    }

    view = ({ handleChange, values, touched, errors }) => (
        <>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>URL *</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["url"]} name="url" autoFocus
                                    value={values.url} onChange={handleChange}
                                    isValid={touched.url && !errors.url} isInvalid={!!errors.url} />
                </Form.Group>

                <Form.Group as={Col} md="4" xs="6">
                    <Form.Label>{AppContext.r["social-type"]}</Form.Label>

                    <RemoteSelect name="social_type_id" key={values.social_type_id} value={values.social_type_id} onChange={handleChange}
                                options={this.socialTypeOptions} fieldToMap="name" />
                </Form.Group>
            </Form.Row>
        </>);

    render() {
        const {socialTypes} = this.state;

        if(!socialTypes)
            return AppContext.r["preloader"];

        return <QuickModule initialValues={{"url": "", "social_type_id": socialTypes.length > 0 ? socialTypes[0].id : -1}}
            className={this.props.className}
            apiPath={'/'+this.props.sociableType+'/'+ this.props.sociableId + '/socials'} 
            tableHead={this.tableHead} schema={this.schema} view={this.view} />;
    }
}

export default withModal(_Socials);