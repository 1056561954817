export default function loadClientSettings(settings) {
    settings["language"] = "en";
    settings["website"] = "api.rafalauk.com";
    settings["project-name"] = "Rafalà";

    settings["api-url"] = "https://api.rafalauk.com/api/v1";

    settings["logo-dark"] = "/assets/images/rafala/logo.svg";
    settings["logo-light"] = "/assets/images/rafala/logo.svg";
    settings["mini-logo-dark"] = "/assets/images/rafala/logo.svg";
    settings["mini-logo-light"] = "/assets/images/rafala/logo.svg";
    settings["mini-logo-qr"] = "/assets/images/rafala/logo.svg";

    // settings['facebook-app-id'] = '954928778580117';
    // settings['facebook-page-id'] = '104751018345289';
    settings["enable-facebook-access"] = false;
    settings["enable-facebook-messenger"] = false;
}
