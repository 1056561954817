export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        // console.log(authToken);
      }

      return config;
    },
    err => Promise.reject(err)
  );

  // axios.interceptors.response.use(response => {
  //   console.log("LOGGED OUT")
  //   return response;
  // }, error => {
  //   if (error.response.status === 401) {
  //     //place your reentry code
  //     window.location.href = "/logout";
  //   }
  //   return error;
  // });
}
