export default function loadClientSettings(settings) {
    settings["language"] = "it";
    settings["website"] = "vaffa.uderly.com";
    settings["project-name"] = "Vaffa Cosmo";
    settings["api-url"] = "https://vaffa-api.uderly.com/api/v1";

    settings["logo-dark"] = "/assets/images/vaffa/logo.png";
    settings["logo-light"] = "/assets/images/vaffa/logo.png";
    settings["mini-logo-dark"] = "/assets/images/vaffa/logo.png";
    settings["mini-logo-light"] = "/assets/images/vaffa/logo.png";
    settings["mini-logo-qr"] = "/assets/images/vaffa/logo.png";

    // settings['facebook-app-id'] = '954928778580117';
    // settings['facebook-page-id'] = '104751018345289';
    settings["enable-facebook-access"] = false;
    settings["enable-facebook-messenger"] = false;
}
