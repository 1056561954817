import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    alert: {
        open: false,
        autoHideDuration: 6000,
        message: "",
        severity: "success",
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }
    }
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAlert(state, action) {
            // console.log(state, action);
            Object.assign(state.alert, action.payload);
        },
        hideAlert(state) {
            state.alert.open = false;
        }
    },
});

export const { setAlert, hideAlert } = layoutSlice.actions
export default layoutSlice;