import React from 'react';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import ShopItem from './ShopItem'

export default class EnotecaItem extends ShopItem {
    info = EnotecaItem.Info;

    showPrice = true;
    showAlcoholPercent = true;

    get shopId () { return 2; }
    
    get pathname () {
        return "/enoteca/items";
    }
    
    static Info = {
        nameResource: "enoteca-items",
        "icon": "fas fa-wine-glass-alt",
        "path": "/enoteca/items",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <EnotecaItem match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["enoteca-item"];
    }

    // get recordsApiPath() {
    //   return '/shopitems?shop=2';
    // }
    
    get apiPath() {
        return '/shop/items';
    }
    
    get recordsApiPath() {
        return '/shop/2/items';
    }
    
    get createApiPath() {
        return '/shop/2/items';
    }
}