import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { parse  } from 'date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import * as yup from 'yup';
// import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch } from '@material-ui/core';

import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
import StripeApi from "api/Stripe";
import FormImage from 'framework/FormImage';
import ActionButton from 'framework/ActionButton';
import { asWidget } from 'framework/asWidget.js';
import _Socials from "app/pages/Core/_Socials";
import _Streams from "app/pages/Core/_Streams";
import _TeamMembers from "app/pages/Core/_TeamMembers";

import 'react-quill/dist/quill.snow.css';

var HtmlToReactParser = require('html-to-react').Parser;

AppContext.yupAddPhoneTest(yup);

class StoreWidget extends React.Component {
    formImageRef = React.createRef();
    coverFormImageRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            store: this.props.store,
            stripeAccount: undefined,
            enableEdit: false,
            openingTimes: null, // Storeable Value
            tabPage: 0
        };
    }

    async componentDidMount() {
        const {store} = this.state;

        if(store.licence_id === 1) {
            // Retrieve Stripe Connected Account
            const r = await StripeApi.RetrieveAccount(store.id);
            console.log(r);
    
            this.setState({
                stripeAccount: (r && r.data && r.status === 200) ? r.data : null
            });
        }

        if(document.getElementById("Description")) {
            const htmlToReactParser = new HtmlToReactParser();
            const reactElement = htmlToReactParser.parse(this.state.store.description);

            ReactDOM.render(
                reactElement,
                document.getElementById('Description')
            );
        }
        
        // if(store.opening_times)
        //     this.setState({
        //         openingTimes: JSON.parse(store.opening_times)
        //     });
        
        // Fetch countries
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");

        console.log(this.state.store);
    }

    restaurantPage(includeHttp = false) {
        return ((includeHttp) ? "https://" : "") + this.state.store.subdomain + "." + AppContext.s["access-domain"];
    }

    onImageFileChanged = async (file, attributeName = 'image') => {
        console.log(AppContext.s["api-url"] + "/users/media")
        const response = await CoreApi.uploadImageFile(file, AppContext.s["api-url"] + "/users/media", attributeName);
        // await Core.EditUser(user.id, user);
        console.log(response);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                image_name: file.name
            };

            item[attributeName] = response.data.name;
            console.log(item);

            await CoreApi.updateItem(AppContext.s["api-url"] + '/stores/'+ this.state.store.id, item);
        // await t.update(item, attributeName === 'image', attributeName === 'cover');
        }
    }

    onDeleteImage = async (attribute = "image") => {
        // await CoreApi.EditUser(this.props.auth.user.id, {image: "-1"});
        const data = {}
        data[attribute] = -1;

        await CoreApi.updateItem(AppContext.s["api-url"] + '/stores/'+ this.state.store.id, data);
        
        if(attribute === "image")
            this.formImageRef.current.resetImage();
        if(attribute === "cover")
            this.coverFormImageRef.current.resetImage();
    }

    onUpdate = async (values) => {
        this.props.setOverlayMessage(AppContext.r["preloader"]);

        // const values = {
        // //    opening_times: JSON.stringify(this.openingTimes)
        // };

        delete values["image"];
        delete values["cover"];

        // values["checkin_at"] += ":00";

        const response = await CoreApi.updateItem(AppContext.s["api-url"] + '/stores/'+ this.state.store.id, values);

        console.log(response, values);
        if(response && response.status === 202) {
            const store = {...this.state.store};
            
            store["name"] = values.name;
            // ...

            this.setState({
                store: store
            });
        }

        this.props.setOverlayMessage(null);
    }

    // stripeOnboard = async () => {
    //     const r = await IqSpaceApi.OnBoard(this.state.store.id);
    //     console.log(r);

    //     if(r && r.status === 201) {
    //         // AppContext.history.replace(r.data.account_links.url);
    //         window.location = r.data.account_links.url;
    //     }
    // }

    // stripeViewAccount = async () => {
    //     const r = await IqSpaceApi.LoginLink(this.state.store.id);
    //     console.log(r);

    //     if(r && r.status === 200 && r.data.login_link) {
    //         // AppContext.history.replace(r.data.account_links.url);
    //         window.location = r.data.login_link.url;
    //     }
    // }

    // Convert minutes to HH:mm format
    convert = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2);

    render() {
        const { store, enableEdit, stripeAccount } = this.state;

        if(!store)
            return AppContext.r["preloader"];

        // if(enableEdit) {
            const allDayHours = [];
            const interval = 15; // mins

            for(let mins = 0; mins < (24 * 60); mins += interval) {
                allDayHours.push(mins);
            }

            const checkInFrom = parse(store.checkin_at, "HH:mm:ss", new Date());
            const checkOutTo = parse(store.checkout_at, "HH:mm:ss", new Date());

            const checkinItems = allDayHours.map(h => 
                <MenuItem value={this.convert(h)} 
                    disabled={false && h < (checkInFrom.getHours() * 60 + checkInFrom.getMinutes())}>
                    {this.convert(h)}
                </MenuItem>);

            const checkoutItems = allDayHours.map(h => 
                <MenuItem value={this.convert(h)} 
                    disabled={false && h > (checkOutTo.getHours() * 60 + checkOutTo.getMinutes())}>
                    {this.convert(h)}
                </MenuItem>);
        // }

        // Stripe OnBoard/OnBoarded View
        // let stripeView = "";

        // if(stripeAccount === undefined) {
        //     stripeView = AppContext.r["preloader"];
        // } else {
        //     if(stripeAccount === null || !stripeAccount.charges_enabled)
        //         stripeView = 
        //             <>
        //                 {AppContext.r["stripe-partners"]}

        //                 <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
        //                     action={this.stripeOnboard}>{AppContext.r["connect-stripe"]}</ActionButton>
                        
        //                 <br />
                        
        //                 <Alert className="stripe-error-alert" severity="error">
        //                     <AlertTitle>{(stripeAccount && !stripeAccount.charges_enabled) && AppContext.r["stripe-charges-not-enabled"]}</AlertTitle>
                            
        //                     {AppContext.r["stripe-required"]}</Alert>
        //             </>;
        //     else
        //         stripeView =
        //             <>
        //                 <p>{AppContext.r["stripe-successfully-connected"]}</p>

        //                 { !stripeAccount.payouts_enabled && stripeAccount.type !== "standard" && 
        //                     <>
        //                         <Alert className="stripe-error-alert" severity="error">
        //                             {AppContext.r["stripe-payouts-not-enabled"]}
        //                         </Alert>
        //                         <br />
        //                     </> }

        //                     {store.stripe_account_type == "express" ?
        //                         <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
        //                             action={this.stripeViewAccount}>{AppContext.r["view-stripe-account"]}</ActionButton> :
        //                             AppContext.r["view-stripe-dashboard"] }
        //             </>;
        // }

        const toggleEnableEdit = () => {
            if(!enableEdit)
                this.props.setAllowMaximize(!enableEdit);

            this.setState({enableEdit: !enableEdit})
        }

        return (
            <>
                <h1>{store.name}</h1>
                
                <div className="actions">
                    <ActionButton selected={enableEdit} 
                        tooltip={!enableEdit ? AppContext.r["enable-edit"] : AppContext.r["disable-edit"]}
                        action={toggleEnableEdit}>
                        <IconButton aria-label="settings">
                            <SettingsIcon />
                        </IconButton>
                    </ActionButton>
                </div>
                
                {/* <Row>
                    <Col>
                        <FormImage viewOnly={!enableEdit}
                            className="cover"
                            ref={this.coverFormImageRef} ratio={3}
                            imageUrl={AppContext.s["api-url"] + '/stores/'+ store.id + '/cover'} 
                            onDelete={() => this.onDeleteImage("cover")}
                            onImageFileChanged={(file) => this.onImageFileChanged(file, 'cover')} />
                    </Col>
                </Row> */}

                <Row>
                    <Col>
                        <FormImage type="avatar" viewOnly={!enableEdit} 
                            rounded height="128px" maxWidth="128px" className="avatar"
                            ref={this.formImageRef}
                            imageUrl={AppContext.s["api-url"] + '/stores/'+ store.id + '/image'}
                            onDelete={this.onDeleteImage}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>{AppContext.r["page-at"]} <a target="_blank" href={AppContext.s["project-url"]+"/"+store.subdomain}>{AppContext.s["project-url"]+"/"+store.subdomain}</a></p>
                        <p><small>{AppContext.r["wish-change-subdomain"]}</small></p>
                    </Col>
                </Row>

                { enableEdit && 
                    <Row>
                        <Col>
                            <Formik
                                initialValues={store}
                                validationSchema={yup.object({
                                    name: yup.string().required().min(6),
                                    phone: yup.string().phone().nullable(),
                                    email: yup.string().email().nullable(),
                                })}
                                onSubmit={(values) => this.onUpdate(values)}>

                                {({ isSubmitting, values, setFieldValue }) => (
                                    <Form className="form store-widget-edit">
                                        <Row className="justify-content-center">
                                            <Col xs={10} md={6}>
                                                <Field className="field" component={TextField} name="name" type="text"
                                                    label={AppContext.r["name"]} />
                                            </Col>

                                            {/* <Col xs={2}>
                                                <label className="color-label">Company Color</label>
                                                <ColorPicker value={values.color}
                                                    onChange={(value) => {
                                                        setFieldValue("color", value);
                                                    }} />
                                            </Col> */}

                                            <Col md={10}>
                                                <label className="textarea-label">{AppContext.r["description"]}</label>
                                                
                                                <Field name="description">
                                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                                </Field>
                                            </Col>

                                            <Col md={10}>
                                                <label className="textarea-label">{AppContext.r["arrival-info"]}</label>
                                                
                                                <Field name="arrival_info">
                                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                                </Field>
                                            </Col>

                                            <Col md={10}>
                                                <label className="textarea-label">{AppContext.r["guests-info"]}</label>
                                                
                                                <Field name="guests_info">
                                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                                </Field>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center">
                                            <Col md={5}>
                                                <InputLabel id="parking-spaces">{AppContext.r["parking-spaces"]}</InputLabel>
                                                <Select labelId="parking-spaces" className="parking-spaces"
                                                    name="parking_spaces"
                                                    value={values.parking_spaces}
                                                    onChange={(event) => {
                                                        setFieldValue("parking_spaces", event.target.value);
                                                    }}>
                                                    {[0,1,2,3,4,5,6,7,8,9,10].map(x =>
                                                        <MenuItem key={"parking-space-"+x} value={x}>{x}</MenuItem>)}
                                                    <MenuItem key={"parking-space-infinite"} value={255}>10+</MenuItem>
                                                </Select>
                                            </Col>

                                            <Col md={10} className="suitable-for-work">
                                                <Field name="suitable_for_work">
                                                    {({ field }) => 
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={field.value}
                                                                    onChange={field.onChange(field.name)}
                                                                    color="primary" />
                                                            }
                                                            label={AppContext.r["suitable-for-work"]} />
                                                    }
                                                </Field>
                                            </Col>
 
                                            {(values.suitable_for_work === 1 || values.suitable_for_work === true) && 
                                                <Col md={10}>
                                                    <label className="textarea-label">{AppContext.r["suitable-for-work-desc"]}</label>
                                                    
                                                    <Field name="suitable_for_work_desc">
                                                        {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                                    </Field>
                                                </Col> }
                                        </Row>

                                        <h3>{AppContext.r["contact-info"]}</h3>

                                        <Row>
                                            <Col>
                                                <Field className="field" component={TextField} name="email" type="text"
                                                    label={AppContext.r["email"]} />
                                            </Col>

                                            <Col>
                                                <Field className="field" component={TextField} name="phone" type="text"
                                                    label={AppContext.r["phone"]} />
                                            </Col>

                                            <Col>
                                                <Field className="field" component={TextField} name="website_url" type="text"
                                                    label={"Website"} />
                                            </Col>
                                        </Row>
                                            
                                        <h3>{AppContext.r["check-x-info"]}</h3>

                                        <Row className="justify-content-center check-x-inputs">
                                            <Col xs="6" md="3">
                                                <InputLabel id="checkin-label">{AppContext.r["checkin-from"]}</InputLabel>
                                                <Select labelId="checkin-label" className="check-x-picker"
                                                    name="checkin_at"
                                                    value={values.checkin_at.substr(0, 5)}
                                                    onChange={(event) => {
                                                        setFieldValue("checkin_at", event.target.value);
                                                    }}>
                                                    {checkinItems}
                                                </Select>
                                            </Col>

                                            <Col xs="6" md="3">
                                                <InputLabel id="checkout-label">{AppContext.r["checkout-to"]}</InputLabel>
                                                <Select labelId="checkout-label" className="check-x-picker" 
                                                    value={values.checkout_at.substr(0, 5)}
                                                    onChange={(event) => {
                                                        setFieldValue("checkout_at", event.target.value);
                                                    }}>
                                                    {checkoutItems}
                                                </Select>
                                            </Col>
                                        </Row>

                                        <h3>{AppContext.r["property-location"]}</h3>

                                        <Row className="justify-content-center">
                                            <Col md={4}>
                                                <Field className="field" component={TextField} name="address1" type="text"
                                                    label={AppContext.r["address"]} />
                                            </Col>
                                            
                                            <Col md={4}>
                                                <Field className="field" component={TextField} name="address2" type="text"
                                                    label={AppContext.r["address"]} />
                                            </Col>
                                        </Row>
                                            
                                        <Row className="justify-content-center">
                                            <Col md={2}>
                                                <Field className="field" component={TextField} name="post_code" type="text"
                                                    label={AppContext.r["post-code"]} />
                                            </Col>
                                            
                                            <Col md={4}>
                                                <Field className="field" component={TextField} name="city" type="text"
                                                    label={AppContext.r["city"]} />
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center">
                                            <Button type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["save"]}</Button>
                                        </Row>

                                        {isSubmitting && <><LinearProgress /></>}

                                        <Row className="justify-content-center">
                                            <Col className="tabs">
                                                <Paper className="paper">
                                                    <Tabs 
                                                        value={this.state.tabPage}
                                                        onChange={(e, value) => this.setState({tabPage: value})}
                                                        indicatorColor="primary" textColor="primary" centered>
                                                        <Tab label={AppContext.r["socials"]} />
                                                        {/* <Tab label={AppContext.r["video-streams"]} /> */}
                                                        <Tab label={AppContext.r["team-members"]} />
                                                    </Tabs>

                                                    <Col xs={12} className="tab">
                                                        {this.state.tabPage === 0 &&
                                                            <_Socials sociableType={"stores"} sociableId={values.id}
                                                                disableInstagram /> }
                                                        {/* {this.state.tabPage === 1 &&
                                                            <_Streams streamableType={"stores"} streamableId={values.id} /> } */}
                                                        {this.state.tabPage === 1 &&
                                                            <_TeamMembers storeId={values.id} /> }
                                                    </Col>
                                                </Paper>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                }

                {/* { store.licence_id === 1 && 
                    <Row>
                        <Col className="connect-with-stripe">
                            <h3>{AppContext.r["payments"]}</h3>

                            {stripeView}
                        </Col>
                    </Row> } */}
            </>
        );
    }
}

export default asWidget(StoreWidget);