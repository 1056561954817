import Axios from 'axios';

import AppContext from 'app/AppContext'
import CoreApi from './Core';

export default class Rafala {
    static async MyShops() {
        return await Axios.get(AppContext.s["api-url"] + "/myshops")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }

    static async History(warehouseId, itemId) {
        return await Axios.get(AppContext.s["api-url"] + "/warehouses/" + warehouseId + "/item/" + itemId + "/history")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async SupplyWarehouse(warehouseId, itemId, quantity) {
        return await Axios.post(AppContext.s["api-url"] + "/warehouses/"+warehouseId+"/supply", 
            { 
                item_id: itemId,
                quantity: quantity
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async TransferItem(fromWarehouseId, toWarehouseId, itemId, quantity) {
        return await Axios.post(AppContext.s["api-url"] + "/warehouses/transfer", 
            { 
                from_warehouse_id: fromWarehouseId,
                to_warehouse_id: toWarehouseId,
                item_id: itemId,
                quantity: quantity
            })
            .catch((error) => {
                console.log('error ' + error);
                return error.response;
            });
    }

    static async Warehouses() {
        const url = AppContext.s["api-url"] + "/warehouses/";
        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Warehouse(warehouse_id) {
        const url = AppContext.s["api-url"] + "/warehouses/" + warehouse_id;
        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Brands(shopId) {
        const url = AppContext.s["api-url"] + "/shopitems/brands?shopId=" + shopId;
        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
            });
    }
}