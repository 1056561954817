import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';

export default class Stay {
    static async MyShops() {
        return await Axios.get(AppContext.s["api-url"] + "/myshops")
            .catch((error) => {
                console.log('error ' + error);
            });
    }
    
    static async Categories() {
        return await Axios.get(AppContext.s["api-url"] + "/categories")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
    
    static async Trips(storeId, upcoming=1) {
        return await Axios.get(AppContext.s["api-url"] + "/stores/"+storeId+"/trips?orderByDesc=arriving_at&upcoming="+upcoming)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
}