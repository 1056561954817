import React from 'react';
import { Button } from '@material-ui/core';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import ToggleButton from "framework/ToggleButton";

let yup = require('yup');

export default class Service extends Module {
    enableInsert = false;
    enableDelete = false;
    onRowClick = null;

    info = Service.Info;
    
    static Info = {
        nameResource: "stripe-products",
        icon: "far fa-calendar",
        path: "/shop/services",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Service match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["stripe-product"];
    }

    get apiPath() {
        return '/services';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required(),
            description: yup.string(),
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["stripe-product-id"],
                "Field": "id",
            },{
                "Adapter": (o) => {
                    return <Button href={"https://dashboard.stripe.com/products/" + o.id} target="_blank">View in Stripe</Button>;
                }
            },{
                "Field": "published",
                "ClassName": "item-icons",
                "Adapter": (o) => {
                    return (
                        <div className="row-thumb">
                            {o.images[0] && <img src={o.images[0]} alt={o.name} /> }
                        </div>
                    );
                }
          },{
            "Title": AppContext.r["public"],
            "Field": "public",
            "Adapter": (o) => {
                const p = o.metadata.public;
                console.log(o)
                return <ToggleButton key={o.public} disabled
                    onToggleAsync={async () => await this.onTogglePublished(o)}
                    on={AppContext.r["yes"]} off={AppContext.r["no"]} toggled={!p || (p !== "false" && p !== "no")} />;
            }
          }
        ];
    }

    onTogglePublished = async (o) => {

    }
}