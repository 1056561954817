import React from 'react';
import {Tooltip} from '@material-ui/core';
import { QRCode } from 'react-qrcode-logo';
import { withModal } from './withModal';

import AppContext from 'app/AppContext';

class QrLink extends React.Component {
    qrSize = this.props.size ? this.props.size : 128;

    onClickQr = (e) => {
        e.stopPropagation();

        const shortUrl = 
            this.props.value
                .replace("https://www.", "")
                .replace("http://www.", "")
                .replace("https://", "")
                .replace("http://", "");
        
        this.props.modal.setView(" ", 
            <>
                <div className="qr-container">
                    <QRCode {...this.props} size={this.qrSize} 
                        logoWidth={this.qrSize * 0.4} />
                </div>

                <p><small>{shortUrl}</small></p>
            </>, true, "qr-link-modal");
    }

    onClickUrl = () => {
        window.open(this.props.value);
    }

    render() {
        return (
            <div className="qr-link" onClick={this.onClickUrl}>
                <span>{this.props.value}</span>
        
                <Tooltip title={AppContext.r["click-to-view-qr"]}>
                    <div className="qr-container" onClick={this.onClickQr}>
                        <QRCode {...this.props} size={this.qrSize} 
                            logoWidth={this.qrSize * 0.4} />
                    </div>
                </Tooltip>
            </div>
        );
    }
}

export default withModal(QrLink);