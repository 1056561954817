export default function loadClientResources (resources) {
    resources["shop-not-active"] = "Your shop has not been activated yet.";
    resources["shop-disabled"] = "Your shop has been disabled.";
    resources["no-role"] = "There are no services associated to this account.";
    resources["active"] = "Active";
    resources["note"] = "Note";
    resources["service"] = "Service";
    resources["services"] = "Services";
    resources["stripe-product"] = "Stripe Product";
    resources["stripe-products"] = "Stripe Products";
    resources["stripe-product-id"] = "Product Id";
    resources["view-in-stripe"] = "View in Stripe";
    resources["accountancy"] = "Accountancy";
    resources["expense"] = "Expense";
    resources["expenses"] = "Expenses";
    resources["invoice"] = "Invoice";
    resources["invoices"] = "Invoices";
    resources["media-stock"] = "Media Stock";
    resources["create-store-first"] = "Create the store, first.";
    resources["category"] = "Category";
    resources["categories"] = "Categories";
    resources["rating"] = "Rating";
    resources["ratings"] = "Ratings";
    resources["channel"] = "Channel";
    resources["channels"] = "Channels";
}