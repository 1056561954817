import React, { useContext } from "react";
// import {
//   Dashboard
// } from "../../_metronic/_partials";

import {SubheaderContext} from "../../_metronic/layout/_core/MetronicSubheader";

import AppContext from 'app/AppContext';
import ProjectIdsEnum from 'app/settings/ProjectIdsEnum';

import DashboardUderly from "../dashboards/Uderly";
import DashboardMenuPro from "../dashboards/MenuPro/Dashboard";
import DashboardIqSpace from "../dashboards/IqSpace/Dashboard";
import DashboardQuartarone from "../dashboards/Quartarone/Dashboard";
import DashboardWoolSafe from "../dashboards/WoolSafe/Dashboard";
import DashboardStay from "../dashboards/Stay/Dashboard";
import DashboardVaffa from "../dashboards/Vaffa";
import DashboardRafala from "../dashboards/Rafala/Dashboard";

export function DashboardPage() {
    // Reset Sub Header Actions
    const context = useContext(SubheaderContext);
    context.setActions("");

    if(AppContext.s["project-id"] === ProjectIdsEnum.Uderly)
        return <DashboardUderly />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.UderlyPro)
        return <DashboardMenuPro />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.QrtService)
        return <DashboardQuartarone />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.IqSpace)
        return <DashboardIqSpace />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.WoolSafe)
        return <DashboardWoolSafe />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.Stay)
        return <DashboardStay />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.Vaffa)
        return <DashboardVaffa />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.Rafala)
        return <DashboardRafala />;

    return "No valid dashboard found";
}