import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';

export default class QrtService {
    static async MyProviders() {
        return await Axios.get(AppContext.s["api-url"] + "/myproviders")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }

    static async Services(categoryId) {
        return await Axios.get(AppContext.s["api-url"] + "/categories/" + categoryId + "/services")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }

    static async Categories() {
        return await Axios.get(AppContext.s["api-url"] + "/categories")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }

    static async Stores(providerId) {
        const url = AppContext.s["api-url"] + (providerId ? ("/providers/" + providerId + "/stores") : "/stores");
        
        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }

    static async Orders(providerId, query = "") {
        const url = AppContext.s["api-url"] + (providerId ? ("/providers/" + providerId + "/orders"+query) : "/stores");
        
        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
}