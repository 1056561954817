import AppContext from './AppContext';
import ProjectIdsEnum from './settings/ProjectIdsEnum';

// Core
import Admin from "./pages/Core/Admin";
import AssetType from "./pages/AssetType";
import User from "./pages/Core/User";
import Role from "./pages/Core/Role";
import Permission from "./pages/Core/Permission";
import Log from "./pages/Core/Log";
import SocialType from "./pages/Core/SocialType";
import Touch from "./pages/Core/Touch";

import Tables from "./pages/QrtService/Tables";

// Uderly
import UderlyStore from "./pages/Uderly/Store";
import UderlyAccountancy from "./pages/Uderly/Accountancy";
import UderlyService from "./pages/Uderly/Service";
import UderlyExpense from "./pages/Uderly/Expense";
import UderlyInvoice from "./pages/Uderly/Invoice";
import UderlyMediaStock from "./pages/Uderly/MediaStock";
import MediaStockCategories from "./pages/Uderly/MediaStockCategory";
import MediaStockRatings from "./pages/Uderly/MediaStockRating";
import MediaStockChannels from "./pages/Uderly/MediaStockChannel";

// // UderlyPro
// import Shop from "./pages/menupro/Shop";
// import MenuItem from "./pages/menupro/MenuItem";
// import MenuItemCategory from "./pages/menupro/MenuItemCategory";
// import MenuItemExtra from "./pages/menupro/MenuItemExtra";
// import ShopUser from "./pages/menupro/ShopUser";
// import ShopOrder from "./pages/menupro/ShopOrder";
// // import Service from "./pages/menupro/Service";

// // Iq Space
// import IqSpaceShop from "./pages/IqSpace/Shop";
// import Event from "./pages/IqSpace/Event";
// import Package from "./pages/IqSpace/Package";
// import Category from "./pages/IqSpace/Category";
// import Game from "./pages/IqSpace/Game";
// import Playlist from "./pages/IqSpace/Playlist";
// import Follower from "./pages/IqSpace/Follower";

// // QrtService
// // import QrtCategory from "./pages/QrtService/ServiceCategory";
// // import QrtRecipientRegistry from "./pages/QrtService/RecipientRegistry";
// // import QrtBusinessType from "./pages/QrtService/RegistryType";
// // import QrtRegistryType from "./pages/QrtService/BusinessType";
// // import QrtAnomaly from "./pages/QrtService/Anomaly";
// import QrtCategory from "./pages/QrtService/Category";
// import QrtService from "./pages/QrtService/Service";
// import QrtProvider from "./pages/QrtService/Provider";
// import QrtStore from "./pages/QrtService/Store";
// import QrtOrders from "./pages/QrtService/Order";
// import QrtOrderTypes from "./pages/QrtService/OrderType";
// import QrtOrderStatuses from "./pages/QrtService/OrderStatus";
// import QrtPriceListItem from "./pages/QrtService/PriceListItem";
// import QrtProviderStatuses from "./pages/QrtService/ProviderStatus";

// WoolSafe
import WoolSafeStore from "./pages/WoolSafe/Store";
import WoolSafeEvent from "./pages/WoolSafe/Event";
import WoolSafeCategory from "./pages/WoolSafe/Category";
import WoolSafeReport from "./pages/WoolSafe/Report";
import WoolSafeEventType from "./pages/WoolSafe/EventType";

// Stay
import StayStore from "./pages/Stay/Store";
import StayCategory from "./pages/Stay/Category";
import StayTrip from "./pages/Stay/Trip";
import StayMessage from "./pages/Stay/Message";

// Vaffa
import Career from "./pages/Vaffa/Career";

// Rafalà
import ShopItem from "./pages/Rafala/ShopItem";
import EnotecaItem from "./pages/Rafala/EnotecaItem";
import ShopItemCategory from "./pages/Rafala/ShopItemCategory";
import ShopItemEnotecaCategory from "./pages/Rafala/ShopItemEnotecaCategory";
import ShopItemBrand from "./pages/Rafala/ShopItemBrand";
import ShopItemEnotecaBrand from "./pages/Rafala/ShopItemEnotecaBrand";
import RafalaShopOrder from "./pages/Rafala/ShopOrder";
import RafalaCustomer from "./pages/Rafala/Customer";
import RafalaWarehouse from "./pages/Rafala/Warehouse";
import InternalOrder from "./pages/Rafala/InternalOrder";
import Stock from "./pages/Rafala/Stock";
import EnotecaOrder from "./pages/Rafala/EnotecaOrder";

const ModulesMap = [];

// Core Modules
ModulesMap["Admin"] = Admin;
// ModulesMap["Assets"] = Asset;
ModulesMap["AssetTypes"] = AssetType;
ModulesMap["Users"] = User;
ModulesMap["Roles"] = Role;
ModulesMap["Permissions"] = Permission;
ModulesMap["Logs"] = Log;
ModulesMap["Touches"] = Touch;

// if(AppContext.s["project-id"] === ProjectIdsEnum.QrtService) {
//     // ModulesMap["ServiceCategories"] = QrtCategory;
//     // ModulesMap["Registries"] = QrtRecipientRegistry;
//     // ModulesMap["BusinessTypes"] = QrtBusinessType;
//     // ModulesMap["RegistryTypes"] = QrtRegistryType;
//     // ModulesMap["Anomalies"] = QrtAnomaly;
    
//     ModulesMap["Tables"] = Tables;
//     ModulesMap["SocialTypes"] = SocialType;

//     ModulesMap["Categories"] = QrtCategory;
//     ModulesMap["Services"] = QrtService;
//     ModulesMap["Providers"] = QrtProvider;
//     ModulesMap["Stores"] = QrtStore;
//     ModulesMap["OrderTypes"] = QrtOrderTypes;
//     ModulesMap["OrderStatuses"] = QrtOrderStatuses;
//     ModulesMap["Orders"] = QrtOrders;
//     ModulesMap["PriceListItems"] = QrtPriceListItem;
//     ModulesMap["ProviderStatuses"] = QrtProviderStatuses;
// }

if(AppContext.s["project-id"] === ProjectIdsEnum.Uderly) {
    ModulesMap["Accountancy"] = UderlyAccountancy;
    ModulesMap["Stores"] = UderlyStore;
    ModulesMap["Services"] = UderlyService;
    ModulesMap["Expenses"] = UderlyExpense;
    ModulesMap["Invoices"] = UderlyInvoice;
    ModulesMap["MediaStock"] = UderlyMediaStock;
    ModulesMap["MediaStockCategories"] = MediaStockCategories;
    ModulesMap["MediaStockRatings"] = MediaStockRatings;
    ModulesMap["MediaStockChannels"] = MediaStockChannels;
}

// if(AppContext.s["project-id"] === ProjectIdsEnum.UderlyPro) {
//     // MenuPro
//     ModulesMap["Shops"] = Shop;
//     ModulesMap["MenuItems"] = MenuItem;
//     ModulesMap["ShopCategories"] = MenuItemCategory;
//     ModulesMap["Extras"] = MenuItemExtra;
//     ModulesMap["ShopOrders"] = ShopOrder;
//     ModulesMap["ShopUsers"] = ShopUser;
// }

// if(AppContext.s["project-id"] === ProjectIdsEnum.IqSpace) {
//     ModulesMap["Shops"] = IqSpaceShop;
    
//     // IqSpace
//     ModulesMap["Events"] = Event;
//     ModulesMap["Packages"] = Package;
//     ModulesMap["Categories"] = Category;
//     ModulesMap["Games"] = Game;
//     ModulesMap["Playlists"] = Playlist;
//     ModulesMap["Followers"] = Follower;
// }

if(AppContext.s["project-id"] === ProjectIdsEnum.WoolSafe) {
    ModulesMap["Tables"] = Tables;
    ModulesMap["SocialTypes"] = SocialType;
    ModulesMap["EventTypes"] = WoolSafeEventType;
    
    ModulesMap["Stores"] = WoolSafeStore;
    ModulesMap["Events"] = WoolSafeEvent;
    ModulesMap["Categories"] = WoolSafeCategory;
    ModulesMap["Reports"] = WoolSafeReport;
}

if(AppContext.s["project-id"] === ProjectIdsEnum.Stay) {
    ModulesMap["Tables"] = Tables;
    ModulesMap["SocialTypes"] = SocialType;
    
    ModulesMap["Stores"] = StayStore;
    ModulesMap["Categories"] = StayCategory;
    ModulesMap["Trips"] = StayTrip;
    ModulesMap["Messages"] = StayMessage;
}

if(AppContext.s["project-id"] === ProjectIdsEnum.Vaffa) {
    ModulesMap["SocialTypes"] = SocialType;
    ModulesMap["Careers"] = Career;
}

if(AppContext.s["project-id"] === ProjectIdsEnum.Rafala) {
    ModulesMap["ShopItems"] = ShopItem;
    ModulesMap["ShopItemCategories"] = ShopItemCategory;
    ModulesMap["ShopItemBrands"] = ShopItemBrand;
    ModulesMap["ShopOrders"] = RafalaShopOrder;
    ModulesMap["EnotecaItems"] = EnotecaItem;
    ModulesMap["ShopItemEnotecaCategories"] = ShopItemEnotecaCategory;
    ModulesMap["ShopItemEnotecaBrands"] = ShopItemEnotecaBrand;
    ModulesMap["EnotecaOrders"] = EnotecaOrder;
    ModulesMap["RafalaWarehouses"] = RafalaWarehouse;
    ModulesMap["RafalaCustomers"] = RafalaCustomer;
    ModulesMap["Stocks"] = Stock;
    ModulesMap["InternalOrders"] = InternalOrder;
}

export default ModulesMap;