import React from 'react';
import { Field, FieldArray } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';
import { format } from 'date-fns'
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import CoreApi from 'api/Core';

export default class ShopOrder extends Module {
    info = ShopOrder.Info;

    showPagination = false; // TODO: It should be enabled

    enableInsert = false;
    enableDelete = false;

    state = {
        ...this.state,
        totalAmount: 0
    }

    get pathname () {
        return "/orders";
    }
    
    static Info = {
        nameResource: "orders",
        icon: "fas fa-sort-amount-down",
        path: "/orders",
        show: true,
        cookieName: "shop-orders"
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopOrder match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["orders"];
    }

    onFetchedItem = (item) => {
        if(item) {
            this.setState({
                totalAmount: this.getTotalAmount(item)
            })
        }
    }

    _shopId = 1;
    set shopId (value) { this._shopId = value; }
    get shopId () { 
        return this._shopId;
    }

    get apiPath() {
        // console.log('/shop/'+this.shopId+'/orders')
        return '/shop/'+this.shopId+'/orders';
    }

    get itemApiPath() {
        return '/shop/orders';
    }

    get updateApiPath() {
        return '/shop/orders';
    }

    get schema () {
        return yup.object({
            note: yup.string()
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id"
          },{
            "Title": AppContext.r["type"],
            "Adapter": function(o) {
                return AppContext.r["order-type"][o.shop_order_type_id];
            }
          },{
            "Title": AppContext.r["name"],
            "Field": "name",
            "Adapter": function(o) {
                return (o.user === null) ? "" : o.user.name + " " + ((o.user.surname !== null) ? o.user.surname : "");
            }
          },{
            "Title": AppContext.r["placed-at"],
            "Adapter": (o) => format(new Date(o.created_at), 'd/MM/yyyy h:mm')
          },
          {
            "Title": AppContext.r["note"],
            "Field": "note"
          },{
            "Title": AppContext.r["status"],
            "Field": "status",
            "Adapter": function(o) {
                return (o.status === null) ? "" : o.status.title;
            }
          }
        ];
    }

    get initialValues() {
        return {
            created_at: "",
            requested_date: "",
            note: "",
            status: null,
        };
    }

    // static updateInterval = 3000; // ms

    // interval = null;

    async beforeComponentDidMount() {
        // const response = await Axios.get(Utils.s["api-url"] + "/shops", { headers: { Authorization: Auth.bearer } })
        //     .catch((error) => {
        //         console.log('error ' + error);
        //     });

        // if(response.data.data.length > 0)
        //     this.shopId = response.data.data[0].id;

        // Fetch statutes
        this.orderStatusOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shoporders/statuses", "id");
        //this.categoryOptions = await CoreApi.fetchAsync(Utils.s["api-url"] + "/shop/"+this.shopId+"/categories", "id");

        // if(!this.interval)
        //     this.interval = setInterval(() => {
        //         if(window.location.pathname === "/orders" && this.state.item === null)
        //             this.fetchRecords();
        //     }, ShopOrder.updateInterval);
    }
    
    componentWillUnmount() {
        // if(this.interval)
        //     clearInterval(this.interval);
        // this.interval = null;
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    extras(extras) {
        if(extras) {
            //console.log(extras);
            const m = extras.map(x => <span className="extra" key={x.id}>{x.name}</span>)
    
            return (
                <div className="extras">
                    { m }
                </div>);
        }
        
        return "";
    }

    formatAddress(c) {
        let a = c.address1;
        if(c.address2 && c.address2.length > 0) a += ", " + c.address2;
        a += " - " + c.post_code;
        a += " - " + c.city;
        return a;
    }

    getTotalAmount = (item = null) => {
        if(!item) item = this.state.item;
        let totalAmount = 0;

        for(let v of item.items)
            totalAmount += parseFloat(v.pivot.price) * parseFloat(v.pivot.quantity);

        return totalAmount;
    }

    get mask () {
        const { item } = this.state;

        const u = item.user;
        const name = (<p>{u.name + " " + u.surname + " (User ID: " + u.id + ")"}</p>);
        const email = u.email.length > 0 ? (<span className="mail"><i className="far fa-envelope"></i> <a href={"mailto:"+u.email}>{u.email}</a></span>) : "";
        const mobile = (u.mobile_phone && u.mobile_phone.length > 0) ? (<span><i className="fas fa-phone"></i> <a href={"tel:"+u.mobile_phone}>{u.mobile_phone}</a></span>) : "";
        const note = item && item.note && item.note.length > 0 ? (<p className="note">{item.note}</p>) : "";
        const orderType = (item && item.type) ? AppContext.r["order-type"][item.type.id]  : "";
        const paymentType = (item && item.payment_type) ? item.payment_type.title : "";
        
        const { contact } = this.state.item;
        
        const contactView = (!contact) ? "" : (
            <div className="order-contact">
                <h3>{contact.alias}</h3>
                {this.formatAddress(contact)}
                <hr />
            </div>
        );

        const collectionShopLabel = (id) => {
            if(id === 2) {
                return <span className="collection-shop-label enoteca enoteca-background-color">Enoteca</span>;
            }
            return <span className="collection-shop-label rafalauk-background-color">Rafala UK</span>;
        }

        return ({ handleSubmit, handleChange, values, errors }) => (
            <Form onSubmit={handleSubmit} className="order" autoComplete="off">
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["status"]}</Form.Label>

                        <RemoteSelect name="shop_order_status_id" key={values.shop_order_status_id} value={values.shop_order_status_id} onChange={handleChange}
                                        nullOption={false} options={this.orderStatusOptions} fieldToMap="title" />
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["placed-at"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["placed-at"]} name="created_at" disabled
                                    value={format(new Date(values.created_at), 'd/MM/yyyy h:mm')} onChange={handleChange} />
                    </Form.Group>
                </Form.Row>
                
                <Form.Row>
                    <Form.Group as={Col} xs="12" className="order-user">
                        <h2>{AppContext.r["user"]}</h2>
                        {name}
                        {email}
                        {mobile}
                    </Form.Group>

                    <Form.Group as={Col} xs="12" className="order-contact">
                        {contactView}
                    </Form.Group>
                </Form.Row>
                
                <Form.Row>
                    <Form.Group as={Col} xs="12">
                    <h2>{AppContext.r["order-details"]}</h2>
                    </Form.Group>

                    <Form.Group as={Col} xs="6">
                        {/* <p>Order Type: <b>{orderType}</b>{(item.type && item.type.id === 3) && ((item.collection_shop_id === 2) ? " @ Enoteca" : " @ Rafala UK")}</p> */}
                        <p>Order Type: <b>{orderType}</b>{(item.type && item.type.id === 3) && collectionShopLabel(item.collection_shop_id)}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="6">
                        <p>Payment Type: <b>{paymentType}</b></p>
                    </Form.Group>
                    
                    {note}

                    {/* Items */}

                    <Form.Group as={Col} xs="12">
                        <FieldArray name="items"
                            render={arrayHelpers => (
                                <div className="items">
                                    {console.log(values)}
                                    {values.items && values.items.length > 0 ? (
                                        values.items.map((v, index) => (
                                            <div key={index} className="list-item">
                                                <Row>
                                                    <Col sm={9}>
                                                        {/* <Link to={"/shop/items/"+v.id}></Link> */}
                                                        {v.product_code && v.product_code + " - "}
                                                        {values.items[index].pivot.quantity} x <b>{values.items[index].name}</b>
                                                        &nbsp;
                                                        {v.brand && <span style={{color: "#aa0000"}}>{v.brand.name} </span>}
                                                        (Pack of {v.package_quantity} x {v.package_size})
                                                    </Col>

                                                    <Col sm={3}>
                                                        <div className="price">
                                                            <label htmlFor={`items.${index}.pivot.price`}>{AppContext.r['price']}</label>
                                                            
                                                            <Field
                                                                name={`items.${index}.pivot.price`}
                                                                placeholder="0"
                                                                type="text" 
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    // EDIT Disabled

                                                                    // const { value } = e.target;
                                                                    // const regex = /^(0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                                    // if (regex.test(value.toString())) {
                                                                    //     setFieldValue(`items.${index}.pivot.price`, value);

                                                                    //     v.pivot.price = value;

                                                                    //     this.setState({
                                                                    //         totalAmount: this.getTotalAmount(values)
                                                                    //     });
                                                                    // }
                                                                }} />
                                                            {/* {errors.items && errors.items[index] && errors.items[index].pivot.price && touched.items && touched.items[index].pivot.price && (
                                                                <div className="field-error">
                                                                    {errors.items[index].pivot.price}
                                                                </div>
                                                            )} */}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {/* <span className="quantity">{values.items[index].pivot.quantity}</span>
                                                <span className="x"> x </span>
                                                <span className="name">{values.items[index].name}</span> */}

                                                {this.extras(values.items[index].pivot.extra_objects)}

                                                <span className="note">{values.items[index].pivot.note}</span>

                                                <hr />
                                            </div>
                                        ))
                                    ) : ("none") }
                                    <div>
                                    </div>

                                    { typeof errors.items === 'string' ? <div>{errors.items}</div> : null }
                                </div>
                            )}
                        />

                        <p className="total">£ {this.state.totalAmount}</p>

                        <p>Delivery Fee: <b>£ {this.state.item.delivery_fee}</b></p>
                    </Form.Group>
                </Form.Row>

                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["order-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}  
            </Form>
        );
    }
}
