import React from 'react';
import Cookie from 'react-cookies';
import { Button, Row, Col, Container, Tabs, Tab } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { LinearProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Button as MuiButton } from '@material-ui/core';
import { arrayRemove } from 'react-movable';
import { format } from 'date-fns'
import * as yup from 'yup';

// import asDashboardWidget from 'framework/asDashboardWidget.js';
import AppContext from 'app/AppContext';
import CoreApi from 'api/Core.js';
import TriviaProApi from 'api/TriviaPro';
import GameStatusEnum from 'api/QuizStatusEnum.js';
import RemoteSelect from 'framework/RemoteSelect';
import JsonToTable from 'framework/JsonToTable';
import ActionButton from 'framework/ActionButton';
import Countdown from 'framework/Countdown';
import { withModal } from 'framework/withModal.js';
import PlaylistQuestions from '../../pages/IqSpace/_PlaylistQuestions';
import { indexToLetter } from '../../pages/IqSpace/_PackageQuestions.js';
import { ShareEvent } from '../../pages/IqSpace/Event.js';

class QuizConsole extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "event": this.props.event,
            "gameStatus": null,
            "playlist": null,
            "playlistId": null
        }

        this.jPlayersHead = [
            {
                "Title": AppContext.r["name"],
                "Field": "Name"
            },{
                "Title": AppContext.r["last-answer"],
                "Field": "LastAnswer",
                "Adapter": function(o) {
                    return indexToLetter(o.LastAnswer);
                }
            },{
                "Title": AppContext.r["last-time"],
                "Field": "LastTime"
            },{
                "Title": AppContext.r["last-score"],
                "Field": "LastScore"
            },{
                "Title": AppContext.r["score"],
                "Field": "Score"
            },{
                "Adapter": (o, index) => {
                    return (
                        <div>
                            {/* <Button variant="link" className="warning" onClick={() => { t.wavePlayer(o, index) }}>
                                <i className="fas fa-hand-paper"></i> {AppContext.r['wave']}
                            </Button> */}
                            <Button variant="link" className="danger" onClick={() => { this.confirmDeletePlayer(o, index) }}>
                                <i className="fas fa-trash-alt"></i> {AppContext.r['delete']}
                            </Button>
                        </div>
                        );
                }
            }
        ];
    }

    // componentWillReceiveProps(nextProps) {
    //         console.log(nextProps.event)
    //         if (nextProps.event !== this.props.event) {
    //         this.setState({
    //             event: nextProps.event
    //         })
    //     }
    // }

    wavePlayer = async (o, index) => {
        const { event } = this.state;

        const response = await TriviaProApi.WavePlayer(event.id, o.Id);

        if(response && response.status === 200) {
        }

        this.props.modal.hide();
    }

    notify = async (values) => {
        console.log(values);
        const { event } = this.state;

        const response = await TriviaProApi.Notify(event.id, values.message, values.title, values.severity, values.duration);

        if(response && response.status === 200) {
        }

        this.props.modal.hide();
    }

    confirmResetScores = () => {
        this.props.modal.confirm(AppContext.r["reset-scores"], AppContext.r["reset-scores-confirm"], this.resetScores);
    }
    
    confirmDeletePlayer = (o, index) => {
        this.props.modal.confirm(AppContext.r["delete-player"], AppContext.r["delete-player-confirm"], () => this.deletePlayer(o));
    }

    resetScores = async () => {
        const { event } = this.state;

        const response = await TriviaProApi.SetPlayerScore(event.id, -1, 0, true);

        if(response && response.status === 200) {
        }

        this.props.modal.hide();
    }
    
    deletePlayer = async (o) => {
        const { event } = this.state;

        const response = await TriviaProApi.DeletePlayer(event.id, o.Id);

        if(response && response.status === 200) {
        }

        this.props.modal.hide();
    }

    fetchPlaylists = async () => {
        const playlistOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/playlists");
        const notResolvedPlaylist = (playlistOptions && playlistOptions.length > 0) ? playlistOptions[0] : null;
        const playlistId = (notResolvedPlaylist) ? notResolvedPlaylist.Id : -1;

        this.setState({
            playlist: notResolvedPlaylist,
            playlistId: playlistId,
            playlistOptions: playlistOptions,
        });

        this.onPlaylistChanged(null, playlistId);
    }

    async componentDidMount() {
        // const eventsToday = await TriviaProApi.Events(0);
        // const events = eventsToday.data.data.filter(e => e.published);
        // const event = (events.length > 0) ? (await TriviaProApi.Event(events[0].id)).data.data : "";

        const playlistOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/playlists");
        const notResolvedPlaylist = (playlistOptions && playlistOptions.length > 0) ? playlistOptions[0] : null;

        this.setState({
            playlist: notResolvedPlaylist,
            playlistId: (notResolvedPlaylist) ? notResolvedPlaylist.Id : -1,
            playlistOptions: playlistOptions,
            // event: event
        });

        // Connect to the hub
        if(this.state.event)
            window.start(this);
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus.bind(this));
    }

    onFocus() {
         if (this.eventId && this.cachedPlayer && this.state.user
                && window.squizzerHub.State >= 1)
            window.squizzerHub.subscribe(this.eventId, this.cachedPlayer, this.state.user.id);
    }

    onConnecting() {
        this.props.setOverlayMessage(AppContext.r['rts-connecting']);
    }

    onConnected() {
        this.props.setOverlayMessage(AppContext.r['rts-connected']);

        const { event } = this.state;

        // TODO: Event can still be NULL
        
        if(event.id) {
            const cachedPlayer = Cookie.load(AppContext.s['quiz-cookie-name']);
            //console.log(cachedPlayer);

            if(cachedPlayer && cachedPlayer.EventId === event.id && this.state.user)
                window.squizzerHub.subscribe(event.id, cachedPlayer, this.state.user.id);
            else
                window.squizzerHub.subscribe(event.id);
        }
    }

    onDisconnected() {
        this.props.setOverlayMessage(AppContext.r['rts-disconnected']);
    }

    onConnectionToHubError() {
        this.props.setOverlayMessage(AppContext.r['rts-couldnt-connect']);
    }

    onSubscriptionFailed(error) {
        this.props.setOverlayMessage(AppContext.r['rts-subscription-failed'] + JSON.stringify(error));
    }

    onSubscribed(result) {
        // console.log("onsubscribed", result);
        const messages = [];

        // Retrieve sent chat messages
        if(result["ChatMessages"]) {
            for(var m of result["ChatMessages"]) {
                if(m.ToPlayerId === -1)
                    messages.push({
                        id: m.PlayerId,
                        name: m.Name,
                        message: m.Message,
                        userId: m.UserId
                    });
            }
        }

        if(result && !result.User && this.state.player)
            this.quitGame();

        const { playlist } = this.state;
        const { playlistOptions } = this.state;

        if(playlist && !playlist.questions) {
            // By default, resolve first owned playlist in the list
            let selectedPlaylistId = playlist.id;
            let questionId = -1;

            // Look for an owned remotely selected playlist
            if(result.GameStatus) {
                for(var p of playlistOptions) {
                    if(p.id === result.GameStatus.PlaylistId) {
                        selectedPlaylistId = result.GameStatus.PlaylistId;
                        break;
                    }
                }

                if(result.GameStatus.Question)
                    questionId = result.GameStatus.Question.QuestionId;
            }

            this.fetchPlaylist(selectedPlaylistId, selectedPlaylistId, questionId);
        }

        // Subscribed to the hub

        this.onGameStatusChanged(result.GameStatus);

        this.props.setOverlayMessage("");

        this.setState({
            subscribed: true,
            player: (result.GameStatus && result.GameStatus.MyPlayer) ? result.GameStatus.MyPlayer : null,
            broadcastChatMessages: messages,
        });

        // Scroll chat to the bottom
        const el = document.getElementById("chat");
        if(el)
            el.scrollTop = el.scrollHeight;
    }
    
    onGameStatusChanged(gameStatus) {
        console.log("onGameStatusChanged", gameStatus);
        const playlist = JSON.parse(JSON.stringify(this.state.playlist));

        if(gameStatus && gameStatus.Question)
            this.highlightSelectedQuestion(playlist, gameStatus.PlaylistId, gameStatus.Question.QuestionId);

        this.setState({
            gameStatus: gameStatus,
            playlist: playlist
        });
    }

    deleteGame = async () => {
        const { event } = this.state;

        const response = await TriviaProApi.DeleteGame(event.id);

        if(response && response.status === 200) {
            this.setState({
                gameStatus: null
            });

            window.squizzerHub.subscribe(event.id);
        }

        if(this.props.onGameDeleted)
            this.props.onGameDeleted();

        this.props.modal.hide();
    }

    confirmDeleteGame = () => {
        this.props.modal.confirm(AppContext.r["cancel-game"], AppContext.r["cancel-game-confirm"], this.deleteGame);
    }

    forward = async (eventId) => {
        const {playlist} = this.state; // Selected Playlist

        let questionId = -1;
        let playlistId = null; // sending null won't change the selected playlist

        const { gameStatus } = this.state;
        
        const question = gameStatus.Question ? gameStatus.Question : null; // Currently selected question

        if(question && gameStatus.GameStatus === GameStatusEnum.Video) {
            questionId = question.QuestionId;
        } else if(!question || playlist.id !== gameStatus.PlaylistId) {
            // Play the first question in the playlist
            if(playlist.questions && playlist.questions.length > 0)
                questionId = playlist.questions[0].id;
        } else if(question) {
            // Find currently playing question in currently selected playlist
            for(var i = 0; i < playlist.questions.length; i++) {
                const q = playlist.questions[i];
                
                if(q.id === gameStatus.Question.QuestionId)
                    break;
            }

            const index = playlist.questions.length > (i+1) ? i+1 : 0;

            questionId = playlist.questions[index].id;
        }

        playlistId = playlist.id;

        await TriviaProApi.Forward(eventId, questionId, playlistId);
    }

    onRemovedFromPlaylist = (o, index) => {
        console.log(o, index);

        const playlist = this.state.playlist;
        playlist.questions = arrayRemove(playlist.questions, index);

        this.setState({
            playlist: playlist
        });
    }

    onReordered = (playlist) => {
        // console.log(playlist);
    }

    quickCreatePlaylist = () => {
        this.props.modal.input(AppContext.r["create-playlist"], "", 
            async (t) => { 
                const response = await CoreApi.createItem(AppContext.s["api-url"] + "/playlists", { name: t });

                if(response && response.status === 201) {
                    console.log(response)
                    const playlist = (response.data.data);

                    await this.fetchPlaylists();

                    playlist["questions"] = [];
                            
                    this.setState({
                        playlist: playlist,
                        playlistId: playlist.id,
                    });
                }
                
                this.props.modal.hide();
            });
    }

    get console () {
        const { gameStatus, playlistOptions, event, playlist } = this.state;
        const isPremium = event.store.premium_id === 1;

        const tabsView = (
            <Row>
                <Col md={12}>
                    <Tabs defaultActiveKey="playlists">
                        <Tab eventKey="playlists" title={AppContext.r["playlists"]}>
                            <div>
                                {/* key={values.asset_type_id} value={values.asset_type_id} onChange={handleChange} */}
                                {(playlistOptions) &&
                                    playlistOptions.length > 0 ?
                                        <RemoteSelect name="playlist_id" className="playlists-select" 
                                            nullOption={false} options={playlistOptions} fieldToMap="name"
                                            value={this.state.playlistId} onChange={this.onPlaylistChanged} />
                                        : <div className="no-playlists">
                                            <p>{AppContext.r["no-playlists"]}</p>
                                            <Button variant="link" onClick={this.quickCreatePlaylist}>{AppContext.r["create-playlist"]}</Button>
                                        </div> }

                                {gameStatus && playlist &&
                                    <PlaylistQuestions playlist={playlist} allowPlayback eventId={this.state.event.id}
                                        onRemovedFromPlaylist={this.onRemovedFromPlaylist} onReordered={this.onReordered} /> }
                            </div>
                        </Tab>

                        <Tab eventKey="players" title={AppContext.r["players"]}>
                            {gameStatus && 
                                <div className="players">
                                    <div className="head">
                                        <span>{<><b>{gameStatus.Players.length}</b> {" " + AppContext.r["players"] + " | "}</>} {AppContext.r["max-players-count"]} <b>{event.max_attendants_count}</b></span>
                                        
                                        {(gameStatus.Players && gameStatus.Players.length > 0) ?
                                            <Button variant="link" className="warning" onClick={() => { this.confirmResetScores() }}>
                                                <i className="fas fa-trash-alt"></i> {AppContext.r["reset-scores"]}
                                            </Button> : <br />}
                                    </div>

                                    <JsonToTable 
                                        head={this.jPlayersHead} body={gameStatus.Players} />
                                </div>
                            }
                        </Tab>
                    
                        <Tab eventKey="notify" title={AppContext.r["notifications"]}>
                            <div className="notifications-tab">
                                <Row>
                                    <Col md={12}>
                                        <h3>{AppContext.r["notifications-desc"]}</h3>

                                        {!isPremium &&
                                            <p><small>{AppContext.r["only-premium-feature"]}</small></p>}
                                    </Col>
                                </Row>

                                <Formik 
                                    initialValues={{ message: "", title: "", severity: "info", duration: 5000 }}
                                    validationSchema={ yup.object({ message: yup.string().required(AppContext.r["required-field"]) }) }
                                    onSubmit={(values) => this.notify(values)}>

                                    {({ isSubmitting, values }) => (
                                        <Form className={"notifications-form " + (isPremium ? "" : "disabled-form")}>
                                            <Row>
                                                <Col md={6}>
                                                    <Field className="field" component={TextField} type="text" variant="standard" margin="normal"
                                                        value={values.title} name="title" label={AppContext.r["title"]} />
                                                </Col>

                                                <Col md={3}>
                                                    <Field className="field" component={TextField} type="text" select variant="standard" margin="normal"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={values.severity} name="severity" label={AppContext.r["type"]}>
                                                            <MenuItem value={"info"}>{AppContext.r["info"]}</MenuItem>
                                                            <MenuItem value={"success"}>{AppContext.r["success"]}</MenuItem>
                                                            <MenuItem value={"warning"}>{AppContext.r["warning"]}</MenuItem>
                                                            <MenuItem value={"error"}>{AppContext.r["error"]}</MenuItem>
                                                    </Field>
                                                </Col>

                                                <Col md={3}>
                                                    <Field className="field" component={TextField} type="text" select variant="standard" margin="normal"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={values.duration} name="duration" label={AppContext.r["duration"]}>
                                                            <MenuItem value={3000}>3s</MenuItem>
                                                            <MenuItem value={5000}>5s</MenuItem>
                                                            <MenuItem value={8000}>8s</MenuItem>
                                                            <MenuItem value={10000}>10s</MenuItem>
                                                            <MenuItem value={15000}>15s</MenuItem>
                                                            <MenuItem value={20000}>20s</MenuItem>
                                                    </Field>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Field className="field" component={TextField} type="text" multiline variant="standard" margin="normal"
                                                        value={values.message} name="message" label={AppContext.r["message"]} />
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-center">
                                                <MuiButton type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["submit"]}</MuiButton>
                                            </Row>
                                                                            
                                            {isSubmitting && <LinearProgress />}
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        );

        const mainControlsView = (
            <div className="main-controls">
                { (gameStatus && !gameStatus.Completed && !gameStatus.IsPaused)
                    && <ActionButton className="pause" tooltip={AppContext.r["pause-tooltip"]}
                    action={async () => await TriviaProApi.PauseGame(event.id)} icon="fas fa-pause" /> }
                { (gameStatus && !gameStatus.Completed && gameStatus.IsPaused)
                    && <ActionButton className="pause" tooltip={AppContext.r["pause-tooltip"]} selected 
                    action={async () => await TriviaProApi.PauseGame(event.id, false)} icon="fas fa-pause" /> }

                { (!gameStatus || (gameStatus.Completed && !gameStatus.expired))
                    && <ActionButton className="play success" big tooltip={AppContext.r["play-tooltip"]}
                        action={async () => await TriviaProApi.StartGame(event.id)} icon="fas fa-play" /> }
                { (gameStatus && !gameStatus.Completed) 
                    && <ActionButton className="stop danger" big tooltip={AppContext.r["stop-tooltip"]}
                        action={async () => await TriviaProApi.StopGame(event.id)} icon="fas fa-stop" /> }

                {/* Force Move Forward */}
                { (gameStatus && !gameStatus.Completed
                    && (gameStatus.GameStatus >= GameStatusEnum.Category && gameStatus.GameStatus < GameStatusEnum.CorrectAnswer))
                    && <ActionButton className="forward" tooltip={AppContext.r["skip-tooltip"]}
                        action={async () => await this.forward(event.id)} icon="fas fa-forward" /> }
                
                {/* Force Move Forward */}
                { (gameStatus && !gameStatus.Completed
                    && (gameStatus.GameStatus < GameStatusEnum.Category || gameStatus.GameStatus >= GameStatusEnum.CorrectAnswer))
                    && <ActionButton className="forward" tooltip={AppContext.r["forward-tooltip"]}
                        action={async () => await this.forward(event.id)} icon="fas fa-forward" /> }
            </div>);

        const cancelByView = (gameStatus && gameStatus.CanCancel) ? (
            <div className="cancel-game">
                <div className="cancel-by">{AppContext.r["cancel-by"]}</div>
                <Countdown timespan={gameStatus.TimespanToCancel} />
                <ActionButton className="danger" 
                    action={this.confirmDeleteGame} icon="fas fa-trash-alt" />
            </div>) : "";

        const rankingControlsDisabled = !(gameStatus && 
            (gameStatus.GameStatus === GameStatusEnum.Started || gameStatus.GameStatus === GameStatusEnum.InvitePlayers
                || gameStatus.GameStatus > GameStatusEnum.AnswersTimerOn));

        const invitePlayersControlView = (gameStatus && !gameStatus.Completed) ? (
            <div className="labelled-control">
                {/* <label>{AppContext.r["invite-players"]}</label> */}

                { (gameStatus.GameStatus === GameStatusEnum.InvitePlayers)
                    && <ActionButton className="invite-players" selected icon="fas fa-bolt" 
                        tooltip={AppContext.r["hide-invite-players-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} /> }
                { (gameStatus.GameStatus !== GameStatusEnum.InvitePlayers)
                    && <ActionButton className="invite-players" icon="fas fa-bolt" 
                        tooltip={AppContext.r["show-invite-players-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => {
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.InvitePlayers);
                        }} /> }
            </div>
        ) : "";

        const enablePartialView = gameStatus  && !gameStatus.Completed ? (
            <div className="labelled-control">
                <label>{AppContext.r["partial-leaderboard-on-off"]}</label>

                { (gameStatus && gameStatus.EnablePartialRanking) &&
                    <ActionButton className="partial-results" selected icon="fas fa-list-ol" 
                        tooltip={AppContext.r["disable-partial-leaderboard-tooltip"]}
                        action={async () => await TriviaProApi.EnablePartialRanking(event.id, false)} /> }
                { (gameStatus && !gameStatus.EnablePartialRanking) &&
                    <ActionButton className="partial-results" icon="fas fa-list-ol"
                        tooltip={AppContext.r["enable-partial-leaderboard-tooltip"]}
                        action={async () => await TriviaProApi.EnablePartialRanking(event.id, true)} /> }
            </div>
        ) : "";

        const rankingControlsView = gameStatus ? (
            <div className="ranking-controls">
                {invitePlayersControlView}

                { (gameStatus && !gameStatus.Completed) 
                    && <div className="controls-separator"></div> }

                { (gameStatus && !gameStatus.Completed && gameStatus.GameStatus === GameStatusEnum.Teams)
                    && <ActionButton className="teams" selected icon="fas fa-list-ul"
                        tooltip={AppContext.r["hide-teams-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} /> }
                { (gameStatus && !gameStatus.Completed && gameStatus.GameStatus !== GameStatusEnum.Teams)
                    && <ActionButton className="teams" icon="fas fa-list-ul"
                        tooltip={AppContext.r["show-teams-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => {
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.Teams);
                        }} /> }
        
                { (gameStatus && !gameStatus.Completed && gameStatus.GameStatus === GameStatusEnum.Ranking)
                    && <ActionButton className="leaderboard" selected icon="fas fa-list-ol"
                        tooltip={AppContext.r["hide-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)} /> }
                { (gameStatus && !gameStatus.Completed && gameStatus.GameStatus !== GameStatusEnum.Ranking)
                    && <ActionButton className="leaderboard" icon="fas fa-list-ol"
                        tooltip={AppContext.r["show-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => {
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.Ranking);
                        }} /> }

                { (gameStatus && !gameStatus.Completed && gameStatus.GameStatus === GameStatusEnum.FinalRanking)
                    && <ActionButton className="final leaderboard" selected icon="fas fa-medal"
                        tooltip={AppContext.r["hide-final-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer)}  /> }
                { (gameStatus && !gameStatus.Completed && gameStatus.GameStatus !== GameStatusEnum.FinalRanking)
                    && <ActionButton className="final leaderboard" icon="fas fa-medal"
                        tooltip={AppContext.r["show-final-leaderboard-tooltip"]} disabled={rankingControlsDisabled}
                        action={async () => {
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
                            await TriviaProApi.SubmitStatusChange(event.id, GameStatusEnum.FinalRanking);
                        }} /> }

                { (gameStatus && !gameStatus.Completed) 
                    && <div className="controls-separator"></div> }

                {enablePartialView}
            </div>) : "";

        return (
            <Container className="console">
                <Row>
                    <Col md={3}>
                        {cancelByView}
                    </Col>

                    <Col md={6}>
                        {mainControlsView}
                    </Col>

                    <Col md={3}>
                    </Col>
                </Row>

                <Row>
                    {rankingControlsView}
                </Row>

                {(gameStatus && !gameStatus.Completed) 
                    && tabsView }
           </Container>
        );
    }

    highlightSelectedQuestion = (playlist, selectedPlaylistId, selectedQuestionId) => {
        if(/*playlist.id === selectedPlaylistId && */playlist && playlist.questions)
            for(var q of playlist.questions) {
                q["selected"] = false;
                if(q.id === selectedQuestionId) {
                    q["selected"] = true;
                }
            }
    }

    fetchPlaylist = async (playlist_id, selectedPlaylistId = -1, selectedQuestionId = -1) => {
        // console.log(playlist_id);
        const response = await TriviaProApi.Playlist(playlist_id);
        
        if(response && response.data) {
            const playlist = response.data.data;
            // console.log(playlist, selectedPlaylistId, selectedQuestionId);
            this.highlightSelectedQuestion(playlist, selectedPlaylistId, selectedQuestionId);

            this.setState({
                playlist: playlist,
                playlistId: selectedPlaylistId,
            });
        }
    }

    onPlaylistChanged = async (e, playlist_id) => {
        const { gameStatus } = this.state;

        await this.fetchPlaylist(playlist_id, gameStatus.PlaylistId, gameStatus.Question ? gameStatus.Question.QuestionId : -1);
    }

    get IsNotStarted() { 
        return (!this.gameStatus || this.gameStatus.Completed);
    }

    render() {
        const { event, gameStatus } = this.state;

        if(event === null) {
            return AppContext.r["preloader"];
        } else if (event) {
            return (
                <div className="event">
                    <h2 className="text-center">{event.title}</h2>
                    {this.IsNotStarted &&
                        <p>{format(new Date(event.date_time), "H:mm")}</p> }
                    
                    <ShareEvent event={event} label={AppContext.r["game-link"]} />

                    {this.IsNotStarted &&
                        <div className="countdown-to-start">
                            { event.countdown > 0 &&
                                <Countdown timespan={event.countdown} label={<p>{AppContext.r["event-planned"]}</p>} /> }

                            { !gameStatus &&
                                <p className="uppercase-bold">{AppContext.r["start-when"]}</p> }
                        </div>}
         
                    {gameStatus && !gameStatus.Completed && <span className="live">LIVE</span>}
                    {gameStatus && <span className="total-viewers">{gameStatus.TotalViewers} {AppContext.r["viewers"]}</span>}
                    
                    {this.console}
                </div>
            );
        }
    }
}

export default withModal(QuizConsole);