import React from 'react';
import { Line } from 'react-chartjs-2';

import AppContext from 'app/AppContext';
import MenuParty from 'api/MenuPro';
import { asWidget } from '../../../framework/asWidget.js';

class MenuPartyUserAccessesWidget extends React.Component {
    state = {
        graphData: null
    };

    async componentDidMount() {
        const graphResponse = await MenuParty.UsersGraph(this.props.restaurant.id);

        this.setState({
            graphData: graphResponse.data
        });
    }

    render() {
        const options = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) { if (Number.isInteger(value)) { return value; } },
                        stepSize: 1
                    }
                }]
            }
        }
        
        let graph = AppContext.r['preloader'];

        if(this.state.graphData) {
            const data = {
                datasets: [{
                    label: AppContext.r["users-check-ins-count"],
                    data: this.state.graphData.data,
                    backgroundColor: [ 'rgba(0, 123, 255, 0.4)' ],
                }],
            
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: this.state.graphData.labels
            };

            graph = <Line data={data} options={options}/>
        }

        return (
            <div>
                <h1 className="your-restaurant-title">{AppContext.r["users-check-ins-last-month"]}</h1>
                
                {graph}
            </div>);
    }
}

export default asWidget(MenuPartyUserAccessesWidget);