import React, {Component} from 'react'

export default class ProSlider extends Component {
    constructor(props) {
        super (props);

        this.step = (this.props.step) ? this.props.step : 1;
        this.minValue = (this.props.minValue) ? this.props.minValue : 0;
        this.maxValue = (this.props.maxValue) ? this.props.maxValue : 100;
        this.adapterDividend = (this.props.adapterDividend) ? this.props.adapterDividend : 1;
        this.value = (this.props.value) ? this.props.value / this.adapterDividend : this.minValue;
        if(this.value < this.minValue) this.value = this.minValue;
        if(this.value > this.maxValue) this.value = this.maxValue;
        this.symbol = (this.props.symbol) ? this.props.symbol : "";

        this.state = {
            value: this.value
        }
    }

     handleChange = (e) => {
//        e.target.value = parseInt(e.target.value, 10);
        const v = parseInt(e.target.value, 10);
        this.setState({
            value: v
        });

        if(this.props.onChange !== undefined)
            this.props.onChange(v * this.adapterDividend);
     }

    render() {
        return (
            <div className="pro-slider">
                <input type="range" min={this.minValue} max={this.maxValue} step={this.step}
                    value={this.state.value} onChange={this.handleChange}
                        disabled={this.props.disabled}></input>
                <p>{this.state.value} {this.symbol}</p>
            </div>);

        // return (
        //     <Form.Control as="range" name={this.props.name}  
        //                   onChange={this.handleChange}>
        //     </Form.Control>
        // );
        
        // const options = (this.state.options === null) ? [] : this.state.options.map(o => <option key={o.id} value={o.id}>{o.name}</option>);
        // const isDisabled = this.state.options === null;
        // const nullOption = (this.props.nullOption === true) ? <option value={null}></option> : "";

        // return (
        //     <Form.Control as="range" name={this.props.name} value={this.state.value} disabled={isDisabled}
        //                   onChange={this.handleChange}>
        //         {nullOption}
        //         {options}
        //     </Form.Control>);
    }
}