import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import AppContext from 'app/AppContext';
import {withModal} from 'framework/withModal';
import QuickModule from 'framework/QuickModule';
import QuickImage from 'framework/QuickImage';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);
AppContext.yupAddUrlTest(yup);

class _TeamMembers extends React.Component {
    state = { };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Field": "title",
            },
            {
                "Title": AppContext.r["name"],
                "Field": "name",
            },
            {
                "Adapter": (o) => {
                    return (
                        <div className="row-thumb">
                            {o.image && <img src={AppContext.s["api-url"] + "/stores/team/" + o.id + "/image/thumb"} alt="Thumb" /> }
                        </div>
                    );
                }
            }
        ];
    }

    get schema () {
        return yup.object({
            name: yup.string().required(),
            email: yup.string().email().nullable(),
            phone: yup.string().phone().nullable(),
            linkedin_url: yup.string().url().nullable(),
            twitter_url: yup.string().url().nullable(),
            instagram_url: yup.string().url().nullable(),
            facebook_url: yup.string().url().nullable()
        });
    }

    view = ({ handleChange, values, touched, errors, setFieldValue }) => (
        <>
            <Row>
                <Col md="8">
                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["title"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                            value={values.title} onChange={handleChange}/>
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["name"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                            value={values.name} onChange={handleChange}
                                            isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["email"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                        value={values.email} onChange={handleChange}
                                        isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["phone"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                            value={values.phone} onChange={handleChange}
                                            isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>{AppContext.r["description"]} (Max 255 characters)</Form.Label>
                            <Form.Control type="text" maxLength={255} placeholder={AppContext.r["description"]} name="description"
                                            value={values.description} onChange={handleChange} />
                        </Form.Group>
                    </Form.Row>

                    {/* Shown only on editing */}
                    {/* {(values.id) &&
                        <Form.Row>
                            <Form.Group as={Col}>
                                <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                                <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                                value={values.id} />
                            </Form.Group>
                        </Form.Row>} */}
                </Col>
                
                <Col md="4">
                    <QuickImage item={values} 
                        apiUrl={AppContext.s["api-url"] + "/stores/team/"}
                        onChanged={() => this.setState({ footerBusy: true })}
                        onUploaded={async (item, attributeName) => {
                            setFieldValue(attributeName, item.image);
                            setFieldValue("image_name", item.image_name);
                            this.setState({ footerBusy: false });
                        }}
                        onDelete={(item, attributeName) => {
                            console.log("DELETE " + attributeName, item)
                            setFieldValue(attributeName, item.image); // Set to -1
                            setFieldValue("image_name", item.image_name); // Set to empty
                        }} />
                </Col>
            </Row>

            <Form.Row>
                <h3>{AppContext.r["socials"]}</h3>
                
                {!this.props.disableLinkedIn &&
                    <Form.Group as={Col} xs="12">
                        <Form.Label>LinkedIn</Form.Label>
                        <Form.Control type="text" maxLength={255} placeholder={"LinkedIn"} name="linkedin_url"
                                        value={values.linkedin_url} onChange={handleChange}
                                        isValid={touched.linkedin_url && !errors.linkedin_url} isInvalid={!!errors.linkedin_url} />
                    </Form.Group> }
                
                {!this.props.disableTwitter &&
                    <Form.Group as={Col} xs="12">
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control type="text" maxLength={255} placeholder={"Twitter"} name="twitter_url"
                                        value={values.twitter_url} onChange={handleChange}
                                        isValid={touched.twitter_url && !errors.twitter_url} isInvalid={!!errors.twitter_url} />
                    </Form.Group> }
                
                {!this.props.disableFacebook &&
                    <Form.Group as={Col} xs="12">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control type="text" maxLength={255} placeholder={"Facebook"} name="facebook_url"
                                        value={values.facebook_url} onChange={handleChange}
                                        isValid={touched.facebook_url && !errors.facebook_url} isInvalid={!!errors.facebook_url} />
                    </Form.Group> }

                {!this.props.disableInstagram &&
                    <Form.Group as={Col} xs="12">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control type="text" maxLength={255} placeholder={"Facebook"} name="instagram_url"
                                        value={values.instagram_url} onChange={handleChange}
                                        isValid={touched.instagram_url && !errors.instagram_url} isInvalid={!!errors.instagram_url} />
                    </Form.Group> }
            </Form.Row>
        </>);

    render() {
        return <QuickModule initialValues={{"name": "", "image": ""}}
            className={this.props.className}
            footerBusy={this.state.footerBusy}
            apiPath={'/stores/'+ this.props.storeId + '/team'} 
            tableHead={this.tableHead} schema={this.schema} view={this.view}
            footerDisabled={this.state.footerDisabled} />;
    }
}

export default withModal(_TeamMembers);