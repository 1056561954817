import React from 'react';
import * as yup from "yup";

import { ContentRoute } from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";

export default class Invoice extends Module {
    enableInsert = false;
    enableDelete = false;
    onRowClick = null;

    info = Invoice.Info;
    
    static Info = {
        nameResource: "invoices",
        icon: "fa-solid fa-angles-down money-in-icon",
        path: "/invoices",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Invoice match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["invoice"];
    }

    get apiPath() {
        return '/invoices';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required(),
            description: yup.string(),
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Field": "title"
            }
        ];
    }
}