export default function loadClientResources (resources) {
    resources["shop-not-active"] = "Il tuo locale non è ancora stato attivato.";
    resources["shop-disabled"] = "Il tuo locale è stato disabilitato.";
    resources["no-role"] = "Non hai nessun servizio associato a questo account.";
    resources["active"] = "Attivo";
    resources["note"] = "Note";
    resources["service"] = "Servizio";
    resources["services"] = "Servizi";
    resources["stripe-product"] = "Prodotto Stripe";
    resources["stripe-products"] = "Prodotti Stripe";
    resources["stripe-product-id"] = "Id Prodotto";
    resources["view-in-stripe"] = "Gestisci su Stripe";
    resources["accountancy"] = "Contabilità";
    resources["expense"] = "Spesa";
    resources["expenses"] = "Spese";
    resources["invoice"] = "Fattura";
    resources["invoices"] = "Fatture";
    resources["media-stock"] = "Media Stock";
    resources["create-store-first"] = "Crea la scheda prima.";
    resources["category"] = "Categoria";
    resources["categories"] = "Categorie";
    resources["rating"] = "Rating";
    resources["ratings"] = "Ratings";
    resources["channel"] = "Canale";
    resources["channels"] = "Canali";
}