import React from 'react';

import Settings from '../../settings';

export default function loadClientResources (resources) {
    resources["shop"] = "Shop";
    resources["shops"] = "Shops";
    resources["shop-item-categories"] = "Categories";
    resources["shop-item-category"] = "Category";
    resources["shop-item-sub-category"] = "Sub-Category";
    resources["shop-item-sub-categories"] = "Sub-Categories";
    resources["highlighted"] = "Highlighted";
    resources["percent-discount"] = "% Discount";
    resources["placed-at"] = "Placed At";
    resources["requested-date"] = "Requested Date";
    resources["order"] = "Order";
    resources["orders"] = "Orders";
    resources["order-details"] = "Order Details";
    resources["brand"] = "Brand";
    resources["shop-item-brand"] = "Brand";
    resources["shop-item-brands"] = "Brands";
    resources["customer"] = "Customer";
    resources["customers"] = "Customers";
    resources["pending-orders"] = "Pending Orders";
    resources["no-pending-orders"] = "There are no pending orders.";
    resources["payment"] = "Payment";
    resources["order-to-table"] = "Order To Table";
    resources["delivery"] = "Delivery";
    resources["takeaway"] = "Take Away";
    resources["opening-hours"] = "Opening Hours";
    resources["collection-hours"] = "Collection Hours";
    resources["discount"] = "Discount";
    resources["order-id-is"] = "This order ID is";
    resources["customer-notes"] = "Customer Notes";

    resources["payments"] = "Payments";
    resources["stripe-partners"] = <>
        <p>Uderly partners with Stripe for simplified payments.</p>
        <p>Connect with Stripe to receive payments to your account.</p>
        <p><small>It will redirect you to the Stripe Uderly affiliates form.</small></p>
    </>;
    resources["connect-stripe"] = "Connect Stripe";
    resources["stripe-charges-not-enabled"] = <>You haven't successfully provided your payout details.</>
    resources["stripe-required"] = <>You are required to setup Stripe's payout in order to be enabled to sell by {Settings["project-name"]} and receive funds.</>;
        // <br />If you don't feel ready just yet, you could try a game attendance by providing the free tickets generated. You could see them in the event's view.</>;
    resources["stripe-payouts-not-enabled"] = 
        <>You have successfully provided the required information to take payments but there's still something to do about your payout details.
            <br/>In order to receive funds, please check your Stripe account.
            <br />If you believe you have provided all the required legal information, please do not worry and consider it might take a couple of days for Stripe to verify your profile and enable your payouts.
            <br />You can already start with {Settings["project-name"]}!</>
    resources["stripe-successfully-connected"] = "You have successfully connected your account with Stripe.";
    resources["view-stripe-account"] = "View Stripe Account";
    resources["view-stripe-dashboard"] = <p>You can manage your payments directly from your <a href="https://dashboard.stripe.com/" target="_blank">Stripe's dashboard</a>.<br />If you wish to change or remove the payment account, please get in touch with us.</p>;
    resources["currency"] = "Currency";
}