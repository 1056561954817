
import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import CoreApi from 'api/Core';

export default class Warehouse extends Module {
    info = Warehouse.Info;
    
    enableDelete = false;
    
    get pathname () {
        return "/warehouses";
    }
    
    static Info = {
        nameResource: "warehouses",
        "icon": "fas fa-warehouse",
        "path": "/warehouses",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Warehouse match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["warehouses"];
    }

    get apiPath() {
      return '/warehouses';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["city"],
                "Field": "city"
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
            description: "",
            address1: "",
            address2: "",
            post_code: "",
            city: "",
            country_id: 228,
        };
    }
    
    async beforeComponentDidMount() {
        // Fetch countries
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["address"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address1"
                                                value={values.address1} onChange={handleChange}
                                                isValid={touched.address1 && !errors.address1} isInvalid={!!errors.address1} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address2"
                                                value={values.address2} onChange={handleChange} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["post-code"]} name="post_code"
                                                value={values.post_code} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["city"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["city"]} name="city"
                                                value={values.city} onChange={handleChange}/>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4">
                                <Form.Label>{AppContext.r["country"]}</Form.Label>

                                <RemoteSelect name="country_id" key={values.country_id} value={values.country_id} onChange={handleChange}
                                            options={this.countryOptions} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange}
                                                isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                            </Form.Group>
                        </Row>
                            
                        {/* Shown only on editing */}
                        <Form.Row style={this.displayOnEditStyle}>
                            <Form.Group as={Col}>
                            <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                            <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                            value={values.id} onChange={handleChange}
                                            isValid={touched.id && !errors.id} isInvalid={!!errors.id} />
                            </Form.Group>
                        </Form.Row>
                    </Col>

                    {this.formFooter}
                </Form.Row>
            </Form>);
    }

    // render() {
    //     return this.listModeRender(AppContext.r["warehouse"], AppContext.r["warehouses"]);
    // }
}