import React from 'react';

export default function loadClientResources (resources) {
    resources["your-store"] = "Il tuo Stand";
    resources["edit-store-information"] = "Modifica il tuo stand";
    resources["stand"] = "Stand";
    resources["stands"] = "Stand";
    resources["enabled"] = "Abilitato";
    resources["products"] = "Prodotti";
    resources["event-type"] = "Tipo Evento";
    resources["event-types"] = "Tipi Evento";

    resources["free-tickets-desc"] = <p>Sentiti libero di usare questi voucher per offrire <b>ticket gratuiti</b>.</p>;

    resources["free-not-possible"] = <>
        <p>Non è più possibile rendere questo evento gratuito, in quanto hai già venduto dei ticket.</p>
        <p>Se desideri ospitare un evento gratuito, potresti rimborsare i partecipanti e creare un nuovo evento.</p>
    </>;
}