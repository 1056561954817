import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import CopyButton from "framework/CopyButton";

let yup = require('yup');

export default class Message extends Module {
    info = Message.Info;
    
    static Info = {
        nameResource: "messages",
        icon: "fa-solid fa-message",
        path: "/messages",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Message match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["message"];
    }

    get apiPath() {
      return '/store/messages';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["title"],
                "Field": "title",
            },
            {
                "Title": AppContext.r["language"],
                "Adapter": (o) => o.language.name,
            },
            {
                "ClassName": "d-xs-table-cell",
                "Adapter": (o) => {
                    const randomId = "random" + Math.round(Math.random() * 100000);
                
                    return <>
                        <input id={randomId} value={o.message} style={{ display: "none" }} />
                        <CopyButton id={randomId} />
                    </>;
                },
            }
        ];
    }

    get initialValues() {
        return {
            title: "",
            message: "",
        };
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <Form.Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                                value={values.title} onChange={handleChange}
                                                isValid={touched.title && !errors.title} isInvalid={!!errors.title} autoFocus />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="justify-content-center">
                            <CopyButton id="message" />
                        </Form.Row>
                        
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["message"]}</Form.Label>
                                <Form.Control as="textarea" id="message" rows="12" placeholder={AppContext.r["message"]} name="message" 
                                                value={values.message} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}