import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import SettingsIcon from '@material-ui/icons/Settings';

import asDashboardWidget from 'framework/asDashboardWidget.js';
import AppContext from 'app/AppContext';
import StripeApi from "api/Stripe";
import FormImage from 'framework/FormImage';
import ActionButton from 'framework/ActionButton';
import QrLink from 'framework/QrLink';

import 'react-quill/dist/quill.snow.css';

import { connect } from 'react-redux';
import { setAlert } from '../../../_metronic/layout/components/layoutSlice';

var HtmlToReactParser = require('html-to-react').Parser;

class ShopWidget extends React.Component {
    formImageRef = React.createRef();

    state = {
        store: this.props.store,
        stripeAccount: undefined,
        tabPage: 0
    };

    async componentDidMount() {
        const {store} = this.state;

        // Retrieve Stripe Connected Account
        const r = await StripeApi.RetrieveAccount(store.id);
        console.log(r);

        this.setState({
            stripeAccount: (r && r.data && r.status === 200) ? r.data : null
        });

        if(document.getElementById("Description")) {
            const htmlToReactParser = new HtmlToReactParser();
            const reactElement = htmlToReactParser.parse(this.state.store.description);

            ReactDOM.render(
                reactElement,
                document.getElementById('Description')
            );
        }
    }

    stripeViewAccount = async () => {
        const r = await StripeApi.LoginLink(this.state.store.id);

        if(r && r.status === 200 && r.data.login_link) {
            window.location = r.data.login_link.url;
        }
    }

    storePageUrl(includeHttp = false) {
        const { store } = this.state;

        return ((includeHttp) ? "https://www." : "www.") + store.domain;
    }

    get IsPremium () {
        return this.state.store && this.state.store.premium_id > 0 && 
            (!this.state.store.premium_ends_in || this.state.store.premium_ends_in > 0);
    }

    render() {
        const { store, enableEdit, stripeAccount } = this.state;

        if(!store)
            return AppContext.r["preloader"];

        // Stripe OnBoard/OnBoarded View
        let stripeView = "";

        if(stripeAccount === undefined) {
            stripeView = AppContext.r["preloader"];
        } else {
            if(stripeAccount === null || !stripeAccount.charges_enabled)
                stripeView = 
                    <>
                        {enableEdit &&
                            <>
                                {AppContext.r["stripe-partners"]}
        
                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeOnboard}>{AppContext.r["connect-stripe"]}</ActionButton>
                                
                                <br />
                            </> }
                        
                        <Alert className="stripe-error-alert" severity="error">
                            <AlertTitle>{(stripeAccount && !stripeAccount.charges_enabled) && AppContext.r["stripe-charges-not-enabled"]}</AlertTitle>
                            {AppContext.r["stripe-required"]}
                            {/* <Button onClick={() => this.setState({enableEdit: true})}>{AppContext.r["setup"]}</Button> */}
                        </Alert>
                    </>;
            else
                stripeView =
                    <>
                        <p>{AppContext.r["stripe-successfully-connected"]}</p>

                        { !stripeAccount.payouts_enabled && stripeAccount.type !== "standard" && 
                            <>
                                <Alert className="stripe-error-alert" severity="error">
                                    {AppContext.r["stripe-payouts-not-enabled"]}
                                </Alert>
                                <br />
                            </> }

                            {store.stripe_account_type == "express" ?
                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeViewAccount}>{AppContext.r["view-stripe-account"]}</ActionButton> :
                                    AppContext.r["view-stripe-dashboard"] }
                    </>;
        }
        
        return (
            <>
                <div className="left">
                    <FormImage type="avatar" viewOnly={!enableEdit} 
                        rounded height="128px" maxWidth="128px" className="avatar"
                        ref={this.formImageRef}
                        imageUrl={AppContext.s["api-url"] + '/stores/'+ store.id + '/image?updated_at=' + store.updated_at}
                        onDelete={this.onDeleteImage}
                        onImageFileChanged={this.onImageFileChanged} />
                </div>

                <div className="right">
                    <h2>{AppContext.r["your-store"]}</h2>
                    <h1>{store.name}</h1>
                    
                    <h3>
                        <Link to="/followers">
                            <b>{store.followers_count}</b> {AppContext.r["followers"]}
                        </Link>
                    </h3>

                    <QrLink value={this.storePageUrl(true)} logoImage={AppContext.s["mini-logo-qr"]} />
                </div>

                <div className="flex-break"></div>

                <QrLink value={this.storePageUrl(true)} logoImage={AppContext.s["mini-logo-qr"]} />

                <Link to="/organization" className="edit-store">
                    {AppContext.r["edit"]} <SettingsIcon />
                </Link>

                {/* <button aria-label="Increment value" 
                    onClick={() => this.props.setAlert({ open: true, message: "Hello world", severity: "warning" })} >
                    Alert Test
                </button>

                <span>{this.props.layout.value}</span> */}
            </>
        );
    }
}

const mapStateToProps = state => ({
    layout: state.layout
});

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(mapStateToProps, mapDispatchToProps())(asDashboardWidget(ShopWidget));