// import React from 'react'
// import { Route } from "react-router-dom"
// import Utils from '../../../Utils'
// import Layout from '../../Layout'
import ShopItemBrand from './ShopItemBrand';

import React from 'react';
import { Formik } from 'formik';
import { Row, Col, Form, Button, Modal as BSModal } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import JsonToTable from "framework/JsonToTable";
import RafalaApi from 'api/Rafala';
import CoreApi from 'api/Core';

export default class ShopItemEnotecaBrand extends ShopItemBrand {
    info = ShopItemEnotecaBrand.Info;
    
    get shopId () { return 2; }
    
    get pathname () {
        return "/enoteca/brands";
    }
    
    static Info = {
        nameResource: "shop-item-enoteca-brands",
        "icon": "fas fa-tags",
        "path": "/enoteca/brands",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItemEnotecaBrand match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["shop-item-enoteca-brands"];
    }

    get recordsApiPath() {
      return '/shopitems/brands?shopId=2';
    }

    // render() {
    //     return this.listModeRender(AppContext.r["shop-item-enoteca-brand"],AppContext.r["shop-item-enoteca-brands"]);
    // }
}