import React from 'react';
import { format } from 'date-fns';

import {ContentRoute} from "../../../_metronic/layout";

import Module from '../../modules/Module';
import AppContext from '../../AppContext';

export default class Report extends Module {
    enableView = false;
    enableInsert = false;
    showPagination = true;
    
    info = Report.Info;

    static Info = {
        "nameResource": "reports",
        "icon": "fa fa-tasks admin-menus-color",
        "path": "/reports",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Report match={props.match} location={props.location} history={props.history} />} />;
    }

    get apiPath() {
        return '/logs';
    }
    
    get tableHead () {
        return [
            {
                "Title": AppContext.r["user"],
                "Adapter": function(o) {
                    if(!o || !o.user)
                        return "";

                    const jsx = (
                        <>
                            {o.user.title && (o.user.title + " ")}
                            <b>{o.user.name && (o.user.name + " ")}</b>
                            <b>{o.user.surname && o.user.surname}</b>
                            {o.user.company && (" (" + o.user.company + ") ")}
                            &nbsp;- <a href={"mailto:" + o.user.email}>{o.user.email}</a>
                            {o.user.mobile_phone && <>&nbsp;- <a href={"tel:" + o.user.mobile_phone}>{o.user.mobile_phone}</a></>}
                        </>
                    );

                    return jsx;
                }
            },{
                "Title": AppContext.r["date-time"],
                "Field": "created_at",
                "Adapter": (o) => format(new Date(o.created_at), "d/MM/yyyy H:mm:ss")
            },{
                "Title": AppContext.r["ip-address"],
                "Adapter": function(o) {
                    const ipInfo = JSON.parse(o.ip_info);
                    return (ipInfo) ? ipInfo.ip : "";
                }
            },{
                "Title": AppContext.r["country"],
                "Adapter": function(o) {
                    const ipInfo = JSON.parse(o.ip_info);
                    return (ipInfo) ? ipInfo.country : "";
                }
            }
        ];
    }
}
