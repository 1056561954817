import React, { Suspense, lazy } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import About from "./pages/About";
import UserProfile from "./pages/UserProfile";
import ProjectIdsEnum from './settings/ProjectIdsEnum';

import AppContext from "./AppContext";
import ModulesMap from "./ModulesMap";
import { withModal } from "framework/withModal";

const YourOrganization = lazy(() =>
    import("./pages/IqSpace/YourStore")
);

const YourRafalaOrganization = lazy(() =>
    import("./pages/Rafala/YourStore")
);

const YourStand = lazy(() =>
    import("./pages/WoolSafe/YourStore")
);

function moduleRoutes(user) {
    const routes = [];

    if(user) {
        if(user.roles && user.roles.length > 0) {
            // Dynamic Routing
            if(!user.roles[0].modules || user.roles[0].modules.length === 0)
                return;

            const modules = JSON.parse(user.roles[0].modules);

            for (const i in modules) {
                const module = ModulesMap[modules[i].name];
                if(module && module.Info) { // && module.navMenuItem.show
                    if(module.route)
                        routes.push(module.route);

                    if(modules[i].subMenus) {
                        for (const j in modules[i].subMenus) {
                            const subModule = ModulesMap[modules[i].subMenus[j].name];

                            if(subModule && subModule.route !== null)
                                routes.push(subModule.route);
                        }
                    }
                }
            }
        }
    }
    
    return routes;
}

const BasePage = (props) => {
    const { user } = useSelector(state => state.auth);
    
    AppContext.history = useHistory();

    const routes = moduleRoutes(user);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                {/* <ContentRoute path="/builder" component={BuilderPage}/> */}
                <ContentRoute path="/about" component={About}/>

                {AppContext.s["project-id"] === ProjectIdsEnum.WoolSafe &&
                    <ContentRoute path="/stand" component={YourStand}/> }

                {AppContext.s["project-id"] === ProjectIdsEnum.IqSpace &&
                    <ContentRoute path="/organization" component={YourOrganization}/> }

                {AppContext.s["project-id"] === ProjectIdsEnum.Rafala &&
                    <ContentRoute path="/organization" component={YourRafalaOrganization}/> }

                <ContentRoute path="/user/profile" component={UserProfile}/>
                
                {routes}

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}

export default withModal(BasePage);