import React from 'react';
import { Form, Row, Col/*, InputGroup*/ } from 'react-bootstrap';
import { Field } from 'formik';
import ReactQuill from 'react-quill';

import AppContext from 'app/AppContext';
import {withModal} from 'framework/withModal';
import QuickModule from 'framework/QuickModule';
import QuickImage from 'framework/QuickImage';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);
AppContext.yupAddUrlTest(yup);

class _Products extends React.Component {
    state = { };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Field": "title",
            },
            {
                "Adapter": (o) => {
                    return (
                        <div className="row-thumb">
                            {o.image && <img src={AppContext.s["api-url"] + "/stores/product/" + o.id + "/image/thumb"} alt="Thumb" /> }
                        </div>
                    );
                }
            }
        ];
    }

    get schema () {
        return yup.object({
            title: yup.string().required(),
            url: yup.string().url().nullable()
        });
    }

    // <Form.Group as={Col} xs="4" md={3}>
    //     <Form.Label>{AppContext.r["price"]}*</Form.Label>
            
    //     <InputGroup className="mb-3">
    //         <InputGroup.Prepend>
    //             <InputGroup.Text id="productPrice">{"£"/* store.locale.currency_symbol */}</InputGroup.Text>
    //         </InputGroup.Prepend>
            
    //         <Form.Control type="text" aria-describedby="productPrice"
    //             placeholder={AppContext.r["price"]} name="price" value={values.price}
    //             onChange={(event) => { handleChange(event); /*this.calculateFees(event.target['value']);*/ }}
    //             isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
    //         <div className="error-message">{errors.price}</div>
    //     </InputGroup>
    // </Form.Group>

    view = ({ handleChange, values, touched, errors, setFieldValue }) => (
        <>
            <Row>
                <Col md="8">
                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>{AppContext.r["title"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                            value={values.title} onChange={handleChange}/>
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>{AppContext.r["description"]}</Form.Label>
                            <Field name="description">
                                {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                            </Field>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text" placeholder={"URL"} name="url"
                                value={values.url} onChange={handleChange}
                                isValid={touched.url && !errors.url} isInvalid={!!errors.url} />
                        </Form.Group>

                    </Form.Row>

                    {/* Shown only on editing */}
                    {(values.id) &&
                        <Form.Row>
                            <Form.Group as={Col}>
                                <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                                <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                                value={values.id} />
                            </Form.Group>
                        </Form.Row>}
                </Col>
                
                <Col md="4">
                    <QuickImage item={values} 
                        apiUrl={AppContext.s["api-url"] + "/stores/product/"}
                        onChanged={() => this.setState({ footerBusy: true })}
                        onUploaded={async (item, attributeName) => {
                            setFieldValue(attributeName, item.image);
                            setFieldValue("image_name", item.image_name);
                            this.setState({ footerBusy: false });
                        }}
                        onDelete={async (item, attributeName) => {
                            console.log("DELETE " + attributeName, item)
                            setFieldValue(attributeName, item.image); // Set to -1
                            setFieldValue("image_name", item.image_name); // Set to empty
                        }} />
                </Col>
            </Row>
        </>);

    render() {
        return <QuickModule initialValues={{"name": "", "description": "", "price": 0}}
            className={this.props.className}
            footerBusy={this.state.footerBusy}
            apiPath={'/stores/'+ this.props.storeId + '/products'} 
            tableHead={this.tableHead} schema={this.schema} view={this.view}
            footerDisabled={this.state.footerDisabled} />;
    }
}

export default withModal(_Products);