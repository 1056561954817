// QuickImage v1.0

import React from 'react';

import AppContext from '../app/AppContext';
import CoreApi from "api/Core";
import FormImage from "framework/FormImage";

export default class QuickImage extends React.Component {
    constructor(props) {
        super(props);

        if(!props.apiUrl) {
            const error =  { code : 403, message : "QuickImage: Must specify apiUrl" };
            throw error;
        }
        
        this.state = {
            loading: false,
            item: props.item,
            apiUrl: props.apiUrl
        };

        this.formImageRef = React.createRef();
    }

    mediaTransferApiPath = "media";

    uploadImageFile = async (file, attributeName = 'image') => {
        this.setState({
            loading: true
        });
  
        const url = AppContext.s["api-url"] + "/assets/" + this.mediaTransferApiPath;
    
        const formData = new FormData();
        formData.append('file', file);
  
        const response = await CoreApi.uploadFile(url, formData);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                //cover: response.data.name,
                image_name: file.name
            };

            item[attributeName] = response.data.name;

            console.log(item);

            if(this.props.onUploaded)
                await this.props.onUploaded(item, attributeName);
            // await this.update(item, attributeName === 'image', attributeName === 'cover');
        }

        this.setState({
            loading: false
        });
    }

    onFileChanged = (file, attributeName = 'image') => {
        console.log(file)
        if(this.props.onChanged)
            this.props.onChanged(file);

        this.uploadImageFile(file, attributeName);

        const item = { ...this.state.item };
        item[attributeName] = file.name;

        this.setState({
            item: item
        });
    }

    onDelete = async (attributeName = 'image') => {
        const values = { };
        values[attributeName] = "-1"; // Serve!?
        values["image_name"] = ""; // Serve!?

        if(this.props.onDelete)
            await this.props.onDelete(values, attributeName);
        // this.update(values, attributeName === 'image', attributeName === 'cover');

        const item = { ...this.state.item };
        item[attributeName] = null;

        this.setState({
            item: item
        });
        
        this.formImageRef.current.resetImage();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.item !== this.props.item) {
            this.setState({
                item: nextProps.item
            });
        }
    }

    render() {
        const {item} = this.state;

        const url = (!item || !item.id) ? "" : (this.state.apiUrl + item.id + "/image/thumb?updated_at=" + item.updated_at);

        return (
            <FormImage ref={this.formImageRef} ratio={1} imageUrl={url}
                onDelete={this.onDelete}
                onImageFileChanged={this.onFileChanged} />);
    }
}