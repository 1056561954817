import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { /*FormattedMessage, */injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { FacebookProvider, LoginButton } from 'react-facebook';
// import { Button } from '@material-ui/core';

import Api from "../../../../api/Auth.js";
import AppContext from "../../../AppContext";
import { loadResources } from "app/resources/resources";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

const initialValues = {
    email: "",
    password: "",
};

function Login(props) {
    //   const { intl } = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        //   .required(
        //     intl.formatMessage({
        //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
        //     })
        //   )
        .required(AppContext.r["required-field"]),
        password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(AppContext.r["required-field"]),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
        }

        return "";
    };

    const onLoggedIn = (response) => {
        Api.cacheTokenFromResponse(response);

        console.log("RESPONSE ", response);
        
        const { user } = response.data;

        if(user) {
            // Translate Language
            // console.log(user.language, AppContext.s["language"])
            if(user.language) {
                AppContext.s["language"] = user.language.iso_code;
            }
            loadResources();
        }

        disableLoading();
        props.login(response.data.access_token);
    }
  
    const handleFacebookResponse = async (data) => {
        console.log("handleFacebookResponse", data);

        enableLoading();
        setTimeout(() => {
          login(data.profile.email, null, "social", "facebook", data.tokenDetail.accessToken, data.profile.first_name, data.profile.last_name)
            .then(onLoggedIn)
            .catch((error) => {
              console.log("RESPONSE ", error);
              disableLoading();
            //   setSubmitting(false);
            //   setStatus(AppContext.r["wrong-credentials"]);
            });
        }, 1000);

        //this.login(data.profile.email, null, "social", "facebook", data.tokenDetail.accessToken, data.profile.first_name, data.profile.last_name);
        // const response = await 
        // if(response) {
        //     if(response.status === 200)
        //         this.props.modal.hide();
                
        //     if(response.status === 400)
        //         this.props.modal.setMessage(<>
        //             <i className="far fa-frown icon"></i>
        //             <p className="message">{AppContext.r["social-login-failed"]}</p>
        //             <Button onClick={this.props.modal.setFree}>{AppContext.r['close']}</Button>
        //         </>);
        // }
    }

    const handleError = (error) => {
        console.log(error);
    }

    const onLoadingFacebookLogin = () => {
        console.log("loading")
    }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(onLoggedIn)
          .catch((error) => {
            console.log("RESPONSE ", error);
            disableLoading();
            setSubmitting(false);
            setStatus(AppContext.r["wrong-credentials"]);
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          {AppContext.r["login-title"]}
        </h3>
        <p className="text-muted font-weight-bold">
          {AppContext.r["insert-credentials"]}
        </p>
      </div>
      {/* end::Head */}

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework">
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : ""}

        {/* (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@uderly.com</strong> and password{" "}
              <strong>password</strong> to continue.
            </div>
          </div>
        ) */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            {AppContext.r["forgot-password"]}
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>{AppContext.r["login"]}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>

        {AppContext.s["facebook-app-id"] && AppContext.s["enable-facebook-access"] &&
            <div className="facebook-button">
                <p className="text-center">{AppContext.r["or"]}</p>
                <FacebookProvider appId={AppContext.s["facebook-app-id"]}>
                    <LoginButton 
                        scope="email"
                        onLoading={onLoadingFacebookLogin}
                        onCompleted={handleFacebookResponse}
                        onError={handleError}>
                        <span><i className="fab fa-facebook-f"></i> {AppContext.r['access-with-facebook']}</span>
                    </LoginButton>
                </FacebookProvider>
            </div> }
        
      <p className="text-center">{AppContext.r["access-conditions"]}</p>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
