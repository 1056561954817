import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { format } from 'date-fns'
import * as yup from 'yup';

import AppContext from 'app/AppContext';
import StayApi from "api/Stay";
import StripeApi from "api/Stripe";
import FormImage from 'framework/FormImage';
import ActionButton from 'framework/ActionButton';
import { asWidget } from 'framework/asWidget.js';

AppContext.yupAddPhoneTest(yup);

class TripsWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            store: this.props.store,
            trips: null
        };
    }

    async componentDidMount() {
        const {store} = this.state;

        const response = await StayApi.Trips(store.id);
        console.log(response, response.data.data);

        this.setState({
            trips: response.data.data
            // trips: [...response.data.data, ...response.data.data, ...response.data.data, ...response.data.data, ...response.data.data, ...response.data.data, ...response.data.data]
        });
    }

    render() {
        const { store, trips } = this.state;

        if(!store || !trips)
            return AppContext.r["preloader"];

        return (
            <>
                <h1>{AppContext.r["upcoming-trips"]}</h1>
                
                <div className="actions">
                </div>

                <div className="trips">
                    <div className="card add-trip" onClick={() => AppContext.history.push("/trips/insert")}>
                        <Paper>
                            <h2>{AppContext.r["insert"]}</h2>
                            <h2>{AppContext.r["insert-trip"]}</h2>
                            <Icon className="fas fa-suitcase-rolling" />
                        </Paper>
                    </div> 

                    { trips.map(trip => 
                        <div className="card trip" onClick={() => AppContext.history.push("/trips/"+trip.id)} >
                            <Paper>
                                <h2>{trip.title}</h2>

                                <div>
                                    <p className="checkx-at">{AppContext.r["checkin-at"]}</p>
                                    <p>{format(new Date(trip.arriving_at), 'd MM yyyy')} { format(new Date(trip.checkin_at), 'HH:mm') }</p>
                                    <p className="checkx-at">{AppContext.r["checkout-at"]}</p>
                                    <p>{format(new Date(trip.departing_at), 'd MM yyyy')} { format(new Date(trip.checkout_at), 'HH:mm') }</p>
                                </div>

                                <div className="guests-recap">
                                    <b>{trip.guests_count}</b> {AppContext.r["guests"]} | <b>{trip.ids_count}</b> {AppContext.r["provided-ids"]}
                                </div>
                            </Paper>
                        </div>) }
                </div>
            </>
        );
    }
}

export default asWidget(TripsWidget);