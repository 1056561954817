export default function loadClientResources (resources) {
    resources["categories"] = "Categorie";
    resources["category"] = "Categoria";

    resources["vehicle"] = "Mezzo";
    resources["vehicles"] = "Mezzi";
    resources["plate"] = "Targa";
    resources["volume"] = "Volume";
    resources["cities"] = "Città";
    resources["divisions"] = "Divisioni";
    resources["human-resources"] = "Risorse Umane";
    resources["items"] = "Items";
    resources["jobs"] = "Mansioni";
    resources["teams"] = "Team";
    resources["vehicles"] = "Mezzi";
    resources["warehouses"] = "Magazzini";

    resources["customer"] = "Cliente";
    resources["customers"] = "Clienti";
    resources["plan"] = "Piano";

    resources["recipient"] = "Destinatario";
    resources["recipients"] = "Destinatari";
    resources["service-categories"] = "Categorie Servizi";
    resources["service-category"] = "Categoria Servizi";
    resources["registry"] = "Anagrafica";
    resources["registries"] = "Anagrafiche";
    resources["company-name"] = "Nome Azienda";
    resources["tax-number"] = "Partita IVA";
    resources["sdi-number"] = "SDI";
    resources["pec-email"] = "PEC";
    resources["business-type"] = "Tipo Giuridico Azienda";
    resources["business-types"] = "Tipi Giuridici Azienda";
    resources["registry-type"] = "Categoria Cliente";
    resources["registry-types"] = "Categorie Cliente";
    resources["registry-contacts"] = "Contatti";
    resources["registry-contact"] = "Contatto";
    resources["anomaly"] = "Anomalia";
    resources["anomalies"] = "Anomalie";
    resources["contact-name"] = "Nome Contatto";
    resources["new-contact"] = "Nuovo Contatto";
    resources["edit-contact"] = "Modifica Contatto";
    resources["delete-contact"] = "Elimina Contatto";
    resources["order-type"] = "Tipo Ordine";
    resources["order-types"] = "Tipi Ordini";
    resources["order-status"] = "Stato Ordine";
    resources["order-statuses"] = "Stato Ordini";
    resources["provider"] = "Fornitore";
    resources["providers"] = "Fornitori";
    resources["provider-status"] = "Stato Fornitore";
    resources["provider-statuses"] = "Stato Fornitori";
    resources["service"] = "Servizio";
    resources["services"] = "Servizi";
    resources["price-list-items"] = "Listino Servizi";
    resources["price-list-item"] = "Servizio";

    resources["order-types-enum"] = [];
    
    resources["order-statuses-enum"] = [];
    resources["order-statuses-enum"][1] = "Piazzato";
    resources["order-statuses-enum"][2] = "In Lavorazione";
    resources["order-statuses-enum"][3] = "Completato";
    resources["order-statuses-enum"][4] = "Cancellato dall'utente";
}