import React from 'react';
import { Formik } from 'formik';
import { Row, Col, Form, Button, Modal as BSModal } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import JsonToTable from "framework/JsonToTable";
import RafalaApi from 'api/Rafala';
import CoreApi from 'api/Core';

export default class ShopItemBrand extends Module {
    info = ShopItemBrand.Info;

    get shopId () { return 1; }
    
    get pathname () {
        return "/shop/brands";
    }
    
    static Info = {
        nameResource: "shop-item-brands",
        "icon": "fas fa-tags",
        "path": "/shop/brands",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItemBrand match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["shop-item-brands"];
    }

    get apiPath() {
      return '/shopitems/brands';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required(),
            description: yup.string()
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
        },{
            "Title": AppContext.r["name"],
            "Field": "name"
        },{
            "Title": AppContext.r["description"],
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const maxLength = 60;

                if(o.description && o.description.length > maxLength)
                    return o.description.substr(0, maxLength) + "...";
                return o.description;
            }
        },{
            "Title": "",
            "ClassName": "item-icons",
            "Adapter": function(o) {
                const icons = [];

                if(o.image) icons.push(<i className="far fa-image"></i>);

                return icons;
            }
          }
        ];
    }

    get initialValues() {
        return {
            name: "",
            shop_id: this.shopId
        };
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="10">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control as="textarea" rows="2" maxLength="255" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange}
                                                isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                            </Form.Group>
                        </Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }

    // render() {
    //     return this.listModeRender(AppContext.r["shop-item-brand"], AppContext.r["shop-item-brands"]);
    // }
}