export default function loadClientResources (resources) {
    resources["career"] = "Career";
    resources["careers"] = "Careers";
    resources["company"] = "Company";
    resources["message"] = "Message";
    resources["careers"] = {
        1: "Designer",
        2: "Developer",
        3: "Animator",
        4: "3D Artist",
        5: "Video-Maker"
    };
}