import React from 'react';
import { Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import ActionButton from '../../../framework/ActionButton';
import ToggleButton from '../../../framework/ToggleButton';

export default class Career extends Module {
    enableDelete = false;

    info = Career.Info;
    
    static Info = {
        nameResource: "careers",
        icon: "fas fa-user-md",
        path: "/careers",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Career match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["career"];
    }

    get apiPath() {
      return '/careers';
    }

    get mediaTransferApiPath() {
        return '/media/upload';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Field": "name",
            },
            {
                "ClassName": "item-icons",
                "Adapter": (o) => {
                    return (
                        <div className="row-thumb">
                            {o.image && <img src={AppContext.s["api-url"] + this.apiPath + "/" + o.id + "/image/thumb"} alt="Thumb" /> }
                        </div>
                    );
                }
            },
            {
                "Title": "",
                "Field": "published",
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <ActionButton selected={o.published}
                                action={async () => {
                                    const responseObj = await this.onTogglePublished(o);

                                    if(responseObj) {
                                        const records = [...this.state.records];

                                        for(let item of records) {
                                            if(item && item.id === responseObj.id) {
                                                item.published = responseObj.published;
                                            }
                                        }

                                        this.setState({
                                            records: records
                                        });
                                    }
                                }
                            }>{o.published ? AppContext.r["yes"] : AppContext.r["no"]}</ActionButton>
                        </div>);
                }
            }
        ];
    }
    
    onTogglePublished = async (o) => {
        const item = {
            id: o.id,
            published: !o.published ? 1 : 0 
        };

        return await this.update(item);
    }

    get initialValues() {
        return {
            name: "",
            published: true
        };
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name" autoFocus
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                            
                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                    onToggle={
                                        (value) => { 
                                            var item = {...values}
                                            item.published = value;
                                            this.setState({item: item});
                                            this.onChange(item);
                                        }} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                   
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}