import React from 'react';
import { Row, Col } from 'react-bootstrap';

import AppContext from 'app/AppContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class ActiveWeek extends React.Component {
    defaultWeek = {
        days: [ false, true, true, true, true, true, true ],
        minutes: [ { from: 8 * 60, to: 22 * 60 }, { from: 8 * 60, to: 22 * 60 }, { from: 8 * 60, to: 22 * 60 },
            { from: 8 * 60, to: 22 * 60 }, { from: 8 * 60, to: 22 * 60 }, { from: 8 * 60, to: 22 * 60 }, { from: 8 * 60, to: 22 * 60 } ]
    };

    state = this.props.values ? this.props.values : this.defaultWeek;

    render() {
        const hours = [];
        const interval = 15; // mins
        let mins = 0;

        while(mins < (24 * 60)) {
            hours.push(mins);
            mins += interval;
        }

        const convert = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2);
        const hoursMap = hours.map(h => <MenuItem value={h}>{convert(h)}</MenuItem>)

        const days = AppContext.r["week-days-short"].map((d, index) => {
            const { days } = this.state;
            const { minutes } = this.state;

            return (
                <Col className="active-days">
                    <FormControlLabel className="week-day"
                        control={
                            <Checkbox
                                checked={days[index]}
                                onChange={(event) => {
                                    days[index] = event.target.checked;
                                    this.setState({days: days});

                                    if(this.props.onChanged)
                                        this.props.onChanged({
                                            days: days,
                                            minutes: minutes
                                        });
                                }}
                                name={"checked"+d}
                                color="primary" />
                        }
                        label={d} />

                    <FormControl disabled={!days[index]}>
                        <InputLabel id={"from"+index}>From</InputLabel>
                        <Select labelId={"from"+index}
                            value={minutes[index].from}
                            onChange={(event) => {
                                const v = event.target.value;

                                minutes[index].from = v;
                                this.setState({minutes: minutes});

                                if(this.props.onChanged)
                                    this.props.onChanged({
                                        days: days,
                                        minutes: minutes
                                    });
                            }}>
                            {hoursMap}
                        </Select>
                    </FormControl>

                    <FormControl disabled={!days[index]}>
                        <InputLabel id={"to"+index}>To</InputLabel>
                            <Select
                                labelId={"to"+index}
                                value={minutes[index].to}
                                onChange={(event) => {
                                    const v = event.target.value;

                                    minutes[index].to = v;
                                    this.setState({minutes: minutes});
                                    
                                    if(this.props.onChanged)
                                        this.props.onChanged({
                                            days: days,
                                            minutes: minutes
                                        });
                                }}>
                            {hoursMap}
                            </Select>
                    </FormControl>
                </Col>
            );
        });

        return (
            <Row>
                {days}
            </Row>
        );
    }
}