import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';

export default class Stripe {
    static async OnBoard(storeId) {
        return await Axios.post(AppContext.s["api-url"] + "/stripe/onboard", { shopId: storeId })
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);

                return error.response;
            });
    }

    static async RetrieveAccount() {
        return await Axios.post(AppContext.s["api-url"] + "/stripe/retrieveAccount")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);

                return error.response;
            });
    }

    static async LoginLink(storeId) {
        return await Axios.post(AppContext.s["api-url"] + "/stripe/loginlink", { shopId: storeId })
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);

                return error.response;
            });
    }

    static async RefundTicket(attendance) {
        return await Axios.post(AppContext.s["api-url"] + "/event/attendances/"+attendance.id+"/refund")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }
}