import React from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { format } from 'date-fns';
import axios from 'axios';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {Tooltip} from '@material-ui/core';

import { ContentRoute } from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import TriviaProApi from 'api/TriviaPro';
import IqSpaceApi from 'api/TriviaPro';
import Module from "../../modules/Module";
import ToggleButton from 'framework/ToggleButton';
import ActionButton from '../../../framework/ActionButton';
import JsonToTable from 'framework/JsonToTable';

import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import TvIcon from '@material-ui/icons/Tv';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ShareIcon from '@material-ui/icons/Share';

export class ShareEvent extends React.Component {
    state = {
        mode: 0
    };

    handleChange = (e, value) => {
        this.setState({mode: value});
    }

    render() {
        const {event} = this.props;
        const {mode} = this.state;

        const localizedDomain = AppContext.s["language"] === "it" ? "iqspace.it" : "iqspace.co.uk";
        let eventLink = localizedDomain+"/event/" + event.id;
        if(mode === 1) eventLink += "/maxi";

        return (
            <div className="shareable-event-links">
                <BottomNavigation className="modes" value={mode} onChange={this.handleChange}>
                    <Tooltip title={<p className="mode-tooltip">{AppContext.r["play-desc"]}</p>}>
                        <BottomNavigationAction value={0} label={AppContext.r["play"]} icon={<PhoneIphoneIcon />} />
                    </Tooltip>

                    <Tooltip title={<p className="mode-tooltip">{AppContext.r["project-desc"]}</p>}>
                        <BottomNavigationAction value={1} label={AppContext.r["project"]} icon={<TvIcon />} />
                    </Tooltip>
                </BottomNavigation>

                <a href={"https://www."+eventLink} target="_blank" rel="noopener noreferrer">
                    {eventLink}
                </a>
                
                <ShareIcon className="share-icon" />

                {this.props.label && 
                    <span className="details-label">{this.props.label}</span>}
            </div>);
    }
}

const yup = require('yup');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Event extends Module {
    info = Event.Info;
    
    static Info = {
        nameResource: "events",
        icon: "fas fa-calendar",
        path: "/events",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Event match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["event"];
    }

    get apiPath() {
      return '/events';
    }

    state = {
        ...this.state,
        isFree: true
    }

    minPrice = 0.5;
    defaultPrice = 5;
    
    get schema () {
        const v = {
            title: yup.string().required(AppContext.r["required-field"])
        };

        if(!this.state.isFree)
            v["price"] = yup.number()
                .required(AppContext.r["required-field"])
                .min(this.minPrice, AppContext.r["ticket-min-price"].replace("{currency}", this.state.store.locale.currency_symbol));
        
        return yup.object(v);
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <div className="row-thumb">
                                {o.image && <img src={AppContext.s["api-url"] + "/events/" + o.id + "/image/thumb"} alt="Thumb" /> }
                            </div>

                            {o.title}
                        </div>);
                }
            },
            {
                "Title": AppContext.r["price"],
                "Adapter": (o) => {
                    let p = this.state.store.locale.currency_symbol + " " + o.price;

                    if(o.price === 0)
                        p = AppContext.r["free"];

                    return (
                        <div className="td-v-center">
                            {p}
                        </div>);
                }
            },
            {
                "Title": AppContext.r["date-time"],
                "Adapter": (o) => {
                    const date = new Date(o.date_time.substr(0, 19));
                    // const readableDate = date.getDate() + " " + AppContext.r['months'][date.getMonth()] + " " + date.getFullYear() + " " + AppContext.r["at-time"] + " " + date.getHours() + ":" + date.getMinutes()
                    const readableDate = format(date, "d MM yyyy H:mm");
                    return <>
                        <div className="td-v-center" style={{minWidth: "100px"}}>
                            {readableDate}
                        </div>
                    </>;
                }
            },
            {
                "Title": "",
                "Field": "published",
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <ActionButton selected={o.published}
                                action={async () => {
                                    const responseObj = await this.onTogglePublished(o);

                                    if(responseObj) {
                                        const records = [...this.state.records];

                                        for(let item of records) {
                                            if(item && item.id === responseObj.id) {
                                                item.published = responseObj.published;
                                            }
                                        }

                                        this.setState({
                                            records: records
                                        });
                                    }
                                }
                            }>{o.published ? AppContext.r["yes"] : AppContext.r["no"]}</ActionButton>
                        </div>);
                }
            }
        ];
    }
    
    onTogglePublished = async (o) => {
        const item = {
            id: o.id,
            published: !o.published ? 1 : 0 
        };

        return await this.update(item);
    }

    get tableAttendancesHead () {
        return [
            {
                "Title": AppContext.r["ticket-id"],
                "Field": "id"
            },{
                "Title": AppContext.r["fullname"],
                "Adapter": (o) => {
                    return o.user.name + " " + o.user.surname;
                }
            },{
                "Title": AppContext.r["username"],
                "Adapter": (o) => {
                    return o.user.gamename;
                }
            },{
                "Title": AppContext.r["purchased-on"],
                "Field": "created_at"
            },{
                "Title": AppContext.r["voucher"],
                "Field": "discount_code"
            },{
                "Title": AppContext.r["attendance_status"],
                "Adapter": (o) => {
                    const status = AppContext.r["attendance_statuses"][o.status_id];

                    if(o.status_id === 2 
                        // && o.payment_intent 
                        && !o.discount_code && o.amount > 0)
                        return <span>{status}<Button variant="link" onClick={() => this.confirmRefund(o)}>{AppContext.r["refund"]}</Button></span>;

                    return status;
                }
            }
        ];
    }

    get initialValues() {
        return {
            title: "",
            published: true,
            description: "",
            date_time: format(new Date(), "yyyy-MM-dd 20:30:00"),
            timezone: "",
            youtube_url: "",
            facebook_url: "",
            instagram_url: "",
            twitter_url: "",
            location_id: null,
            price: 123456789,
            netIncome: 0,
            transactionFee: 0
        };
    }
    
    async beforeComponentDidMount() {
        // Fetch locations
        this.locationOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locations", "id");
        const myShopsResponse = await TriviaProApi.MyShops();

        if(myShopsResponse && myShopsResponse.status === 200 && myShopsResponse.data.data[0]) {
            const store = myShopsResponse.data.data[0];

            this.setState({
                store: store
            });

            this.calculateFees(null, store);
        }
    }

    onFetchedItem = (item) => {
        if(item.price > 0)
            this.setState({isFree: false});
        this.calculateFees(item.price);
    }

    confirmRefund = (attendance) => {
        const text = AppContext.r["refund-confirm"].replace("{user}", attendance.user.name);

        this.confirm(AppContext.r["refund-ticket"], text, 
            async () => await this.refund(attendance));
    }

    refund = async (attendance) => {
        console.log(attendance);
        this.setState({
            modalBusy: true
        });

        const response = await IqSpaceApi.RefundTicket(attendance);
        console.log(response);
        this.fetchItem(this.state.item.id);

        this.setState({
            modalBusy: false,
            showModal: false
        });
    }

    calculateFees = (price = null, store = null) => {
        if(!store)
            store = this.state.store;
        if(!price)
            price = this.state.price;
        if(!price)
            price = this.initialValues.price;

        const transactionFee = ((parseFloat(price) / 100 * parseFloat(store.stripe_fee)) + parseFloat(store.stripe_fixed_fee));

        this.setState({
            netIncome: parseFloat(price) - transactionFee,
            transactionFee: transactionFee
        });
    }

    get freeTicketsMap() {
        const {free_tickets} = this.state.item;

        if(!free_tickets)
            return "";

        const tickets = JSON.parse(free_tickets);

        return (
            <Form.Row>
                <p>{AppContext.r["free-tickets-desc"]}</p>

                <div className="free-tickets">
                    {tickets.map(t => 
                        <Chip variant="outlined" label={t.code} icon={<Icon className="fas fa-ticket-alt" />} />)}
                </div>
            </Form.Row>);
    }

    onDeleted = (response) => {
        console.log(response, this.props);

        if(response.status === 423)
            this.setState({
                error: AppContext.r["cant-delete-event"]
            });
    }

    insertDataAdapter = (values) => {
        if(this.state.isFree)
            values["price"] = 0;
    //     // TODO: make it more generic
    //     values["timezone"] = "+0"+this.state.store.timezone+":00";
        return values;
    }

    updateAdapter = (values) => {
        if(this.state.isFree)
            values["price"] = 0;
        // console.log(values);
        return values;
    }

    get mask () {
        this.formImageRatio = 4/3;

        const {store, error, item} = this.state;

        if(!store)
            return AppContext.r["preloader"];

        // const DatePickerField = ({ name, value, onChange }) => {
        //     return (
        //         <DatePicker
        //             selected={(value && new Date(value)) || null}
        //             onChange={val => {
        //                 onChange(name, format(val, "yyyy-MM-dd H:mm:ss"));
        //             }}
        //             showTimeSelect
        //             timeFormat="HH:mm"
        //             timeIntervals={15}
        //             timeCaption={AppContext.r["time"]}
        //             dateFormat="yyyy-MM-d H:mm" />
        //     );
        // };

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form className="event-mask" onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title" autoFocus
                                                value={values.title} onChange={handleChange}
                                                isValid={touched.title && !errors.title} isInvalid={!!errors.title} />
                                <div className="error-message">{errors.title}</div>
                            </Form.Group>

                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.published = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                             <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["date-time"]}</Form.Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker className="date-time-picker"
                                        ampm={false} format="d MM yyyy - H:mm"
                                        value={values.date_time.substr(0, 19)}
                                        onChange={(v) => setFieldValue("date_time", format(v, "yyyy-MM-dd H:mm:ss") + ".000000")} />
                                        {/* label={AppContext.r["date-time"]}  */}
                                </MuiPickersUtilsProvider>

                                {/*
                                <Form.Label>{AppContext.r["date-time"]}</Form.Label>
                                <DatePickerField
                                    name="date_time"
                                    value={values.date_time}
                                    onChange={setFieldValue} /> */}
                            </Form.Group>

                            {this.isEdit && 
                                <Form.Group as={Col} md="2">
                                    <Form.Label>{AppContext.r["timezone"]}</Form.Label>
                                    <p>{values.timezone}</p>
                                    {/* <Form.Control type="text" placeholder={AppContext.r["title"]} name="timezone" readOnly small
                                                    value={values.timezone} onChange={handleChange} /> */}
                                </Form.Group> }
                        </Form.Row>

                        {/* Zoom */}

                        <Form.Row>
                            <Col xs={12}>
                                <h3>Zoom</h3>
                            </Col>
                            <Col xs={12}>
                                {AppContext.r["zoom-meeting-desc"]}
                            </Col>
                        </Form.Row>

                        <br />

                        <Form.Row>
                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["zoom-meeting-id"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["zoom-meeting-id"]} name="zoom_meeting_id"
                                                value={values.zoom_meeting_id} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["zoom-meeting-password"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["zoom-meeting-password"]} name="zoom_meeting_password"
                                                value={values.zoom_meeting_password} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                        
                        {/* <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Field name="description">
                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                </Field>
                            </Form.Group>
                        </Form.Row> */}
                    </Col>

                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>

                {/* {store.licence_id <= 2 && */}
                    <Form.Row>
                        <Col xs={12}>
                            <h3>{AppContext.r["tickets"]}</h3>
                        </Col>

                        <Form.Group as={Col} xs={12}>
                            <FormControlLabel
                                control={<Switch checked={this.state.isFree} 
                                    onChange={(e) => {
                                        console.log(item, e.target.checked)

                                        if((!item.attendances || item.attendances.length === 0) && (!item.games || item.games.length === 0)) {
                                            setFieldValue("price", !e.target.checked ? this.defaultPrice : 123456789);
                                            // setFieldValue("max_attendants_count", !e.target.checked ? 30 : 90);

                                            if(!e.target.checked)
                                                this.calculateFees(this.defaultPrice);
    
                                            this.setState({isFree: e.target.checked });
                                        } else {
                                            this.setModal("", AppContext.r["free-not-possible"], null);
                                    
                                            this.setState({
                                                showModal: true
                                            });
                                        }
                                    }} name="isFree" />}
                                label={this.state.isFree ? AppContext.r["this-is-a-free-event"] : AppContext.r["this-is-a-paid-event"]} />
                        </Form.Group>

                        { this.state.isFree && 
                            <p>{AppContext.r["licence"]}: <b>{store.licence.title}</b> {store.licence.max_attendants_count + " | " + AppContext.r["players"]}</p>}

                        { !this.state.isFree && 
                        <>
                            <Form.Group as={Col} xs="4" md={3}>
                                <Form.Label>{AppContext.r["price"]}*</Form.Label>

                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="ticketPrice">{store.locale.currency_symbol}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    
                                    <Form.Control type="text" aria-describedby="ticketPrice"
                                        placeholder={AppContext.r["price"]} name="price" value={values.price} 
                                        onChange={(event) => { handleChange(event); this.calculateFees(event.target['value']); }}
                                        isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
                                    <div className="error-message">{errors.price}</div>
                                </InputGroup>
                            </Form.Group>
                        
                            <Col xs={4} md={3}>
                                <Form.Label>{AppContext.r["transaction-fee"]}</Form.Label>
                                <p className="transaction-fee">{store.locale.currency_symbol + " " + this.state.transactionFee.toFixed(2)}</p>
                            </Col>
                            
                            <Col xs={4} md={3}>
                            <Form.Label>{AppContext.r["you-earn"]}</Form.Label>
                            <p className="you-earn">{store.locale.currency_symbol + " " + this.state.netIncome.toFixed(2)}</p>
                        </Col>
                        </>
                        }
                    </Form.Row> 
                    {/* } */}

                {(/*store.licence_id <= 2 && */!this.state.isFree) && this.freeTicketsMap}
                
                {(/*store.licence_id <= 2 && */!this.state.isFree && this.state.item && this.state.item.id) &&
                    <Form.Row style={this.displayOnEditStyle}>
                        <Col xs={12}>
                            <h3>{AppContext.r["attendances"]}</h3>
                        </Col>

                        <Col xs={12}>
                            <JsonToTable head={this.tableAttendancesHead} body={this.state.item.attendances}
                                noItemsText={AppContext.r["no-attendances"]} />
                        </Col>
                    </Form.Row> }

                {values.id && 
                    <Form.Row>
                        <Col>
                            <ShareEvent event={values} label={AppContext.r["game-link"]} />
                        </Col>
                    </Form.Row> }

                {/* Shown only on editing 
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>*/}

                {this.formFooter}

                {error && 
                    <Form.Row className="error-row">
                        <Snackbar open={error != null} autoHideDuration={20000000} 
                            onClose={() => this.setState({error: null})}>
                            <Alert severity="warning" onClose={() => this.setState({error: null})}>{error}</Alert>
                        </Snackbar>
                    </Form.Row> }
            </Form>);
    }
}

export class EventWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: null
        };
    }

    componentDidMount() {
        const t = this;

        axios.get(AppContext.s["api-url"] + "/events?upcoming=1")
            .then(response => {
                // console.log(response);
                t.setState({
                    events: response.data.data
                });
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    get widget() {
        const events = (this.state.events === null) ? AppContext.r["preloader"] : 
            (this.state.events.length === 0) ? (<p className="text-center">{AppContext.r["no-upcoming-events"]}</p>) :
                this.state.events.map((event) => {
                    const imageUrl = AppContext.s["api-url"] + "/events/" + event.id + "/image/thumb";

                    return (
                        <div className="event" key={event.id}>
                            <p>{event.title}</p>
                            <Button variant="link" onClick={() => { this.setState({redirectTo: Event.navMenuItem.path + "/" + event.id})}}>{AppContext.r["edit"]}</Button>
                            <img src={imageUrl} alt={event.title} />
                        </div>);
        });

        return (
          <div className="event widget">
              <h1>{AppContext.r["upcoming-events"]}</h1>

              <div className="events">
                {events}
              </div>
          </div>
        );
    }
}