import React, {Component} from 'react';
import { Button } from 'react-bootstrap';

import AppContext from '../app/AppContext';

export default class ToggleButton extends Component {
    constructor (props) {
        super(props);

        this.state = {
            toggled: (this.props.toggled === true || this.props.toggled === 1) ? true : false
        }

        this.on =  (this.props.on) ? this.props.on : "on";
        this.off = (this.props.off) ? this.props.off : "off";
    }

    onClick = async (e) => {
        e.stopPropagation();

        const toggled = this.state.toggled;

        if(this.props.onToggleAsync) {
            this.setState({
                toggled: null
            })

            await this.props.onToggleAsync(!this.state.toggled);
        }

        if(this.props.onToggle)
            this.props.onToggle(!toggled);

        this.setState({
            toggled: !toggled
        })
    }

    render() {
        if(this.state.toggled === null)
            return AppContext.r["preloader"];

        const baseClass = (this.props.className !== undefined) ? "toggle-button " + this.props.className : "toggle-button";

        return <Button size="sm" variant="outline-info" className={this.state.toggled ? baseClass + " toggled" : baseClass}
                        disabled={this.props.disabled}
                        onClick={this.onClick}>{this.state.toggled ? this.on : this.off}</Button>
    }
}