import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import CoreApi from '../../../api/Core';
import AppContext from 'app/AppContext';
import {withModal} from 'framework/withModal';
import RemoteSelect from '../../../framework/RemoteSelect';
import QuickModule from 'framework/QuickModule';
import QuickImage from 'framework/QuickImage';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);

class _TripContacts extends React.Component {
    state = { };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Adapter": (o) => {
                    return o.name + " " + o.surname
                },
            },
            {
                "Title": AppContext.r["passport-id"],
                "Field": "passport_id"
            }
            ,{
                "ClassName": "item-icons",
                "Adapter": (o) => {
                    return (
                        <div className="row-thumb">
                            {o.image && 
                                <a href={AppContext.s["api-url"] + "/stores/trips/"+ this.props.tripId + "/contacts/" + o.id + "/image"} target="_blank">
                                    <img src={AppContext.s["api-url"] + "/stores/trips/"+ this.props.tripId + "/contacts/" + o.id + "/image/thumb"} alt="Thumb" />
                                </a> }
                        </div>
                    );
                }
            }
        ];
    }

    get schema () {
        return yup.object({
            name: yup.string().required(),
            surname: yup.string().required(),
            email: yup.string().email().nullable(),
            phone: yup.string().phone().nullable(),
        });
    }

    async componentDidMount() {
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
    }

    view = ({ handleChange, values, touched, errors, setFieldValue }) => (
        <>
            <Row>
                <Col md="8">
                    <Form.Row>
                        <Form.Group as={Col} sm="6">
                            <Form.Label>{AppContext.r["name"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                            value={values.name} onChange={handleChange}
                                            isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                        </Form.Group>

                        <Form.Group as={Col} sm="6">
                            <Form.Label>{AppContext.r["surname"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["surname"]} name="surname"
                                            value={values.surname} onChange={handleChange}
                                            isValid={touched.surname && !errors.surname} isInvalid={!!errors.surname} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["email"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                        value={values.email} onChange={handleChange}
                                        isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["phone"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                            value={values.phone} onChange={handleChange}
                                            isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4">
                            <Form.Label>{AppContext.r["passport-id"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["passport-id"]} name="passport_id"
                                            value={values.passport_id} onChange={handleChange} maxLength={15}
                                            isValid={touched.passport_id && !errors.passport_id} isInvalid={!!errors.passport_id} />
                        </Form.Group>

                        <Form.Group as={Col} md="4">
                            <Form.Label>{AppContext.r["nationality"]}</Form.Label>

                            <RemoteSelect name="nationality_id" key={"Nationality-"+values.nationality_id} value={values.nationality_id} onChange={handleChange}
                                        options={this.countryOptions} />
                        </Form.Group>

                        <Form.Group as={Col} md="4">
                            <Form.Label>{AppContext.r["issued-by"]}</Form.Label>

                            <RemoteSelect name="issued_by_id" key={"IssuedBy-"+values.issued_by_id} value={values.issued_by_id} onChange={handleChange}
                                        options={this.countryOptions} />
                        </Form.Group>
                    </Form.Row>
                </Col>
                
                <Col md="4">
                    <QuickImage item={values} 
                        apiUrl={AppContext.s["api-url"] + "/stores/trips/"+ this.props.tripId + '/contacts/'}
                        onChanged={() => this.setState({ footerBusy: true })}
                        onUploaded={async (item, attributeName) => {
                            setFieldValue(attributeName, item.image);
                            setFieldValue("image_name", item.image_name);
                            this.setState({ footerBusy: false });
                        }}
                        onDelete={(item, attributeName) => {
                            console.log("DELETE " + attributeName, item)
                            setFieldValue(attributeName, item.image); // Set to -1
                            setFieldValue("image_name", item.image_name); // Set to empty
                        }} />
                </Col>
            </Row>
        </>);

    render() {
        return <QuickModule initialValues={{"name": "", "nationality_id": 102, "issued_by_id": 102}}
            className={this.props.className}
            footerBusy={this.state.footerBusy}
            apiPath={'/stores/trips/'+ this.props.tripId + '/contacts'}
            tableHead={this.tableHead} schema={this.schema} view={this.view}
            footerDisabled={this.state.footerDisabled} />;
    }
}

export default withModal(_TripContacts);