import React from 'react';

export default function loadClientResources (resources) {
    resources["copy"] = "Copy";
    resources["copy-link"] = "Copy Link";
    resources["copied"] = "Copied";
    resources["click-to-view-qr"] = "Click to view the QR Code";
    resources["image-instructions"] = <>
        <p>Drag and drop an image here</p>
        <p><small>Supported formats .gif, .jpeg, .png</small></p>
    </>;
}