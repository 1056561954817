// export default function loadClientResources (resources) {
//     resources["product-code"] = "Codice Prodotto";
//     resources["package-quantity"] = "Quantità Package";
//     resources["package-size"] = "Package";
//     resources["packaging"] = "Entità Package";
//     resources["placed-at"] = "Piazzato Il";
//     resources["requested-date"] = "Data Richiesta";
//     resources["note"] = "Note";
//     resources["status"] = "Stato";
//     resources["order-id-is"] = "ID ordine";
//     resources["user"] = "Utente";
//     resources["shop-item-enoteca-category"] = "Categoria Enoteca";
//     resources["shop-item-enoteca-categories"] = "Categorie Enoteca";
//     resources["shop-item-enoteca-brand"] = "Marca Enoteca";
//     resources["shop-item-enoteca-brands"] = "Marche Enoteca";
//     resources["filter-not-available"] = "Filtra Non Disponibili";
//     resources["clear-filter-not-available"] = "Resetta Filtro Non Disponibili";
//     resources["warehouse"] = "Magazzino";
//     resources["warehouses"] = "Magazzini";
//     //resources["order-type"] = [];
//     //resources["order-type"][1] = "Comanda";
//     resources["internal-order"] = "Ordini Interni";
//     resources["internal-orders"] = "Ordine Interno";
//     resources["placed-by"] = "Creato Da";
//     resources["percent-volume"] = "% Alcol";
// }

export default function loadClientResources (resources) {
    resources["product-code"] = "Codice Prodotto";
    resources["package-quantity"] = "Quantità Package";
    resources["package-size"] = "Package";
    resources["packaging"] = "Entità Package";
    resources["placed-at"] = "Piazzato Il";
    resources["requested-date"] = "Data Richiesta";
    resources["note"] = "Note";
    resources["status"] = "Stato";
    resources["order-id-is"] = "ID ordine";
    resources["user"] = "Utente";
    resources["shop-item-enoteca-category"] = "Categoria Enoteca";
    resources["shop-item-enoteca-categories"] = "Categorie Enoteca";
    resources["shop-item-enoteca-brand"] = "Marca Enoteca";
    resources["shop-item-enoteca-brands"] = "Marche Enoteca";
    resources["filter-not-available"] = "Filtra Non Disponibili";
    resources["clear-filter-not-available"] = "Resetta Filtro Non Disponibili";
    resources["warehouse"] = "Magazzino";
    resources["warehouses"] = "Magazzini";
    resources["order-type"] = [];
    resources["order-type"][1] = "Comanda";
    resources["internal-order"] = "Ordini Interni";
    resources["internal-orders"] = "Ordine Interno";
    resources["placed-by"] = "Creato Da";
    resources["percent-volume"] = "% Alcol";
    resources["stock"] = "Stock";
    resources["supply-stock"] = "Supply Stock";
    resources["supply-stock-confirm"] = "Would you like to supply the stock with {item}?";
    resources["transfer-stock"] = "Transfer Stock";
    resources["transfer-stock-confirm"] = "Would you like to transfer the {item} stock?";
    resources["previous-stock-level"] = "Previous Level";
    resources["new-stock-level"] = "New Level";
    resources["from-warehouse"] = "Da Magazzino";

    resources["shop"] = "Negozio";
    resources["locale"] = "Ambiente";
    resources["shop-items"] = "Shop Items";
    resources["shop-item"] = "Shop Item";
    resources["enoteca-item"] = "Enoteca Item";
    resources["enoteca-items"] = "Enoteca Items";
    resources["enoteca-orders"] = "Enoteca / Ordini";
}