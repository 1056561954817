import React from "react";
import { Col, Form } from "react-bootstrap";
import * as yup from "yup";

import { ContentRoute } from "_metronic/layout";

import AppContext from "app/AppContext";
import UderlyApi from "api/Uderly";
import MediaStockApi from "api/MediaStock";
import Module from "app/modules/Module";
import MediaStockImage from "../../../framework/MediaStockImage";
import ActionButton from "../../../framework/ActionButton";
import ToggleButton from "../../../framework/ToggleButton";
import RemoteSelect from "../../../framework/RemoteSelect";

export default class MediaStockCategory extends Module {
  info = MediaStockCategory.Info;
  sortable = true;

  static Info = {
    nameResource: "categories",
    icon: "fas fa-layer-group",
    path: "/categories",
    show: true,
  };

  static get route() {
    return (
      <ContentRoute
        key={this.Info.path}
        path={this.Info.path + "/:id?"}
        component={(props) => (
          <MediaStockCategory
            match={props.match}
            location={props.location}
            history={props.history}
          />
        )}
      />
    );
  }

  get title() {
    return AppContext.r["category"];
  }

  get apiUrl() {
    return AppContext.s["net-api-url"];
  }

  get apiPath() {
    return "/mediastock/categories";
  }

  get imageUrl() {
    return this.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
  }

  get schema() {
    return yup.object({
      title: yup.string().required(),
    });
  }

  get tableHead() {
    return [
      {
        Title: AppContext.r["title"],
        Adapter: function(o) {
          const style = {
            backgroundColor: o.color,
          };

          return (
            <div>
              <div className="category-dot" style={style}>
                &nbsp;
              </div>
              {o.title}
            </div>
          );
        },
      },
      {
        Title: AppContext.r["role"],
        Adapter: function(o) {
          if (o.role_id === 1) return "Banner";
          if (o.role_id === 2) return "Card";
          return "";
        },
      },
      {
        Adapter: (o) => {
          return (
            <div className="td-v-center">
              <div className="row-thumb">
                {o.media && o.media.length > 0 && (
                  <img
                    src={this.apiUrl + this.apiPath + "/" + o.id + "/image"}
                    alt="Thumb"
                  />
                )}
              </div>

              {o.name}
            </div>
          );
        },
      },
      {
        Title: AppContext.r["published"],
        Field: "published",
        Adapter: (o) => {
          return (
            <div className="td-v-center">
              <ActionButton
                selected={o.published}
                action={async () => {
                  const responseObj = await this.onTogglePublished(o);

                  if (responseObj) {
                    const records = [...this.state.records];

                    for (let item of records) {
                      if (item && item.id === responseObj.id) {
                        item.published = responseObj.published;
                      }
                    }

                    this.setState({
                      records: records,
                    });
                  }
                }}
              >
                {o.published ? AppContext.r["yes"] : AppContext.r["no"]}
              </ActionButton>
            </div>
          );
        },
      },
    ];
  }

  onTogglePublished = async (o) => {
    const item = {
      ...o,
      published: !o.published ? 1 : 0,
    };

    return await this.update(item);
  };

  get initialValues() {
    return {
      title: "",
      published: true
    };
  }

  roleOptions = [
    {
      id: 0,
      title: AppContext.r["none"],
    },
    {
      id: 1,
      title: "Banner",
    },
    {
      id: 2,
      title: "Card",
    },
  ];

  async beforeComponentDidMount() {
    const myStoresResponse = await UderlyApi.MyStores();

    if (
      myStoresResponse &&
      myStoresResponse.status === 200 &&
      myStoresResponse.data.data[0]
    ) {
      const store = myStoresResponse.data.data[0];

      console.log(store);

      this.filters = {
        storeSubdomain: store.subdomain
      };

      this.setState({
        store: store,
      });
    }
  }

  insertDataAdapter = (values) => {
    const { media, store } = this.state;

    values["media"] = media;
    if (store) values["store_id"] = store.id;

    return values;
  };

  updateDataAdapter = (values) => {
    if (this.state.media) values["media"] = this.state.media;

    return values;
  };

  // TODO: Move

  onImageFileChanged = (imageFile) => {
    const { item } = this.state;

    const m = [
      {
        name: imageFile.name,
        size: imageFile.size,
        mime_type: imageFile.type,
      },
    ];

    this.setState({
      media: m,
    });

    if (item.id) this.update({ ...item, media: m });
  };

  get mask() {
    this.formImageRatio = 1;

    return ({ handleSubmit, handleChange, values, touched, errors }) => (
      <Form className="category-form" onSubmit={handleSubmit}>
        <Form.Row style={{ justifyContent: "center" }}>
          <Col md={parseInt(values.role_id) === 1 ? 12 : 6}>
            <MediaStockImage
              variant="cover"
              imageSx={{ width: "100%" }}
              sx={{ width: "100%", height: "auto" }}
              imageUrl={values.id ? this.imageUrl : null}
              apiPath="/mediastock/categories"
              item={this.state.item}
              onImageFileChanged={this.onImageFileChanged}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="8">
            <Form.Label>{AppContext.r["title"]}*</Form.Label>
            <Form.Control
              type="text"
              placeholder={AppContext.r["title"]}
              name="title"
              value={values.title}
              onChange={handleChange}
              isValid={touched.title && !errors.title}
              isInvalid={!!errors.title}
            />
          </Form.Group>

          <Form.Group as={Col} xs="9" md="2">
            <Form.Label>{AppContext.r["role"]}</Form.Label>

            <RemoteSelect
              name="role_id"
              key={values.role_id}
              value={values.role_id}
              onChange={handleChange}
              options={this.roleOptions}
              fieldToMap="title"
            />
          </Form.Group>

          <Form.Group as={Col} xs="3" md="2">
            <Form.Label>{AppContext.r["published"]}</Form.Label>
            <ToggleButton
              className="published-button"
              toggled={values.published}
              on={AppContext.r["yes"]}
              off={AppContext.r["no"]}
              onToggle={(value) => {
                var item = { ...this.state.item, ...values };
                item.published = value;

                this.setState({ item: item });
                this.onChange(item);
              }}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} xs="12">
            <Form.Label>{AppContext.r["description"]}</Form.Label>
            <Form.Control
              type="text"
              placeholder={AppContext.r["description"]}
              name="description"
              value={values.description}
              onChange={handleChange}
            />
          </Form.Group>

          {/* <Form.Group as={Col} xs="3">
                        <Form.Label>{AppContext.r["color"]}*</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["color"]} name="color" maxLength="7"
                                        value={values.color} onChange={handleChange} />
                    </Form.Group> */}
        </Form.Row>

        {this.formFooter}
      </Form>
    );
  }
    
  onReorder = async (items, oldIndex, newIndex) => {
      console.log("Reorder", items[oldIndex], newIndex);

      const q0 = items[oldIndex]; // Item to move
      const q1 = items[newIndex]; // Item to be next

      if(q0 && q1) {
          await MediaStockApi.ReorderCategories(q0.id, q1.id);
      }
  }
}
