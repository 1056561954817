import axios from 'axios';

import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import JsonToTable from "framework/JsonToTable";

export default class ShopItemCategory extends Module {
    info = ShopItemCategory.Info;
    
    enableInsert = true;
    enableDelete = false;

    get shopId () { return 1; }
    
    get pathname () {
        return "/shop/categories";
    }
    
    static Info = {
        nameResource: "shop-item-categories",
        "icon": "fas fa-folder",
        "path": "/shop/categories",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItemCategory match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["shop-item-categories"];
    }

    get apiPath() {
        return '/shopitems/categories';
    }
    
    get recordsApiPath() {
        return '/shopitems/categories?shopId=1';
    }

    get schema () {
        return yup.object({
            name: yup.string().required(),
            description: yup.string(),
            product_code_prefixes: yup.string().matches(/^$|^[a-zA-Z,]+$/, 'Is not in correct format')
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },{
                "Title": AppContext.r["name"],
                "Field": "name"
            },
            {
                "Title": "Code Prefixes",
                "Field": "product_code_prefixes"
            },{
                "Title": AppContext.r["description"],
                "ClassName": "d-none d-sm-table-cell",
                "Adapter": function(o) {
                    const maxLength = 60;

                    if(o.description && o.description.length > maxLength)
                        return o.description.substr(0, maxLength) + "...";
                    return o.description;
                }
            },{
                "Title": "",
                "ClassName": "item-icons",
                "Adapter": function(o) {
                    const icons = [];

                    if(o.image) icons.push(<i className="far fa-image"></i>);

                    return icons;
                }
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
            shop_id: this.shopId
        };
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    updateDataAdapter = (values) => {
        // console.log(values);
        delete values["created_at"];
        delete values["updated_at"];
        delete values["product_code_prefixes_array"];
        // console.log(values);
        return values;
    }

    get mask () {
        const subCategories = !this.state.item || this.state.subCategories === undefined ? "" :
            <div>
                <h2>Sub-Categories</h2>
                <JsonToTable head={this.tableHead} body={this.state.subCategories} />
            </div>;

        return ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="10">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control as="textarea" rows="5" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange}
                                                isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Product Code Prefix(es)</Form.Label>
                                <Form.Control type="text" placeholder="Product Code Prefix(es)" name="product_code_prefixes"
                                                value={values.product_code_prefixes} onChange={handleChange}
                                                isValid={touched.product_code_prefixes && !errors.product_code_prefixes} isInvalid={!!errors.product_code_prefixes} />
                            </Form.Group>
                        </Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>
                
                {subCategories}

                {this.formFooter}
            </Form>);
    }

    // TODO: move code to "fetchSubCategories"

    async onFetchedItem (item) {
        this.setState({
            subCategories: []
        });

        const url = AppContext.s["host-url"] + this.recordsApiPath + "&parentId=" + item.id;
        const res = await axios.get(url);
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        console.log(url, res);
        this.setState({
            subCategories: data
        });
    }

    // render() {
    //     return this.listModeRender(AppContext.r["shop-item-category"], AppContext.r["shop-item-categories"]);
    // }
}