import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

import AppContext from 'app/AppContext';
import WoolsafeApi from 'api/WoolSafe';
import StoreWidget from './StoreWidget';
import TripsWidget from './TripsWidget';

class Dashboard extends React.Component {
    state = {
        shop: null,
    };

    async componentDidMount() {
        const response = await WoolsafeApi.MyShops();

        if(response) {
            if(response.data.data && response.data.data.length > 0) {
                AppContext.s['shop-id'] = response.data.data.id;

                this.setState({
                    shop: response.data.data[0]
                });
            } else {
                this.setState({
                    shop: -1
                })
            }
        }
    }

    onWidgetStatusChanged(status) {
        //console.log(status);
    }

    render() {
        const { shop } = this.state;
        const { user } = this.props.auth;

        if(!shop || !user)
            return AppContext.r["preloader"];

        console.log(user, shop, user.roles, user.roles[0])

        let map = "";

        if(shop === -1) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else if(shop.active === 0) {
            map = <h3 className="text-center">{AppContext.r["shop-not-active"]}</h3>
        } else if(!user.roles[0]) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else {
            const widgets = [];

            if(user.roles[0].id === 1 || user.roles[0].id === 2) {
                widgets.push({
                    "name": "Events",
                    "status": { "collapsed": shop.stripe_id },
                    "sm": 6
                });
            }
    
            if(user.roles[0].id === 3) {
                widgets.push({
                    "name": "Store",
                    "status": { "collapsed": shop.stripe_id },
                    "sm": 12
                });
                widgets.push({
                    "name": "Trips",
                    "status": { },
                    "sm": 12
                });
            }
            console.log(widgets)
            
            let i = 0;
            map = widgets.map(w => (
                <Col xl={w.xl} sm={w.sm} key={i++}>
                    {w.name === "Store" && 
                        <StoreWidget className="store" store={shop} collapsed={w.status.collapsed}
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                    {w.name === "Trips" && 
                        <TripsWidget store={shop} />}
                    {/*{w.name === "UserAccesses" && 
                        <MenuPartyUserAccessesWidget className="graph" restaurant={shop} collapsed={w.status.collapsed}
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>} */}
                </Col>)
            );
        }
        
        return (
            <div className="home">
                <Row>
                    {map}
                </Row>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(Dashboard);