import React from 'react';
import { format } from 'date-fns'
import { Button } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {Link} from 'react-router-dom';

import AppContext from 'app/AppContext';
import asDashboardWidget from 'framework/asDashboardWidget.js';
import CoreApi from 'api/Core';
import IqSpaceApi from 'api/TriviaPro';
import { withModal } from 'framework/withModal.js';
import QuizConsole from './_QuizConsole';
import JsonToTable from 'framework/JsonToTable';
import ActionButton from 'framework/ActionButton';

class QuizWidget extends React.Component {
    state = {
        "events": null,
        "event": null,
    };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <div className="row-thumb">
                                {o.image && <img src={AppContext.s["api-url"] + "/events/" + o.id + "/image/thumb"} alt="Thumb" /> }
                            </div>

                            {o.title}
                        </div>);
                }
            },
            {
                "Title": AppContext.r["price"],
                "Adapter": (o) => {
                    let p = this.props.store.locale.currency_symbol + " " + o.price;

                    if(o.price === 0)
                        p = AppContext.r["free"];

                    return (
                        <div className="td-v-center">
                            {p}
                        </div>);
                }
            },
            {
                "Title": AppContext.r["time"],
                "Adapter": (o) => {
                    const date = new Date(o.date_time.substr(0, 19));
                    const readableDate = format(date, "H:mm");
                    return <>
                        <div className="td-v-center" style={{minWidth: "100px"}}>
                            {readableDate}
                        </div>
                    </>;
                }
            },
            {
                "Adapter": (o) => {
                    return (
                        <div className="td-v-center">
                            <ActionButton className="start-game"
                                    action={async () => await this.startGame(o)}>
                                <Button variant="contained" color="primary" size="large"
                                    startIcon={<PlayCircleOutlineIcon />}>{AppContext.r["start-game"]}</Button>
                            </ActionButton>
                        </div>);
                }
            }
        ];
    }

    async componentDidMount() {
        this.fetchEvents();
    }

    fetchEvents = async () => {
        const eventsToday = await IqSpaceApi.Events(0);
        const events = eventsToday.data.data.filter(e => e.published);
        // Supposed to be only ONE game event per account
        const gameEvents = events.filter(e => e.games.length > 0 && e.games[0].game_status < 30); 
        const currentEvent = events.length === 1 ? events[0] : ((gameEvents && gameEvents.length > 0) ? gameEvents[0] : null);
        const detailedEventResponse = !currentEvent ? null: await IqSpaceApi.Event(currentEvent.id);
        const event = detailedEventResponse ? detailedEventResponse.data.data : null;

        console.log(event)

        this.setState({
            events: events,
            event: event
        });
    }


    startNewGame = async () => {
        const date = new Date();

        const event = {
            title: "IQ Space " + format(date, "d/MM/Y - H:mm"),
            date_time: format(date, "Y-MM-dd HH:mm:00"),
            price: 0,
            published: true
        };

        const url = AppContext.s["api-url"] + "/events";
        // console.log(url, event);

        const response = await CoreApi.createItem(url, event);

        // console.log(response);
        if(response && response.data.data) {
            const event = response.data.data;
            const detailedEventResponse = await IqSpaceApi.Event(event.id);
            // console.log(detailedEventResponse);

            if(detailedEventResponse && detailedEventResponse.data.data) {
                const detailedEvent = detailedEventResponse.data.data;

                // Actually start the game
                await IqSpaceApi.StartGame(event.id);

                // Retrieve event details
                this.setState({
                    event: detailedEvent
                });
            }
        }
    }

    startGame = async (event) => {
        // Retrieve detailed information about the event
        const response = await IqSpaceApi.Event(event.id);

        // Select the event
        this.setState({
            event: response.data.data
        });

        // Actually start the game
        await IqSpaceApi.StartGame(event.id);
    }

    render() {
        const { event, events } = this.state;

        if(!events) {
            return AppContext.r["preloader"];
        } else {
            if(events.length === 0 && !event) {
                return (<div className="no-events">
                        <h2>{AppContext.r["no-events-today"]}</h2>

                        <ActionButton className="start-game"
                                action={this.startNewGame}>
                            <Button variant="contained" color="primary" size="large"
                                startIcon={<PlayCircleOutlineIcon />}>{AppContext.r["start-new-game"]}</Button>
                        </ActionButton>

                        <p>{AppContext.r["or"].toLowerCase()} <Link to="/events/insert">{AppContext.r["plan-an-event"]}</Link>.</p>
                    </div>);
            }

            if(event) {
                return <QuizConsole event={event} setOverlayMessage={this.props.setOverlayMessage}
                    onGameDeleted={async () => {
                        this.setState({ event: null });
                        await this.fetchEvents()}
                    } />
            }

            return (<>
                <h2>{AppContext.r["events-today"]}</h2>
                
                <JsonToTable head={this.tableHead} body={events} />
            </>);
        }
    }
}

export default withModal(asDashboardWidget(QuizWidget));