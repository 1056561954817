import React from 'react';
import { Form, Col } from 'react-bootstrap';

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import RemoteSelect from '../../../framework/RemoteSelect';
import {withModal} from 'framework/withModal';
import QuickModule from 'framework/QuickModule';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);
AppContext.yupAddUrlTest(yup);

class _Streams extends React.Component {
    state = { };

    get tableHead () {
        return [
            {
                "Title": AppContext.r["title"],
                "Field": "title",
            },
            {
                "Title": "URL",
                "Adapter": (o) => {
                    const maxLength = 20;

                    if(o.url.length < maxLength)
                        return o.url;
                    
                    return ("..." + o.url.substr(Math.max(0, o.url.length - maxLength), o.url.length));
                }
            },{
                "Title": AppContext.r["url"],
                "Adapter": (o) => {
                    return o.type.name;
                }
            }
        ];
    }

    get schema () {
        return yup.object({
            url: yup.string().url().nullable()
        });
    }

    async componentDidMount() {
        // Fetch Stream Types
        const response = await CoreApi.StreamTypes();
        
        if(response && response.data) {
            this.setState({
                streamTypes: response.data.data
            });

            this.streamTypeOptions = CoreApi.mapData(response.data.data, "id");
        }
    }

    view = ({ handleChange, values, touched, errors }) => (
        <>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["title"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                    value={values.title} onChange={handleChange}/>
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>URL *</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["url"]} name="url" autoFocus
                                    value={values.url} onChange={handleChange}
                                    isValid={touched.url && !errors.url} isInvalid={!!errors.url} />
                </Form.Group>

                <Form.Group as={Col} md="4" xs="6">
                    <Form.Label>{AppContext.r["stream-type"]}</Form.Label>

                    <RemoteSelect name="stream_type_id" key={values.stream_type_id} value={values.stream_type_id} onChange={handleChange}
                                options={this.streamTypeOptions} fieldToMap="name" />
                </Form.Group>
            </Form.Row>

            {/* Shown only on editing */}
            {/* {(values.id) &&
                <Form.Row>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>} */}
        </>);

    render() {
        const {streamTypes} = this.state;

        if(!streamTypes)
            return AppContext.r["preloader"];

        return <QuickModule initialValues={{"url": "", "stream_type_id": streamTypes.length > 0 ? streamTypes[0].id : -1}}
            className={this.props.className}
            apiPath={'/'+this.props.streamableType+'/'+ this.props.streamableId + '/streams'} 
            tableHead={this.tableHead} schema={this.schema} view={this.view} />;
    }
}

export default withModal(_Streams);