// QuickModule v1.1

import React from 'react';
import { Form, Button, Pagination } from 'react-bootstrap';
import { Formik } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import JsonToTable from 'framework/JsonToTable';
import {withModal} from 'framework/withModal';

class QuickModule extends React.Component {
    state = {
        records: null,
        footerBusy: this.props.footerBusy,
        currentPage: 1
    }

    _apiPath = null;
    get apiPath() {
        if(!this._apiPath)
            throw new Error(AppContext.r["property-not-implemented"]);
         return this._apiPath;
    }

    _tableHead = null;
    get tableHead() {
        if(!this._tableHead)
            throw new Error(AppContext.r["property-not-implemented"]);
        return this._tableHead;
    }

    constructor(props) {
        super (props);

        this._apiPath = props.apiPath;
        this.updateApiPath = props.updateApiPath ? props.updateApiPath : this._apiPath;
        this.createApiPath = props.createApiPath ? props.createApiPath : this._apiPath;
        this.deleteApiPath = props.deleteApiPath ? props.deleteApiPath : this._apiPath;

        // this._view = props.view;
        this._schema = props.schema;
        this._tableHead = [ ...this.props.tableHead ];
        
        this.tableHead.push({
            "Adapter": (o) => {
                const t = this;

                return (
                    <div className="record-actions">
                        {!this.props.disableDelete &&
                            <Button variant="link" className="danger responsive-icon-button"
                                onClick={() => t.onDelete(o)}>
                                <span className="mobile-hidden">{AppContext.r["delete"]}</span> <DeleteIcon />
                            </Button> }

                        {!this.props.disableEdit &&
                            <Button variant="link" className="warning responsive-icon-button"
                                onClick={() => t.onEdit(o)}>
                                <span className="mobile-hidden">{AppContext.r["edit"]}</span> <EditIcon />
                            </Button> }
                    </div>);
            }
        });

        this.initialValues = this.props.initialValues ? this.props.initialValues : {};
    }

    async componentDidMount() {
        await this.fetchRecords(1, this.props.query);
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.footerBusy !== this.props.footerBusy) {
            this.props.modal.setBusy(nextProps.footerBusy);
        }
        if(nextProps.query !== this.props.query)
            this.fetchRecords(1, nextProps.query);
    }

    fetchRecords = async (page = null, query = null) => {
        const { enablePagination } = this.props;

        if(this.props.query && query === null)
            query = this.props.query;

        if(enablePagination && !page)
            page = this.state.currentPage;

        let url = AppContext.s["api-url"] + this.apiPath;
        if(enablePagination && page)
            url += "?page="+page;

        if(query) {
            if(!enablePagination)
                url += "?";
            url += ("&" + query);
        }

        const records = await CoreApi.fetchAsync(url, null, true);
        // console.log(url, records);

        this.setState({
            records: records
        });
    }
    
    onChangePage = async (page) => {
        this.setState({
            currentPage: page,
            records: null
        });

        this.fetchRecords(page);
    }

    generateView = () => {
        const view = (
            <Formik
                enableReinitialize={true}
                initialValues={this.selectedItem}
                validationSchema={this._schema}
                onSubmit={async (values) => {
                    console.log("SUBMIT", values);
                    // Data adapter: remove image field when not specified as a new value
                    // if(values.image && typeof values.image !== "string" && values.image !== -1)
                    //     delete values.image;

                    if(values.id)
                        this.update(values);
                    else
                        this.insert(values);
                }}>

                {({ handleSubmit, handleReset, handleChange, values, touched, errors, setFieldValue }) => (
                    <Form className="form form-label-right"
                        onSubmit={handleSubmit} onReset={handleReset}>

                        {this.props.view({
                            handleSubmit: handleSubmit,
                            handleChange: handleChange,
                            setFieldValue: setFieldValue,
                            values: values,
                            touched: touched,
                            errors: errors
                        })}

                        <div className="modal-footer">
                            <Button variant="secondary" onClick={this.props.modal.hide}>
                                {AppContext.r["cancel"]}
                            </Button>
                            
                            <Button type="submit" variant="danger">
                                {AppContext.r["save"]}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );

        return view;
    }
    
    onEdit = async (o) => {
        //console.log(o);
        this.selectedItem = o;

        if(this.props.onEdit) {
            const r = await this.props.onEdit(o);
            if(r) this.selectedItem = r;
        }

        this.props.modal.setView(AppContext.r["edit"], this.generateView(), true, "wide", null);
    }

    onInserted = async (item) => {
        this.props.modal.hide();

        if(this.props.onInserted)
            this.props.onInserted(item);

        await this.fetchRecords();
    }

    onInsert = () => {
        this.selectedItem = this.initialValues;

        this.props.modal.setView(AppContext.r["new"], this.generateView(), true, "wide", null);
    }

    insert = async (values) => {
        this.props.modal.setBusy();
        //console.log("INSERT", values);

        const item = {
            ...values,
        }

        const response = await CoreApi.createItem(AppContext.s["api-url"] + this.createApiPath, item);
        //console.log(response);
        this.props.modal.setBusy(false);

        if(response && response.status === 201)
            await this.onInserted(item);
        else
            this.props.modal.setMessage("error");
    }

    update = async (o) => {
        this.props.modal.setBusy();
        //console.log("UPDATE", o);

        if(o.image_name === null || o.image_name === undefined)
            delete o["image"];

        await CoreApi.updateItem(AppContext.s["api-url"] + this.updateApiPath + "/" + o.id, o);
        await this.fetchRecords();
        
        this.props.modal.hide();
    }

    delete = async (o) => {
        this.props.modal.setBusy();
        console.log("DELETE", o);

        const response = await CoreApi.deleteItem(AppContext.s["api-url"] + this.deleteApiPath + "/" + o.id);
        console.log(response);

        if(response && this.props.onDeleted) // TODO: Check if actually deleted server-side?
            this.props.onDeleted(o);
            
        await this.fetchRecords();
        
        this.props.modal.hide();
    }

    onDelete = (o) => {
        this.props.modal.confirm(AppContext.r["confirm-delete-heading"], AppContext.r["confirm-delete"], 
            async () => await this.delete(o));
    }

    render() {
        const { records } = this.state;
        const { enablePagination } = this.props;

        if(!records)
            return AppContext.r["preloader"];

        // Pagination
        const pItems = [];

        if(enablePagination && records.last_page > 1) {
            const active = this.state.currentPage;
        
            for (let number = 1; number <= records.last_page; number++) {
                pItems.push(
                    <Pagination.Item key={number} active={number === active}
                        onClick={() => this.onChangePage(number)}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }

        return (
            <>
                <JsonToTable head={this.tableHead} body={records.data ? records.data : []} 
                    onInsert={this.props.disableInsert ? null : this.onInsert} />
            
                { pItems.length > 0 && 
                    <Pagination size="sm">{pItems}</Pagination> }
            </>
        );
    }
}

export default withModal(QuickModule);