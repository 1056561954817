import React from 'react';
import { Formik } from 'formik';
import { Row, Col, Form, Button, Modal as BSModal } from 'react-bootstrap';
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import JsonToTable from "framework/JsonToTable";
import RafalaApi from 'api/Rafala';
import CoreApi from 'api/Core';

// import React from 'react';
// import { Col, Form } from 'react-bootstrap';
// import * as yup from 'yup';

// import {ContentRoute} from "_metronic/layout";

// import AppContext from 'app/AppContext';
// import Module from "app/modules/Module";

import ShopItemCategory from './ShopItemCategory';

export default class ShopItemEnotecaCategory extends ShopItemCategory {
    info = ShopItemEnotecaCategory.Info;

    get shopId () { return 2; }
    
    get pathname () {
        return "/enoteca/categories";
    }
    
    static Info = {
        nameResource: "shop-item-enoteca-categories",
        "icon": "fas fa-folder",
        "path": "/enoteca/categories",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItemEnotecaCategory match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["shop-item-enoteca-categories"];
    }

    get recordsApiPath() {
      return '/shopitems/categories?shopId=2';
    }

    // render() {
    //     return this.listModeRender(AppContext.r["shop-item-enoteca-category"],AppContext.r["shop-item-enoteca-categories"]);
    // }
}