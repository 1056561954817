import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

import AppContext from 'app/AppContext';
import VaffaApi from 'api/Vaffa';
import TouchesWidget from './TouchesWidget';
// import StoreWidget from './StoreWidget';

class Dashboard extends React.Component {
    state = {
        store: null,
    };

    async componentDidMount() {
        const response = await VaffaApi.MyStores();
        console.log(response);

        if(response) {
            if(response.data.data && response.data.data.length > 0) {
                this.setState({
                    store: response.data.data[0]
                });
            } else {
                this.setState({
                    store: -1
                });
            }
        }
    }

    render() {
        const { store } = this.state;
        const { user } = this.props.auth;

        if(!store || !user)
            return AppContext.r["preloader"];

        let map = "";

        if(store === -1) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else if(store.active === 0) {
            map = <h3 className="text-center">{AppContext.r["shop-not-active"]}</h3>
        } else if(!user.roles[0]) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else {
            const widgets = [{
                "name": "Contacts",
                "status": { "collapsed": false },
                "xl": 12
            }];
    
            // if(user.roles[0].id === 3) {
            //     widgets.push({
            //         "name": "Store",
            //         "status": { "collapsed": false },
            //         "xl": 12
            //     });
            // }

            let i = 0;
            map = widgets.map(w => (
                <Col xl={w.xl} sm={12} key={i++}>
                    <TouchesWidget store={store} />
              {/*      {w.name === "Store" && 
                        <StoreWidget className="store" collapsed={w.status.collapsed} 
                            store={store} onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
 
                    {w.name === "Events" && 
                        <EventsWidget store={store} disableInsert />} */}
                </Col>)
            );
        }
        
        return (
            <div className="home iq-dashboard">
                <Row>
                    {map}
                </Row>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(Dashboard);