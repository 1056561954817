import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

import AppContext from 'app/AppContext';
import WoolsafeApi from 'api/WoolSafe';
import StandWidget from './StandWidget';
import EventsWidget from './EventsWidget';

class Dashboard extends React.Component {
    state = {
        shop: null,
    };

    async componentDidMount() {
        const response = await WoolsafeApi.MyShops();
        // console.log(response)

        if(response) {
            if(response.data.data && response.data.data.length > 0) {
                AppContext.s['shop-id'] = response.data.data.id;

                this.setState({
                    shop: response.data.data[0]
                });
            } else {
                this.setState({
                    shop: -1
                })
            }
        }
    }

    onWidgetStatusChanged(status) {
        //console.log(status);
    }

    render() {
        const { shop } = this.state;
        const { user } = this.props.auth;

        if(!shop || !user)
            return AppContext.r["preloader"];
            console.log(shop)

        let map = "";

        if(shop === -1) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else if(shop.active === 0) {
            map = <h3 className="text-center">{AppContext.r["shop-not-active"]}</h3>
        } else if(!user.roles[0]) {
            map = <h3 className="text-center">{AppContext.r["no-role"]}</h3>
        } else {
            // const widgets = user.roles[0].id === 8 ? [] : [
            //     {
            //         "name": "YourShop",
            //         "status": { "collapsed": false },
            //         "xl": 6
            //     },
            //     {
            //         "name": "UserAccesses",
            //         "status": { "collapsed": false },
            //         "xl": 6
            //     },
            // ];
            const widgets = [];

            if(user.roles[0].id === 1 || user.roles[0].id === 2) {
                widgets.push({
                    "name": "Events",
                    "status": { /*"collapsed": shop.stripe_id*/ },
                    "sm": 12
                });
                // widgets.push({
                //     "name": "StripeStore",
                //     "status": { /*"collapsed": shop.stripe_id*/ },
                //     "sm": 12
                // });
            }
    
            if(user.roles[0].id === 3 || user.roles[0].id === 2){
                widgets.push({
                    "name": "Store",
                    "status": { /*"collapsed": shop.stripe_id*/ },
                    "sm": 12
                });
            }
            
            let i = 0;
            map = widgets.map(w => (
                <Col xl={w.xl} sm={w.sm} key={i++}>
                    {w.name === "Events" && 
                        <EventsWidget className="events-widget" />}
                    {w.name === "Store" && 
                        <StandWidget className="store" store={shop} collapsed={w.status.collapsed} disableCollapse
                                onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                    {/*{w.name === "StripeStore" && 
                        <StandWidget className="store" store={shop} collapsed={w.status.collapsed} disableCollapse enableStripe
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                    {w.name === "UserAccesses" && 
                        <MenuPartyUserAccessesWidget className="graph" restaurant={shop} collapsed={w.status.collapsed}
                            onWidgetStatusChanged={this.onWidgetStatusChanged}/>} */}
                </Col>)
            );
        }
        
        return (
            <div className="home">
                <Row>
                    {map}
                </Row>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(Dashboard);