// export default function loadClientResources (resources) {
//     resources["product-code"] = "Product Code";
//     resources["package-quantity"] = "Package Quantity";
//     resources["package-size"] = "Package Size";
//     resources["packaging"] = "Packaging";
//     resources["placed-at"] = "Placed At";
//     resources["requested-date"] = "Requested Date";
//     resources["note"] = "Note";
//     resources["status"] = "Status";
//     resources["order-id-is"] = "This order ID is";
//     resources["user"] = "User";
//     resources["shop-item-enoteca-category"] = "Enoteca's Category";
//     resources["shop-item-enoteca-categories"] = "Enoteca's Categories";
//     resources["shop-item-enoteca-brand"] = "Enoteca's Brand";
//     resources["shop-item-enoteca-brands"] = "Enoteca's Brands";
//     resources["filter-not-available"] = "Filter Not Available";
//     resources["clear-filter-not-available"] = "Clear Filter Not Available";
//     resources["warehouse"] = "Warehouse";
//     resources["warehouses"] = "Warehouses";
//     //resources["order-type"] = [];
//     //resources["order-type"][1] = "Order";
//     resources["internal-order"] = "Internal Order";
//     resources["internal-orders"] = "Internal Orders";
//     resources["placed-by"] = "Placed By";
//     resources["percent-volume"] = "% Alcohol";
// }
import React from 'react';

export default function loadClientResources (resources) {
    resources["product-code"] = "Product Code";
    resources["package-quantity"] = "Package Quantity";
    resources["package-size"] = "Package Size";
    resources["packaging"] = "Packaging";
    resources["done-at"] = "Done At";
    resources["placed-at"] = "Placed At";
    resources["requested-date"] = "Requested Date";
    resources["note"] = "Note";
    resources["status"] = "Status";
    resources["order-id-is"] = "This order ID is";
    resources["user"] = "User";
    resources["shop-item-enoteca-category"] = "Enoteca's Category";
    resources["shop-item-enoteca-categories"] = "Enoteca's Categories";
    resources["shop-item-enoteca-brand"] = "Enoteca's Brand";
    resources["shop-item-enoteca-brands"] = "Enoteca's Brands";
    resources["filter-not-available"] = "Filter Not Available";
    resources["clear-filter-not-available"] = "Clear Filter Not Available";
    resources["warehouse"] = "Warehouse";
    resources["warehouses"] = "Warehouses";
    resources["order-type"] = [];
    resources["order-type"][2] = "Delivery";
    resources["order-type"][3] = "Collection";
    resources["order-type"][4] = "Quote Request";
    resources["internal-order"] = "Internal Order";
    resources["internal-orders"] = "Internal Orders";
    resources["placed-by"] = "Placed By";
    resources["percent-volume"] = "% Alcohol";
    resources["stock"] = "Stock";
    resources["supply-stock"] = "Supply Stock";
    resources["supply-stock-confirm"] = "Would you like to supply the stock with {item}?";
    resources["transfer-stock"] = "Transfer Stock";
    resources["transfer-stock-confirm"] = "Would you like to transfer the {item} stock?";
    resources["previous-stock-level"] = "Previous Level";
    resources["new-stock-level"] = "New Level";
    resources["from-warehouse"] = "From Warehouse";

    resources["shop"] = "Shop";
    resources["locale"] = "Locale";
    resources["shop-items"] = "Shop Items";
    resources["shop-item"] = "Shop Item";
    resources["enoteca-item"] = "Enoteca Item";
    resources["enoteca-items"] = "Enoteca Items";
    resources["enoteca-orders"] = "Enoteca Orders";
}