// ColorPicker v1.0

import React from 'react';
import { Form } from 'react-bootstrap';
import Popover from '@material-ui/core/Popover';
import { ChromePicker } from 'react-color';

import AppContext from 'app/AppContext';

export default class ColorPicker extends React.Component {
    state = {
        color: this.props.value ? this.props.value : '#ffffff',
        popOpen: false,
        anchorEl: null
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                color: nextProps.value
            });
        }
    }
  
    handleChangeComplete = (color) => {
        this.setState({
            color: color.hex
        });

        if(this.props.onChange)
            this.props.onChange(color.hex);
    };

    render() {
        return (
            <>
                <Popover 
                    open={this.state.popOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.setState({ popOpen: false })}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <ChromePicker 
                        color={ this.state.color } onChangeComplete={ this.handleChangeComplete } />
                </Popover>

                <Form.Control type="text" placeholder={AppContext.r["color"]} 
                    value={this.state.color} maxLength={7} readOnly
                    style={{
                        backgroundColor: this.state.color,
                        color: "white"
                    }}
                    onClick={(event) => {
                        this.setState({
                            anchorEl: event.target,
                            popOpen: true
                        });
                    }} />
            </>);
    }
}