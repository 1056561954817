import React from 'react';
import { Link } from 'react-router-dom';

import asDashboardWidget from 'framework/asDashboardWidget.js';
import JsonToTable from 'framework/JsonToTable.js';
import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
import Touch from "../../pages/Core/Touch";

class TouchesWidget extends React.Component {
    state = {
        store: this.props.store,
        contacts: null
    };

    async componentDidMount() {
        this.head = [ ...Touch.head ];

        const response = await CoreApi.Touches();
        console.log(response);

        this.setState({
            contacts: response.data.data.slice(0, 10)
        });
    }

    render() {
        const { store, contacts } = this.state;

        if(!store || !contacts)
            return AppContext.r["preloader"];

        return (
            <>
                <h1>{AppContext.r["recent-touches"]}</h1>

                <div className="touches">
                    <JsonToTable head={this.head} body={contacts}
                        onRowClick={(o) => AppContext.history.push("/contacts/" + o.id)} />

                    <p>
                        <Link to="/contacts">{AppContext.r["all-touches"]}</Link>
                    </p>
                </div>
            </>
        );
    }
}

export default asDashboardWidget(TouchesWidget);