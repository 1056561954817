export default function loadClientSettings(settings) {
    // settings["project-id"] = ProjectIdsEnum.MenuParty;
    settings["language"] = "en";
    settings["website"] = "api.uderly.com";
    settings["project-name"] = "Uderly Pro";
    //settings["logo-dark"] = "/assets/images/menupro/logo.png";
    //settings["logo-light"] = "/assets/images/menupro/logo.png";
    //settings["mini-logo-dark"] = "/assets/images/menupro/mini-logo.png";
    //settings["mini-logo-light"] = "/assets/images/menupro/mini-logo.png";
    //settings["logo-path"] = "/assets/images/menuparty/logo.png";
    //settings["mini-logo-path"] = "/assets/images/menuparty/mini-logo.png";

    settings["play-api-url"] = "https://play.uderly.com/api/squizzer/";
    // settings["play-api-url"] = "http://localhost:56186/api/squizzer/";
    settings["access-domain"] = "menu.party";
}
