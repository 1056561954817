import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

// import AppContext from 'app/AppContext';
import OrdersWidget from './OrdersWidget';

class Dashboard extends React.Component {

    render() {
        const {auth} = this.props;
        console.log(auth.user.roles)

        return (
            <div className="home">
                {(auth && auth.user && auth.user.roles && auth.user.roles[0].id === 3) &&
                    <Row>
                        <Col xs={12}>
                            <OrdersWidget />
                        </Col>
                    </Row> }
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(Dashboard);