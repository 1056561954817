import React from 'react';
import { Field, FieldArray } from 'formik';
import { Col, Form, Modal as BSModal } from 'react-bootstrap';
import { format } from 'date-fns'
import * as yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import RemoteSelect from "framework/RemoteSelect";
import CoreApi from 'api/Core';

export default class InternalOrder extends Module {
    info = InternalOrder.Info;

    enableInsert = true;
    enableDelete = true;

    state = {
        ...this.state,
        totalAmount: 0
    }

    get pathname () {
        return "/internalorders";
    }
    
    static Info = {
        nameResource: "internal-orders",
        icon: "fas fa-sort-amount-down",
        path: "/internalorders",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <InternalOrder match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["internal-orders"];
    }

    async onBeforeFetchRecords() {
        // const myShops = await MenuParty.MyShops();
        // if(myShops && myShops.data.data && myShops.data.data.length > 0) {
        //     this.shopId = myShops.data.data[0].id;
        //     // this.recordsApiPath = '/shop/'+this.shopId+'/users';

        //     console.log(this._recordsApiPath);
        // }
    }

    onFetchedItem = (item) => {
        if(item) {
            const totalAmount = this.getTotalAmount(item);

            this.setState({
                totalAmount: totalAmount,
                paid: (item.payment1 !== null || item.payment2 !== null || item.payment3 !== null) &&
                    (item.payment1 + item.payment2 + item.payment3) >= totalAmount
            })
        }
    }

    _shopId = 1;
    set shopId (value) { this._shopId = value; }
    get shopId () { 
        return this._shopId;
    }

    get apiPath() {
        return '/shop/'+this.shopId+'/internalorders';
    }

    get itemApiPath() {
        return '/shop/internalorders';
    }

    get updateApiPath() {
        return '/shop/internalorders';
    }

    get schema () {
        return yup.object({
            note: yup.string()
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id"
          },{
            "Title": AppContext.r["placed-by"],
            "Adapter": function(o) {
                return (o.user === null) ? "" : o.user.name + " " + ((o.user.surname !== null) ? o.user.surname : "");
            }
          },{
            "Title": AppContext.r["placed-at"],
            "Adapter": (o) => format(new Date(o.created_at), 'd/MM/yyyy h:mm')
          },{
            "Title": AppContext.r["note"],
            "Field": "note"
          },{
            "Title": AppContext.r["status"],
            "Field": "status",
            "Adapter": function(o) {
                return (o.status === null) ? "" : o.status.title;
            }
          }
        ];
    }

    get initialValues() {
        return {
            created_at: "",
            updated_at: "",
            note: "",
            status: null,
        };
    }

    static updateInterval = 3000; // ms

    interval = null;

    async beforeComponentDidMount() {
        // Fetch statutes
        this.orderStatusOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/internalorders/statuses", "id");
        // Fetch customers
        this.customersOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/customers", "id");
    }

    updateDataAdapter = (values) => {
        delete values["created_at"];
        delete values["updated_at"];
        return values;
    }

    extras(extras) {
        if(extras) {
            //console.log(extras);
            const m = extras.map(x => <span className="extra" key={x.id}>{x.name}</span>)
    
            return (
                <div className="extras">
                    { m }
                </div>);
        }
        
        return "";
    }

    formatAddress(c) {
        let a = c.address1;
        if(c.address2 && c.address2.length > 0) a += ", " + c.address2;
        a += " - " + c.post_code;
        a += " - " + c.city;
        return a;
    }

    getTotalAmount = (item = null) => {
        if(!item) item = this.state.item;
        let totalAmount = 0;

        for(let v of item.items)
            totalAmount += parseFloat(v.pivot.price);

        return totalAmount;
    }

    get mask () {
        console.log(this.state.item);

        const u = this.state.item ? this.state.item.user : null;
        const name = u ? (<p>{u.name + " " + u.surname + " (User ID: " + u.id + ")"}</p>) : "";
        const email = u && u.email.length > 0 ? (<span className="mail"><i className="far fa-envelope"></i> <a href={"mailto:"+u.email}>{u.email}</a></span>) : "";
        const mobile = (u && u.mobile_phone && u.mobile_phone.length > 0) ? (<span><i className="fas fa-phone"></i> {u.mobile_phone}</span>) : "";
        // const note = this.state.item && this.state.item.note && this.state.item.note.length > 0 ? (<p className="note">{this.state.item.note}</p>) : "";
        // const orderType = this.state.item ? AppContext.r["order-type"][this.state.item.type.id] : "";

        // const { contact } = this.state.item;
        
        // const contactView = (!contact) ? "" : (
        //     <div className="order-contact">
        //         <h3>{contact.alias}</h3>
        //         {this.formatAddress(contact)}
        //         <hr />
        //     </div>
        // );

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className="order" autoComplete="off">
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["status"]}</Form.Label>

                        <RemoteSelect name="internal_order_status_id" key={values.internal_order_status_id} value={values.internal_order_status_id} onChange={handleChange}
                                        nullOption={false} options={this.orderStatusOptions} fieldToMap="title" />
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["customer"]}</Form.Label>

                        <RemoteSelect name="customer_id" key={values.customer_id} value={values.customer_id} onChange={handleChange}
                                        nullOption={false} options={this.customersOptions} fieldToMap="name" />
                    </Form.Group>
                </Form.Row>

                <Form.Row style={(this.state.item === undefined || this.state.item.id === undefined) ? { display: "none" } : { display: "flex"}}>
                    <Form.Group as={Col} xs="6">
                        <Form.Label>{AppContext.r["placed-at"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["placed-at"]} name="created_at" disabled
                            value={format(new Date(values.created_at), 'd/MM/yyyy h:mm')} onChange={handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} xs="6">
                        <Form.Label>{AppContext.r["updated-at"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["updated-at"]} name="updated_at" disabled
                            value={format(new Date(values.updated_at), 'd/MM/yyyy h:mm')} onChange={handleChange} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs="12">
                        <Form.Label>{AppContext.r["note"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["note"]} name="note" 
                                      value={values.note} onChange={handleChange} />
                    </Form.Group>
                </Form.Row>
                
                {/* <Form.Row>
                    <Form.Group as={Col} xs="12" className="order-user">
                        <h2>{AppContext.r["user"]}</h2>
                        {name}
                        {email}
                        {mobile}
                    </Form.Group>

                    <Form.Group as={Col} xs="12" className="order-contact">
                        {contactView}
                    </Form.Group>
                </Form.Row> */}
                
                <Form.Row>
                    <h2>{AppContext.r["order-details"]}</h2>

                    {/* <h3 className="type">{orderType}</h3> */}

                    {/* {note} */}

                    {/* * Items * */}

                    <Form.Group as={Col} xs="12">
                        <FieldArray name="items"
                            render={arrayHelpers => (
                                <div className="items">
                                    {console.log(values)}
                                    {values.items && values.items.length > 0 ? (
                                        values.items.map((v, index) => (
                                            <div key={index} className="list-item">
                                                <span className="quantity">{values.items[index].pivot.quantity}</span>
                                                <span className="x"> x </span>
                                                <span className="name">{values.items[index].name}</span>

                                                {this.extras(values.items[index].pivot.extra_objects)}

                                                <div className="price">
                                                    <label htmlFor={`items.${index}.pivot.price`}>{AppContext.r['price']}</label>
                                                    
                                                    <Field
                                                        name={`items.${index}.pivot.price`}
                                                        placeholder="0"
                                                        type="text"
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            const { value } = e.target;
                                                            const regex = /^(0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                            if (regex.test(value.toString())) {
                                                                setFieldValue(`items.${index}.pivot.price`, value);

                                                                v.pivot.price = value;

                                                                this.setState({
                                                                    totalAmount: this.getTotalAmount(values)
                                                                });
                                                            }
                                                        }} />
                                                    {/* {errors.items && errors.items[index] && errors.items[index].pivot.price && touched.items && touched.items[index].pivot.price && (
                                                        <div className="field-error">
                                                            {errors.items[index].pivot.price}
                                                        </div>
                                                    )} */}
                                                </div>

                                                <span className="note">{values.items[index].pivot.note}</span>

                                                <hr />
                                            </div>
                                        ))
                                    ) : AppContext.r["no-items-in-the-list"] }
                                    <div>
                                    </div>

                                    { typeof errors.items === 'string' ? <div>{errors.items}</div> : null }
                                </div>
                            )}
                        />

                        <p className="total">{this.state.totalAmount}</p>
                    </Form.Group>
                </Form.Row>

                <Form.Row className="flex-row-reverse">
                    <Form.Group as={Col} xs="4" sm="3">
                        <Form.Label>{AppContext.r["payment"]} 3</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["payment"]} name="payment3" 
                                    value={values.payment3}
                                    onChange={e => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        const regex = /^(\s*|0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        if (regex.test(value.toString())) {
                                            setFieldValue(`payment3`, value);
                                            values.payment3 = value;
                                            
                                            const paid = (values.payment1 !== null || values.payment2 !== null || values.payment3 !== null) 
                                                && (values.payment1 + values.payment2 + values.payment3) >= this.state.totalAmount;

                                            this.setState({ paid: paid });
                                        }
                                    }} />
                    </Form.Group>

                    <Form.Group as={Col} xs="4" sm="3">
                        <Form.Label>{AppContext.r["payment"]} 2</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["payment"]} name="payment2" 
                                      value={values.payment2}
                                      onChange={e => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        const regex = /^(\s*|0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        if (regex.test(value.toString())) {
                                            setFieldValue(`payment2`, value);
                                            values.payment2 = value;
                                            
                                            const paid = (values.payment1 !== null || values.payment2 !== null || values.payment3 !== null) 
                                                && (values.payment1 + values.payment2 + values.payment3) >= this.state.totalAmount;

                                            this.setState({ paid: paid });
                                        }
                                    }} />
                    </Form.Group>

                    <Form.Group as={Col} xs="4" sm="3">
                        <Form.Label>{AppContext.r["payment"]} 1</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["payment"]} name="payment1" 
                                      value={values.payment1} 
                                      onChange={e => {
                                        e.preventDefault();
                                        const { value } = e.target;

                                        // const blank_string_regex = /^\s*$/;
                                        //alert((blank_string_regex.test(value.toString())));
                                        // if (blank_string_regex.test(value.toString())) {
                                        //     setFieldValue(`payment1`, '0');
                                        //     values.payment1 = value;
                                        // }

                                        const regex = /^(\s*|0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                        if (regex.test(value.toString())) {
                                            setFieldValue(`payment1`, value);
                                            values.payment1 = value;
                                        }
                                    
                                        const paid = (values.payment1 !== null || values.payment2 !== null || values.payment3 !== null) 
                                            && (values.payment1 + values.payment2 + values.payment3) >= this.state.totalAmount;

                                        this.setState({ paid: paid });
                                    }} />
                    </Form.Group>
                </Form.Row>

                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    {/* PAID Label */}
                    {(this.state.paid) ? <h2 className="paid-label">PAID</h2> : "" }

                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["order-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}  
            </Form>
        );
    }
}
