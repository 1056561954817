import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';

export default class MediaStock {
    static async ReorderCategories(id0, id1) {
        const params = {
            id0: id0,
            id1: id1
        };

        console.log(AppContext.s["net-api-url"] + "/mediastock/categories/reorder", params);

        return await Axios.put(AppContext.s["net-api-url"] + "/mediastock/categories/reorder/", params)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }
}