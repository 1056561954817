import React from 'react';
import { Redirect } from 'react-router';

export default function asDashboardWidget(OriginalComponent) {
    return class extends React.Component {
        state = {
            overlayMessage: null,
            redirectTo: null,
            collapsed: (this.props.collapsed) ? this.props.collapsed : false,
            maximized: (this.props.maximized) ? this.props.maximized : null // Initially not allowed
        }

        collapse = () => {

        }
        
        setOverlayMessage = (message) => {
            this.setState({
                overlayMessage: message
            });
        }

        setAllowMaximize = (enable) => {
            this.setState({
                maximized: !enable ? null : false
            });
        }

        overlay = () => {
            return this.state.overlayMessage && (
                <div className='widget-overlay-container'>
                    <div className='widget-overlay'>
                        <p className='message'>
                            {this.state.overlayMessage}
                        </p>
                    </div>
                </div>);
        }
            
        imageExists(image_url){
            var http = new XMLHttpRequest();
        
            http.open('GET', image_url, false);
            http.send();
        
            return http.status === 200;
        }

        toggleWidgetSize = () => {
            const collapsed = !this.state.collapsed;

            this.setState({
                collapsed: collapsed
            });

            if(this.props.onWidgetStatusChanged)
                this.props.onWidgetStatusChanged({
                    collapsed: collapsed,
                    maximized: this.state.maximized
                });
        }

        toggleWidgetMaximized = () => {
            const maximized = !this.state.maximized;

            this.setState({
                maximized: maximized
            });

            if(this.props.onWidgetStatusChanged)
                this.props.onWidgetStatusChanged({
                    collapsed: this.state.collapsed,
                    maximized: maximized
                });
        }

        setRedirectTo = (path, data) => {
            this.setState({
                redirectTo: path
            });
        }

        render() {
            if (this.state.redirectTo) {
                return <Redirect push to={this.state.redirectTo} />;
            }

            const newProps = {
                setOverlayMessage: this.setOverlayMessage,
                setRedirectTo: this.setRedirectTo,
                setAllowMaximize: this.setAllowMaximize,
            }

            const c = "widget " 
                + (this.state.collapsed ? " collapsed " : "") 
                + (this.state.maximized ? " maximized " : "") 
                + (this.props.className ? this.props.className : "");

            // Return original component with additional props
            return (
                <div className={c}>
                    <OriginalComponent {...this.props} {...newProps} />
                    
                    {/* <div className="actions">
                        {!this.props.disableCollapse && 
                            <div className="sizer" onClick={this.toggleWidgetSize}>
                                {this.state.collapsed ? 
                                    <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}
                            </div> }

                        { this.state.maximized !== null && 
                            <div className="sizer" onClick={this.toggleWidgetMaximized}>
                                {this.state.maximized ? 
                                    <i className="fas fa-compress-arrows-alt"></i> : <i className="fas fa-window-maximize"></i>}
                            </div> }
                    </div> */}

                    {this.overlay()}
                </div>);
        }
    }
}