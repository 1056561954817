import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import * as yup from "yup";
import { FilePond } from "react-filepond";
import ReactQuill from "react-quill";
import { Field } from "formik";

import { ContentRoute } from "_metronic/layout";

import AppContext from "app/AppContext";
import CoreApi from "api/Core";
import UderlyApi from "api/Uderly";
import Module from "app/modules/Module";
import ActionButton from "../../../framework/ActionButton";
import ToggleButton from "../../../framework/ToggleButton";
import MediaStockImage from "../../../framework/MediaStockImage";
import RemoteSelect from "../../../framework/RemoteSelect";

import "react-quill/dist/quill.snow.css";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

export default class MediaStock extends Module {
  showPagination = true;

  info = MediaStock.Info;

  static Info = {
    nameResource: "media-stock",
    icon: "fa-solid fa-photo-film",
    path: "/mediastock",
    show: true,
  };

  state = {
    media: null,
    files: [], // pondfile files
    ...this.state,
  };

  static get route() {
    return (
      <ContentRoute
        key={this.Info.path}
        path={this.Info.path + "/:id?"}
        component={(props) => (
          <MediaStock
            match={props.match}
            location={props.location}
            history={props.history}
          />
        )}
      />
    );
  }

  get title() {
    return AppContext.r["media-stock"];
  }

  get apiUrl() {
    return AppContext.s["net-api-url"];
  }

  get apiPath() {
    return "/mediastock";
  }

  get imageUrl() {
    return this.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
  }

  get schema() {
    return yup.object({
      title: yup.string().required(),
    });
  }

  async beforeComponentDidMount() {
    // Retrieve the associated store
    const myStoresResponse = await UderlyApi.MyStores();

    if (
      myStoresResponse &&
      myStoresResponse.status === 200 &&
      myStoresResponse.data.data[0]
    ) {
      const store = myStoresResponse.data.data[0];

      const response = await UderlyApi.MediaStockCategories(store.subdomain);
      if (response && response.data)
        this.categoryOptions = CoreApi.mapData(response.data.data, "id");

      const response1 = await UderlyApi.MediaStockRatings(store.subdomain);
      if (response1 && response1.data)
        this.ratingOptions = CoreApi.mapData(response1.data.data, "id");

      const response2 = await UderlyApi.MediaStockChannels(store.subdomain);
      if (response2 && response2.data)
        this.channelOptions = CoreApi.mapData(response2.data.data, "id");

      this.setState({
        store: store,
      });
    }
  }

  get tableHead() {
    return [
      {
        Title: AppContext.r["title"],
        Field: "title",
      },
      {
        Title: AppContext.r["category"],
        Field: "category_id",
        ClassName: "d-none d-sm-table-cell category-col",
        Adapter: function(o) {
          const style = {
            backgroundColor: o && o.category ? o.category.color : "transparent",
          };
          const category = o && o.category ? o.category.title : "";
          return (
            <div>
              <div className="category-dot" style={style}>
                &nbsp;
              </div>
              {category}
            </div>
          );
        },
      },
      {
        Title: AppContext.r["rating"],
        Field: "rating_id",
        ClassName: "d-none d-sm-table-cell category-col",
        Adapter: function(o) {
          const style = {
            backgroundColor: o && o.rating ? o.rating.color : "transparent",
          };
          const rating = o && o.rating ? o.rating.title : "";
          return (
            <div>
              <div className="category-dot" style={style}>
                &nbsp;
              </div>
              {rating}
            </div>
          );
        },
      },
      {
        Adapter: (o) => {
          return (
            <div className="td-v-center">
              <div className="row-thumb">
                {o.media &&
                  o.media.filter((m) => m.collection_name === "image").length >
                    0 && (
                    <img
                      src={
                        this.apiUrl + this.apiPath + "/" + o.id + "/image/thumb"
                      }
                      alt="Thumb"
                    />
                  )}
              </div>

              {o.name}
            </div>
          );
        },
      },
      {
        Field: "is_highlighted",
        Adapter: (o) => {
          return (
            <div className="td-v-center">
              <ActionButton
                selected={o.is_highlighted}
                action={async () => {
                  const responseObj = await this.onToggleHighlighted(o);

                  if (responseObj) {
                    const records = [...this.state.records];

                    for (let item of records) {
                      if (item && item.id === responseObj.id) {
                        item.is_highlighted = responseObj.is_highlighted;
                      }
                    }

                    this.setState({
                      records: records,
                    });
                  }
                }}
              >
                {o.is_highlighted ? (
                  <i className="fa-solid fa-star"></i>
                ) : (
                  <i className="fa-regular fa-star"></i>
                )}
              </ActionButton>
            </div>
          );
        },
      },
      {
        Title: AppContext.r["published"],
        Field: "published",
        Adapter: (o) => {
          return (
            <div className="td-v-center">
              <ActionButton
                selected={o.published}
                action={async () => {
                  const responseObj = await this.onTogglePublished(o);

                  if (responseObj) {
                    const records = [...this.state.records];

                    for (let item of records) {
                      if (item && item.id === responseObj.id) {
                        item.published = responseObj.published;
                      }
                    }

                    this.setState({
                      records: records,
                    });
                  }
                }}
              >
                {o.published ? AppContext.r["yes"] : AppContext.r["no"]}
              </ActionButton>
            </div>
          );
        },
      },
    ];
  }

  onToggleHighlighted = async (o) => {
    const item = {
      ...o,
      is_highlighted: !o.is_highlighted ? 1 : 0,
    };

    return await this.update(item);
  };

  onTogglePublished = async (o) => {
    const item = {
      ...o,
      published: !o.published ? 1 : 0,
    };

    return await this.update(item);
  };

  get initialValues() {
    return {
      title: "",
      published: false,
      category_id: 100,
    };
  }

  itemToFiles = (item) => {
    return item.media
      .filter((f) => f.collection_name !== "image")
      .map((f) => {
        if (f.collection_name === "image") return undefined;
        console.log(f);
        return {
          source: f.file_name,
          options: {
            type: "local", // has already been uploaded to the server

            file: {
              name: f.file_name,
              size: f.size,
              type: f.mime_type,
            },

            // file initial metadata
            metadata: {
              media: f,
            },
          },
        };
      });
  };

  onFetchedItem = (item) => {
    this.setState({
      media: [...item.media],
      files: this.itemToFiles(item),
    });
  };

  insertDataAdapter = (values) => {
    const { media, store } = this.state;

    values["media"] = media;
    if (store) values["store_id"] = store.id;

    return values;
  };

  updateDataAdapter = (values) => {
    values["published"] = values["published"] ? true : false;

    if (this.state.media) values["media"] = this.state.media;

    return values;
  };

  onUpdated(item) {
    this.setState({
      media: item.media,
      files: this.itemToFiles(item),
    });
  }

  handleChange = async (e) => {
    console.log(this.filters, Object.keys(this.filters).length);

    this.setState({
      filters: this.filters,
    });

    await this.fetchRecords(1);
  };

  onResetFilters = () => {
    this.filters = {
      categoryId: null,
      page: 1,
    };

    this.handleChange(null);
  };

  filters = {
    categoryId: null,
  };

  filtersForm = () => {
    const { categoryId } = this.state.filters;

    return (
      <Form onChange={this.handleChange} className="filters-form">
        <Form.Row>
          <Col xs="6" md="4" lg="3">
            <RemoteSelect
              fieldToMap="title"
              name="categoryId"
              className="form-control-sm"
              options={this.categoryOptions}
              nullOption={true}
              key={this.state.filters.categoryId}
              value={this.state.filters.categoryId}
              onChange={(e, value) => {
                if (value === "") value = null;
                this.filters["categoryId"] = value ? parseInt(value, 10) : null;
                this.filters["page"] = 1;
              }}
            />
          </Col>

          <Col xs="8" md="5" lg="4">
            <Button
              variant="link"
              className="reset-filters-button"
              onClick={this.onResetFilters}
              style={
                categoryId ? { display: "inline-block" } : { display: "none" }
              }
            >
              <i className="fas fa-times"></i> Reset
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  };

  // TODO: Move

  onImageFileChanged = (imageFile) => {
    const { item } = this.state;

    const m = [
      {
        name: imageFile.name,
        size: imageFile.size,
        mime_type: imageFile.type,
      },
    ];

    this.setState({
      media: m,
    });

    if (item.id) this.update({ ...item, media: m });
  };

  onRemoveFile = async (error, file) => {
    if (file) {
      const metadata = file.getMetadata();

      if (metadata && metadata.media) {
        await CoreApi.RemoveMedia("mediastock", metadata.media.id);
      }
    }
  };

  onProcessFile = async (error, file) => {
    if (error) {
      console.log("Oh no file upload error", error);
      return;
    }

    const media = {
      name: file.filename,
      file_name: file.filename,
      size: file.fileSize,
      mime_type: file.fileType,
      collection_name: "asset",
      model_type: "mediastock",
    };

    await CoreApi.AddMedia(this.apiPath, this.state.item.id, [media]);
  };

  onUpdateFiles = (files) => {
    console.log(files);

    this.setState({
      files: files,
    });
  };

  onaActivateFile = async (file) => {
    const metadata = file.getMetadata();

    if (metadata && metadata.media) {
      await CoreApi.DownloadMedia(this.apiUrl + this.apiPath, metadata.media);
    }
  };

  get mask() {
    return ({ handleSubmit, handleChange, values, touched, errors }) => (
      <Form className="media-stock-form" onSubmit={handleSubmit}>
        <Form.Row>
          <Col md="8">
            <Form.Row>
              <Form.Group as={Col} xs="8">
                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={AppContext.r["title"]}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  maxLength={255}
                  isValid={touched.title && !errors.title}
                  isInvalid={!!errors.title}
                />
              </Form.Group>

              <Form.Group as={Col} xs="2">
                <Form.Label>{AppContext.r["highlighted"]}</Form.Label>
                <ToggleButton
                  className="published-button"
                  toggled={values.is_highlighted}
                  on={<i className="fa-solid fa-star"></i>}
                  off={<i className="fa-regular fa-star"></i>}
                  onToggle={(value) => {
                    var item = { ...this.state.item, ...values };
                    item.is_highlighted = value;

                    this.setState({ item: item });
                    this.onChange(item);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} xs="2">
                <Form.Label>{AppContext.r["published"]}</Form.Label>
                <ToggleButton
                  className="published-button"
                  toggled={values.published}
                  on={AppContext.r["yes"]}
                  off={AppContext.r["no"]}
                  onToggle={(value) => {
                    var item = { ...this.state.item, ...values };
                    item.published = value;

                    this.setState({ item: item });
                    this.onChange(item);
                  }}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} xs="12">
                <Form.Label>{AppContext.r["description"]}</Form.Label>
                <Field name="description">
                  {({ field }) => (
                    <ReactQuill
                      value={field && field.value ? field.value : ""}
                      onChange={field.onChange(field.name)}
                    />
                  )}
                </Field>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>{AppContext.r["category"]}</Form.Label>

                <RemoteSelect
                  name="category_id"
                  key={values.category_id}
                  value={values.category_id}
                  onChange={handleChange}
                  options={this.categoryOptions}
                  fieldToMap="title"
                />
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label>{AppContext.r["rating"]}</Form.Label>

                <RemoteSelect
                  name="rating_id"
                  key={values.rating_id}
                  value={values.rating_id}
                  onChange={handleChange}
                  options={this.ratingOptions}
                  fieldToMap="title"
                />
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label>{AppContext.r["channel"]}</Form.Label>

                <RemoteSelect
                  name="channel_id"
                  key={values.channel_id}
                  value={values.channel_id}
                  nullOption
                  onChange={handleChange}
                  options={this.channelOptions}
                  fieldToMap="title"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12">
                <h3>Files</h3>

                {!values.id ? (
                  <p>{AppContext.r["attach-files-after-creation"]}</p>
                ) : (
                  <FilePond
                    ref={(ref) => (this.pondRef = ref)}
                    allowMultiple={true}
                    onupdatefiles={this.onUpdateFiles}
                    //onaddfilestart={this.onAddFile}
                    onremovefile={this.onRemoveFile}
                    onprocessfile={this.onProcessFile}
                    files={this.state.files}
                    onactivatefile={this.onaActivateFile}
                    maxFiles={20}
                    name="media"
                    labelIdle='Upload a file. Drag & Drop or <span class="filepond--label-action">Browse</span>'
                    server={{
                      process: {
                        url: AppContext.s["net-api-url"] + "/mediastock/upload", //+values.id+'/media/add',
                        method: "POST",
                        headers: {
                          Authorization: "Bearer " + this.props.authToken,
                        },
                        ondata: (formData) => {
                          // console.log(formData)
                          //formData.append('asset_type_id', 1);
                          return formData;
                        },
                        onload: this.onUploadCompleted,
                        onerror: (error) => {
                          console.log(error);
                        },
                      },
                    }}
                  />
                )}
              </Form.Group>
            </Form.Row>
          </Col>

          <Col md="4">
            <MediaStockImage
              sx={{ width: 56, height: 56 }}
              variant="squircle"
              imageUrl={values.id ? this.imageUrl : null}
              item={this.state.item}
              onImageFileChanged={this.onImageFileChanged}
            />
          </Col>
        </Form.Row>

        <br />

        {this.formFooter}
      </Form>
    );
  }
}
