import React from 'react';

import { Switch } from '@material-ui/core';

import AppContext from '../app/AppContext';
import JsonToTable from 'framework/JsonToTable';

export default class AssociativeTable extends React.Component {
    state = {
        selectedItems: this.props.selectedItems ? this.props.selectedItems : []
    };

    get tableHead () {
        return [{
            "Adapter": (o) => {
                const selected = this.state.selectedItems.findIndex(x => x.id === o.id) >= 0;
                // console.log(this.state.selectedItems, selected)

                return (
                    <Switch size="small"
                        checked={selected}
                        onChange={() => this.selectItem(o, !selected)}
                        inputProps={{ 'aria-label': 'secondary checkbox' }} />);
            }
        },{
            "Title": "Id",
            "Field": "id"
        },{
            "Title": AppContext.r["title"],
            "Field": "title",
            "ClassName": "min-width-200"
        }];
    }

    selectItem = (o, selected) => {
        console.log(o, selected)
        let newItems;

        if(selected) {
            newItems = [ ...this.state.selectedItems, o ];
        } else {
            newItems = [ ...this.state.selectedItems.filter(x => x.id !== o.id) ];
        }

        this.setState({
            selectedItems: newItems
        });

        if(this.props.onChange)
            this.props.onChange(newItems);
    }

    render() {
        return (
            <>
                <JsonToTable head={this.tableHead} body={this.props.items} />
            </>
        )
    }
}