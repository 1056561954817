export default function loadClientResources (resources) {
    resources["store"] = "Proprietà";
    resources["stores"] = "Proprietà";
    resources["enabled"] = "Abilitato";
    resources["video-streams"] = "Video Stream";
    resources["team-members"] = "Membri del Team";
    resources["category"] = "Categoria";
    resources["categories"] = "Categorie";
    resources["trip"] = "Soggiorno";
    resources["trips"] = "Soggiorni";
    resources["notes-for-guests"] = "Note per gli ospiti";
    resources["nationality"] = "Nazionalità";
    resources["issued-by"] = "Emesso da";
    resources["passport-id"] = "Numero Passaporto";
    resources["upcoming-trips"] = "Prossimi Soggiorni";
    resources["insert-trip"] = "Nuovo Soggiorno";
    resources["checkin-at"] = "Check-in Il";
    resources["checkout-at"] = "Check-out Il";
    resources["guest"] = "Ospite";
    resources["guests"] = "Ospiti";
    resources["guests-count"] = "Numero Ospiti";
    resources["provided-ids"] = "Documenti Forniti";
    resources["guest-notes"] = "Note Ospite";
    resources["arriving"] = "Arrivo";
    resources["departing"] = "Partenza";
    resources["provide-link"] = "Manda questo link ai tuoi ospiti, affinchè possano fornire i loro dati.";
    resources["checkin-from"] = "Check-in Da";
    resources["checkout-to"] = "Check-out Fino A";
    resources["arrival-info"] = "Istruzioni per l'arrivo";
    resources["guests-info"] = "Informazioni per gli ospiti";
    resources["contact-info"] = "Informazioni  di contatto";
    resources["property-location"] = "Dove si trova la proprietà";
    resources["check-x-info"] = "Check-in & Check-out Info";
    resources["parking-spaces"] = "Posti Auto";
    resources["suitable-for-work"] = "Idonea al telelavoro";
    resources["suitable-for-work-desc"] = "Perchè il tuo spazio è adatto al lavoro? (es. scrivania, monitor, connessione veloce, etc...)";
    resources["message"] = "Messaggio";
    resources["messages"] = "Messaggi";
}