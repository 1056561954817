import React from 'react';

export default function loadClientResources (resources) {
    resources["your-store"] = "Your Stand";
    resources["edit-store-information"] = "Edit your stand information";
    resources["stand"] = "Stand";
    resources["stands"] = "Stands";
    resources["enabled"] = "Enabled";
    resources["team-members"] = "Team Members";
    resources["products"] = "Products";
    resources["event-type"] = "Event Type";
    resources["event-types"] = "Event Types";

    resources["free-tickets-desc"] = <p>Wanna give a try? Just use these <b>welcome vouchers</b> to get free trial tickets.</p>;

    resources["free-not-possible"] = <>
        <p>It is not possible anymore to set this event as free because you have already sold tickets.</p>
        <p>If you wish to host a free event you could refund your attendes and create a new event.</p>
    </>;
}