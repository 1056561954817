import React from "react";
import { Row, Col, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { Field } from 'formik';
import Axios from 'axios';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import * as yup from 'yup';

import {ContentRoute} from "../../../_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from '../../../api/Core';
import WoolSafeApi from '../../../api/WoolSafe';
import Module from "../../modules/Module";
import FormImage from "../../../framework/FormImage";
import RemoteSelect from '../../../framework/RemoteSelect';
import ToggleButton from '../../../framework/ToggleButton';
import ActionButton from '../../../framework/ActionButton';
// import ColorPicker from '../../../framework/ColorPicker';
import _Socials from "../Core/_Socials";
import _Streams from "../Core/_Streams";
import _TeamMembers from "../Core/_TeamMembers";
import path from 'path';

import 'react-quill/dist/quill.snow.css';

AppContext.yupAddPhoneTest(yup);

export default class Store extends Module {
    enableInsert = true;
    enableDelete = false;

    info = Store.Info;

    state = {
        ...this.state,
        tabPage: 0
    };

    static Info = {
        nameResource: "stores",
        icon: "fas fa-store-alt",
        itemsTitle: AppContext.r["stores"],
        itemTitle: AppContext.r["store"],
        path: "/properties",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Store match={props.match} location={props.location} history={props.history} />} />;
    }
    
    get title () {
        return AppContext.r["stand"];
    }
    
    get apiPath() {
        return '/stores';
    }

    get schema () {
        return yup.object({
            name: yup.string().required(),
            email: yup.string().email(),
            phone: yup.string().phone().nullable(),
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },{
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["subdomain"],
                "Field": "subdomain"
            },{
                "Title": AppContext.r["category"],
                "Adapter": (o) => {
                    return (o.category ? o.category.title : "");
                }
            },{
                "Title": "",
                "ClassName": "item-icons",
                "Adapter": (o) => {
                    const icons = [];

                    if(o.image) icons.push(<i className="far fa-image"></i>);

                    return icons;
                }
            },{
                "Title": AppContext.r["enabled"],
                "ClassName": "d-xs-table-cell",
                "Adapter": (o) => {
                    return <ActionButton selected={o.active}
                                action={async () => {
                                    const responseObj = await this.onToggleEnabled(o);

                                    if(responseObj) {
                                        const records = [...this.state.records];

                                        for(let item of records) {
                                            if(item && item.id === responseObj.id) {
                                                item.active = responseObj.active;
                                            }
                                        }
    
                                        this.setState({
                                            records: records
                                        });
                                    }
                                }
                            }>{o.active ? AppContext.r["yes"] : AppContext.r["no"]}</ActionButton>;
                }
            }
        ];
    }
    
    onToggleEnabled = async (o) => {
        console.log(o);

        const item = {
            id: o.id,
            active: !o.active ? 1 : 0 
        };

        return await this.update(item);
    }

    get initialValues() {
        return {
            name: "",
            description: "",
            address1: "",
            address2: "",
            post_code: "",
            city: "",
            country_id: 102,
            locale_id: 1,
            timezone: 1,
            phone: "",
            email: "",
        };
    }

    async beforeComponentDidMount() {
        // Fetch timezones
        const res = await Axios.get(AppContext.s["api-url"] + "/timezones");
        if(res && res.data)
            this.timezoneOptions = res.data.map(x => ({ "id": x, "name": ((x > 0) ? "+" : "") + x }))
        // Fetch countries
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
        // Fetch locales
        //this.localeOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locales", "id");
        
        const response = await WoolSafeApi.Categories();
        if(response && response.data)
            this.categoryOptions = CoreApi.mapData(response.data.data, "id");

        this.coverFormImageRef = React.createRef();
    }

    updateDataAdapter = (values) => {
        if(isNaN(values['tables_count']) || values['tables_count'] === "")
            values['tables_count'] = 0;

        if(values.active)
            values.active = 1;

        console.log(values);
        return values;
    }

    get coverDeliveryApiPath() {
        if(this.apiPath !== null && this.state.item && this.state.item.id !== undefined)
            return path.join(this.apiPath, this.state.item.id.toString(10), "cover");
        return "";
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <h3>Stand direct link 
                            <a href={AppContext.s["project-url"] + "/" + values.subdomain} target="_blank" rel="noopener noreferrer">{values.subdomain}</a></h3>
                        <br />
                    </Col>

                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name" autoFocus
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                            
                            {/* <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["locale"]}</Form.Label>

                                <RemoteSelect name="locale_id" key={values.locale_id} value={values.locale_id} onChange={handleChange}
                                            options={this.localeOptions} fieldToMap="locale" />
                            </Form.Group>

                            <Form.Group as={Col} md="3" xs="8">
                                <Form.Label>{AppContext.r["color"]}</Form.Label>
                                <ColorPicker value={values.color}
                                    onChange={(value) => {
                                        setFieldValue("color", value);
                                    }} />
                            </Form.Group> */}

                            <Form.Group as={Col} md="3" xs="4">
                                <Form.Label>Enabled</Form.Label>
                                <ToggleButton className="published-button" toggled={values.active} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.active}
                                    onToggle={(value) => { 
                                        setFieldValue("active", value);
                                    }} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["category"]}</Form.Label>

                                <RemoteSelect name="category_id" key={values.category_id} value={values.category_id} onChange={handleChange}
                                            options={this.categoryOptions} fieldToMap="title" />
                            </Form.Group>

                           <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["subdomain"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["subdomain"]} name="subdomain"
                                                value={values.subdomain} onChange={handleChange} />
                            </Form.Group>

                             {/* <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["color"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["color"]} name="color" maxLength={7} 
                                                value={values.color} onChange={handleChange} />
                            </Form.Group>
                             
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["timezone"]}</Form.Label>

                                <RemoteSelect name="timezone" key={values.timezone} value={values.timezone} onChange={handleChange}
                                            options={this.timezoneOptions} fieldToMap="name" />
                            </Form.Group> */}
                        </Row>
 
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["address"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address1"
                                                value={values.address1} onChange={handleChange}
                                                isValid={touched.address1 && !errors.address1} isInvalid={!!errors.address1} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address2"
                                                value={values.address2} onChange={handleChange} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["post-code"]} name="post_code"
                                                value={values.post_code} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["city"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["city"]} name="city"
                                                value={values.city} onChange={handleChange}/>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4">
                                <Form.Label>{AppContext.r["country"]}</Form.Label>

                                <RemoteSelect name="country_id" key={values.country_id} value={values.country_id} onChange={handleChange}
                                            options={this.countryOptions} />
                            </Form.Group>
                        </Row>

                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["email"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                            value={values.email} onChange={handleChange}
                                            isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                            </Form.Group>
         
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["phone"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                                value={values.phone} onChange={handleChange}
                                                isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                            </Form.Group>

                            {/* <Form.Group as={Col} md="6">
                                <Form.Label>Website</Form.Label>
                                <Form.Control type="text" placeholder={"Website"} name="website_url"
                                                value={values.website_url} onChange={handleChange}
                                                isValid={touched.website_url && !errors.website_url} isInvalid={!!errors.website_url} />
                            </Form.Group> */}
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Logo</Form.Label>
                                {this.formImage}
                            </Form.Group>
                        </Row>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Cover</Form.Label>
                                <FormImage ref={this.coverFormImageRef} ratio={3}
                                    disabled={this.state.item === undefined || this.state.item.id === undefined}
                                    disabledMessage={AppContext.r["create-item-first"]}
                                    viewOnly={!this.enableEdit}
                                    imageUrl={AppContext.s["host-url"] + this.coverDeliveryApiPath} 
                                    onDelete={() => {
                                        this.update({ cover: "-1" }, false, true);
                                        this.coverFormImageRef.current.resetImage();
                                    }}
                                    onImageFileChanged={(file) => this.onImageFileChanged(file, 'cover')} />
                            </Form.Group>
                        </Row>

                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Field name="description">
                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                </Field>
                            </Form.Group>
                        </Form.Row>

                        { this.state.item.id &&
                            <Row className="justify-content-center">
                                <Col className="tabs">
                                    <Paper className="paper">
                                        <Tabs 
                                            value={this.state.tabPage}
                                            onChange={(e, value) => this.setState({tabPage: value})}
                                            indicatorColor="primary" textColor="primary" centered>
                                            <Tab label={AppContext.r["socials"]} />
                                            <Tab label={AppContext.r["video-streams"]} />
                                            <Tab label={AppContext.r["team-members"]} />
                                        </Tabs>

                                        <Col xs={12} className="tab">
                                            {this.state.tabPage === 0 &&
                                                <_Socials sociableType={"stores"} sociableId={this.state.item.id} /> }
                                            {this.state.tabPage === 1 &&
                                                <_Streams streamableType={"stores"} streamableId={this.state.item.id} /> }
                                            {this.state.tabPage === 2 &&
                                                <_TeamMembers storeId={this.state.item.id} /> }
                                        </Col>
                                    </Paper>
                                </Col>
                            </Row> }
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>
                
                {this.formFooter}
            </Form>);
    }
}