import React from 'react'
import { Col, Form } from 'react-bootstrap';

import { ContentRoute } from "../../../_metronic/layout";

import CoreApi from "api/Core";
import Module from "../../modules/Module";
import AppContext from '../../AppContext';
// import JsonToTable from 'framework/JsonToTable';
import AssociativeTable from 'framework/AssociativeTable';

let yup = require('yup');

export default class Role extends Module {
    info = Role.Info;
    
    static Info = {
        "nameResource": "roles",
        "icon": "fas fa-user-tag admin-menus-color",
        "path": "/roles",
        "show": true
    };

    static get route() {
        return <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
            component={(props) => <Role match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["role"];
    }

    get apiPath() {
        return '/roles';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required()
        });
    }

    get tableHead () {
        return [{
            "Title": "Id",
            "Field": "id"
        },{
            "Title": AppContext.r["title"],
            "Field": "title",
            "ClassName": "min-width-200"
        }];
    }

    get initialValues() {
        return {
            title: "",
        };
    }

    async beforeComponentDidMount() {
        const response = await CoreApi.Permissions();
        
        if(response && response.data)
            this.setState({
                permissions: response.data.data
            });
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                                value={values.title} onChange={handleChange}
                                                isValid={touched.title && !errors.title} isInvalid={!!errors.title} 
                                                ref={this.focusedInput} />
                            </Form.Group>
                        </Form.Row>        
                        
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["modules"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["modules"]} name="modules" disabled
                                                value={values.modules} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>

                        <h3>{AppContext.r["permissions"]}</h3>
                        <AssociativeTable onChange={(values) => setFieldValue("permissions", values.map(v => v.id))}
                            selectedItems={this.state.item.permissions} 
                            items={this.state.permissions} />
                        {/* <JsonToTable head={this.tableHead} body={this.state.item.permissions} /> */}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}