import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';

export default class WoolSafe {
    static async MyShops() {
        return await Axios.get(AppContext.s["api-url"] + "/myshops")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }
    
    static async Categories() {
        return await Axios.get(AppContext.s["api-url"] + "/categories")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
    
    static async Events(upcoming = 0) {
        return await Axios.get(AppContext.s["api-url"] + "/events?upcoming="+upcoming)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
    
    static async EventTypes() {
        return await Axios.get(AppContext.s["api-url"] + "/events/types")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorlog(error);
            });
    }
}