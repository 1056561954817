/*
    0  NotStarted,
    1  Started,
    2  InvitePlayers,
    3  Category,
    4  Image,
    5  Video,
    6  Question,
    7  AnswersTimerOn,
    8  AnswersTimerOff,
    9  CorrectAnswer,
    10 ImageAgain,
    20 Teams,
    21 PartialRanking,
    22 Ranking,
    23 FinalRanking,
    30 Completed // Either closed or expired
*/

export default class GameStatusEnum {
    static get NotStarted() { return 0; }
    static get Started() { return 1; }
    static get InvitePlayers() { return 2; }
    static get Category() { return 3; }
    static get Image() { return 4; }
    static get Video() { return 5; }
    static get Question() { return 6; }
    static get AnswersTimerOn() { return 7; }
    static get AnswersTimerOff() { return 8; }
    static get CorrectAnswer() { return 9; }
    static get ImageAgain() { return 10; }
    static get Teams() { return 20; }
    static get PartialRanking() { return 21; }
    static get Ranking() { return 22; }
    static get FinalRanking() { return 23; }
    static get Completed() { return 30; }
}
