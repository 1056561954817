import React from 'react';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { format } from 'date-fns';
import * as yup from 'yup';

import asDashboardWidget from 'framework/asDashboardWidget.js';
import AppContext from 'app/AppContext';
import IqSpaceApi from "api/TriviaPro";

AppContext.yupAddPhoneTest(yup);

class EventsWidget extends React.Component {
    state = {
        store: this.props.store,
        events: null
    };

    async componentDidMount() {
        const { store } = this.state;

        const response = await IqSpaceApi.EventsAdmin(store.id);
        console.log(response);

        this.setState({
            events: response.data.data
        });
    }

    render() {
        const { store, events } = this.state;

        if(!store || !events)
            return AppContext.r["preloader"];

        return (
            <>
                <h1>{AppContext.r["upcoming-events"]}</h1>
                
                <div className="actions">
                </div>

                <div className="events">
                    {!this.props.disableInsert && 
                        <div className="card add-event" onClick={() => AppContext.history.push("/events/insert")}>
                            <Paper>
                                <h2>{AppContext.r["insert"]}</h2>
                                <h2>{AppContext.r["insert-trip"]}</h2>
                                <Icon className="fas fa-suitcase-rolling" />
                            </Paper>
                        </div> }

                    { events.map(event => 
                        <div className="card event-card" onClick={() => AppContext.history.push("/events/"+event.id)} >
                            <Paper>
                                <h2>{event.title}</h2>

                                <div>
                                    <p>{format(new Date(event.date_time), "d-MM-yyyy H:mm")}</p>
                                </div>

                                <div className="recap">
                                    {event.games && event.games.length > 0 && 
                                        <>
                                            {console.log(JSON.parse(event.games[0].players))}
                                            <b>LIVE</b> | <b>{JSON.parse(event.games[0].players).length}</b> { AppContext.r["players"] }
                                        </> }
                                </div>
                            </Paper>
                        </div>) }
                </div>
            </>
        );
    }
}

export default asDashboardWidget(EventsWidget);