import axios from "axios";

import AuthApi from "../../../../api/Auth.js";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

// export const ME_URL = "api/me";

// export function login(email, password) {
// //   return axios.post(LOGIN_URL, { email, password });
//     return AuthApi.loginSync(email, password);
// }

export function login(email, password, grantType = "password", provider = "", accessToken = "", name = "", surname = "") {
    //   return axios.post(LOGIN_URL, { email, password });
    return AuthApi.login(email, password, grantType, provider, accessToken, name, surname);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
//   return axios.get(ME_URL);
    return AuthApi.fetchUser();
}
