import React from 'react';
import { Col, Form } from 'react-bootstrap';
import ColorPicker from '../../../framework/ColorPicker';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";

let yup = require('yup');

export default class Category extends Module {
    enableDelete = false;

    info = Category.Info;
    
    static Info = {
        nameResource: "categories",
        icon: "fas fa-user-md admin-menus-color",
        path: "/categories",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Category match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["category"];
    }

    get apiPath() {
      return '/categories';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["title"],
                "Field": "title",
            },
            {
                "Title": AppContext.r["color"],
                "Field": "color",
            }
            // ,{
            //     "ClassName": "item-icons",
            //     "Adapter": (o) => {
            //         // const icons = [];
            //         // if(o.image) icons.push(<i key="image" className="far fa-image"></i>);
            //         // icons.push( (o.highlighted === 1) ? <i key="star" className="fas fa-star"></i> : "");
            //         // return icons;
            //         return (
            //             <div className="row-thumb">
            //                 {o.image && <img src={AppContext.s["host-url"] + this.apiPath + "/" + o.id + "/thumb"} alt="Thumb" /> }
            //             </div>
            //         );
            //     }
            // }
        ];
    }

    get initialValues() {
        return {
            title: "",
            color: "",
        };
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <Form.Row>
                            <Form.Group as={Col} xs="9">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                                value={values.title} onChange={handleChange}
                                                isValid={touched.title && !errors.title} isInvalid={!!errors.title} autoFocus />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="3" xs="6">
                                <Form.Label>{AppContext.r["color"]}</Form.Label>
                                <ColorPicker value={values.color}
                                    onChange={(value) => {
                                        setFieldValue("color", value);
                                    }} />
                            </Form.Group>
                        </Form.Row>        
                        
                        <Form.Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
{/*                     
                    <Col md="4">
                        {this.formImage}
                    </Col> */}
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}